import { useEffect } from 'react';
import anime from 'animejs/lib/anime.es.js';
import 'styles/illustrations/fintech.css';

export default function FinTech() {
  useEffect(() => {
    animate();
  }, []);

  return (
    <div className="fintech-illustration">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        viewBox="0 0 995 682"
      >
        <title>FinTech</title>
        <defs>
          <linearGradient
            id="fintech-a"
            x1="525.16"
            y1="360.86"
            x2="669.95"
            y2="360.86"
            gradientTransform="matrix(1, 0.27, 0, 1, 0, -321.32)"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0" stopColor="#5ec2bb" />
            <stop offset="1" stopColor="#203075" />
          </linearGradient>
          <linearGradient
            id="fintech-b"
            x1="229.45"
            y1="427.07"
            x2="347.81"
            y2="427.07"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0" stopColor="#ee105a" />
            <stop offset="1" stopColor="#0a1236" />
          </linearGradient>
          <linearGradient
            id="fintech-d"
            x1="229.45"
            y1="234.18"
            x2="347.81"
            y2="234.18"
            gradientTransform="matrix(1, 0, 0, 1, 0, 0)"
            xlinkHref="#fintech-a"
          />
          <linearGradient
            id="fintech-f"
            x1="844.51"
            y1="442.34"
            x2="940.56"
            y2="442.34"
            gradientTransform="matrix(1, 0, 0, 1, 0, 0)"
            xlinkHref="#fintech-a"
          />
          <linearGradient
            id="fintech-g"
            x1="746.2"
            y1="452.29"
            x2="800.27"
            y2="452.29"
            gradientTransform="matrix(1, 0, 0, 1, 0, 0)"
            xlinkHref="#fintech-a"
          />
          <linearGradient
            id="fintech-h"
            x1="607.34"
            y1="462.24"
            x2="695.04"
            y2="462.24"
            gradientTransform="matrix(1, 0, 0, 1, 0, 0)"
            xlinkHref="#fintech-a"
          />
          <linearGradient
            id="fintech-i"
            x1="527.13"
            y1="543.1"
            x2="589.82"
            y2="543.1"
            gradientTransform="matrix(1, 0, 0, 1, 0, 0)"
            xlinkHref="#fintech-a"
          />
          <linearGradient
            id="fintech-j"
            x1="551.82"
            y1="157"
            x2="551.83"
            y2="157"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0" stopColor="#5ec2bb" />
            <stop offset="1" stopColor="#0d183a" />
          </linearGradient>
          <linearGradient
            id="fintech-k"
            x1="409.13"
            y1="172.16"
            x2="551.84"
            y2="172.16"
            xlinkHref="#fintech-j"
          />
          <linearGradient
            id="fintech-l"
            x1="551.82"
            y1="255.67"
            x2="551.83"
            y2="255.67"
            xlinkHref="#fintech-j"
          />
          <linearGradient
            id="fintech-m"
            x1="409.13"
            y1="270.83"
            x2="551.84"
            y2="270.83"
            xlinkHref="#fintech-j"
          />
          <linearGradient
            id="fintech-n"
            x1="-1922.53"
            y1="2887.07"
            x2="-1922.53"
            y2="2887.07"
            gradientTransform="translate(3894.27 -579.91) rotate(45)"
            xlinkHref="#fintech-j"
          />
          <linearGradient
            id="fintech-o"
            x1="-2065.23"
            y1="2902.24"
            x2="-1922.52"
            y2="2902.24"
            gradientTransform="translate(3894.27 -579.91) rotate(45)"
            xlinkHref="#fintech-j"
          />
          <clipPath id="graph-mask">
            <rect id="graph-mask-rect" x="0" y="0" width="950" height="2000" />
          </clipPath>
        </defs>

        <polygon
          id="base"
          className="fintech-a"
          points="403.74 664.87 38.9 566.36 629.5 406.9 994.34 505.4 403.74 664.87"
        />

        <g id="phone">
          <path className="fintech-b" d="M534.74,14.33h0l.14-.05Z" />
          <path
            className="fintech-b"
            d="M711.56,51.68,556.61,10.16a16.21,16.21,0,0,0-6.45-.45h0a12.83,12.83,0,0,0-3.58,1.08l-10,3a15.67,15.67,0,0,1,7.6.23l155,41.52c9.39,2.52,17,12.18,17,21.58V398.74c0,5.72-2.83,10-7.15,11.94h0l11.54-3.51c4.84-1.71,8.08-6.19,8.08-12.3V73.26C728.58,63.86,721,54.2,711.56,51.68Z"
          />
          <path
            className="fintech-c"
            d="M699.1,411.2l-155-41.52c-9.39-2.52-17-12.18-17-21.58V26.48c0-9.4,7.62-15,17-12.46l155,41.52c9.39,2.52,17,12.18,17,21.58V398.74C716.11,408.14,708.49,413.72,699.1,411.2Z"
          />
          <polygon
            className="fintech-d"
            points="702.91 371.43 540.33 327.86 540.33 40.78 702.91 84.34 702.91 371.43"
          />
          <ellipse
            className="fintech-e"
            cx="625.91"
            cy="369.47"
            rx="9.45"
            ry="12.35"
            transform="translate(-88.44 503.56) rotate(-41.18)"
          />
          <ellipse
            className="fintech-e"
            cx="642.11"
            cy="58.79"
            rx="4.73"
            ry="6.17"
            transform="translate(120.15 437.37) rotate(-41.18)"
          />
          <polygon
            className="fintech-e"
            points="621.62 53.3 587.32 44.11 587.32 41.02 621.62 50.22 621.62 53.3"
          />
        </g>

        <g id="euro" className="fintech-f">
          <path
            id="euro-sign"
            className="fintech-g"
            d="M761,198.57l-15.69,4.21L743,179l20-5.36c7.88-41.44,37.44-74.86,79.2-86,42.26-11.32,76.47,5,90.07,42.59l-47.58,12.75c-8.74-13.39-22.67-17.8-39.37-13.32-16.2,4.34-28.59,15.8-35.25,31.42l42.26-11.33,2.31,23.79L805,186.77c.1,2.42.27,5.08.44,7.75a33,33,0,0,0,1.08,7l49.34-13.23,2,23.61L815.07,223.4c9,12.24,23,16.9,39.68,12.43S884,219.57,890,202.25l47.58-12.75c-6.65,43-36.53,76.24-78.79,87.57s-76.29-5.33-90.49-41.12l-19.74,5.29-2-23.61,15.69-4.21a66.46,66.46,0,0,1-.82-7.1C761.23,203.65,761.13,201.24,761,198.57Z"
          />
        </g>

        <ellipse
          id="stack-shadow"
          className="fintech-h"
          cx="288.63"
          cy="566.05"
          rx="59.18"
          ry="15.98"
        />

        <g id="stack0">
          <path className="fintech-i" d="M229.45,425.67v1.4a4.56,4.56,0,0,1,.23-1.4Z" />
          <path
            className="fintech-i"
            d="M330.48,438.37c-23.11,6.24-60.59,6.24-83.7,0-11.56-3.12-17.33-7.21-17.33-11.3v80.86c0,4.09,5.77,8.18,17.33,11.3,23.11,6.24,60.59,6.24,83.7,0,11.56-3.12,17.33-7.21,17.33-11.3V427.07C347.81,431.16,342,435.25,330.48,438.37Z"
          />
          <ellipse className="fintech-j" cx="288.63" cy="427.07" rx="59.18" ry="15.98" />
          <ellipse className="fintech-j" cx="288.63" cy="427.07" rx="59.18" ry="15.98" />
        </g>

        <g id="stack1">
          <g className="fintech-k">
            <path
              className="fintech-c"
              d="M330.48,245.48c-23.11,6.24-60.59,6.24-83.7,0-11.56-3.12-17.33-7.21-17.33-11.3V315c0,4.09,5.77,8.18,17.33,11.3,23.11,6.24,60.59,6.24,83.7,0,11.56-3.12,17.33-7.21,17.33-11.3V234.18C347.81,238.27,342,242.36,330.48,245.48Z"
            />
          </g>
          <path
            className="fintech-g"
            d="M330.48,309.88c-23.11,6.24-60.59,6.24-83.7,0-11.56-3.12-17.33-7.21-17.33-11.3v80.86c0,4.09,5.77,8.17,17.33,11.29,23.11,6.25,60.59,6.25,83.7,0,11.56-3.12,17.33-7.2,17.33-11.29V298.58C347.81,302.67,342,306.76,330.48,309.88Z"
          />
          <ellipse className="fintech-g" cx="288.63" cy="298.58" rx="59.18" ry="15.98" />
          <g className="fintech-k">
            <path
              className="fintech-c"
              d="M330.48,246.18c-23.11,6.24-60.59,6.24-83.7,0-11.56-3.12-17.33-7.21-17.33-11.3v80.86c0,4.09,5.77,8.18,17.33,11.3,23.11,6.24,60.59,6.24,83.7,0,11.56-3.12,17.33-7.21,17.33-11.3V234.88C347.81,239,342,243.06,330.48,246.18Z"
            />
          </g>
          <ellipse className="fintech-l" cx="288.63" cy="234.18" rx="59.18" ry="15.98" />
          <ellipse className="fintech-l" cx="288.63" cy="234.18" rx="59.18" ry="15.98" />
        </g>

        <g id="graph" clipPath="url(#graph-mask)">
          <polygon
            className="fintech-m"
            points="870.42 501.29 905.49 446 940.57 390.71 912.95 383.4 877.88 438.69 844.51 491.28 870.42 501.29"
          />
          <polygon
            className="fintech-n"
            points="765.19 506.53 800.27 405.36 800.27 405.36 800.27 405.36 772.65 398.05 746.2 474.35 765.19 506.53"
          />
          <polygon
            className="fintech-o"
            points="624.89 544.13 659.97 465.89 695.04 387.66 695.04 387.66 667.43 380.35 632.35 458.59 607.34 514.38 624.89 544.13"
          />
          <polygon
            className="fintech-p"
            points="554.74 606.52 589.82 484.69 562.2 477.38 527.13 599.22 527.13 601.51 554.74 608.81 554.74 608.81 554.74 606.52"
          />
          <polygon
            className="fintech-c"
            points="940.57 505.43 554.74 608.81 554.74 606.52 589.82 484.69 624.89 544.13 659.97 465.89 695.04 387.66 730.12 447.1 765.19 506.53 800.27 405.36 835.34 487.74 870.42 501.29 905.49 446 940.57 390.71 940.57 505.43"
          />
        </g>

        <polyline
          id="line"
          className="fintech-q"
          points="0.15 446.74 157.22 323.27 314.28 579.61 471.34 451.26 628.4 600.47 785.46 681.51"
        />

        <g id="coins">
          <g id="coin0">
            <path
              className="fintech-u"
              d="M551.83,256.48c-.37,4.8-7.33,9.56-20.89,13.22-27.86,7.53-73.05,7.53-100.91,0-14.16-3.82-21.12-8.85-20.89-13.86h0v11.09h0c.34,4.81,7.3,9.59,20.89,13.26,27.86,7.52,73.05,7.52,100.91,0,14.32-3.87,21.27-9,20.89-14Z"
            />
            <ellipse className="fintech-c" cx="480.48" cy="256.07" rx="71.36" ry="19.27" />
            <path
              className="fintech-g"
              d="M451.56,264.48l-6.9-1.87,10.49-2.22-5.45-1.48-10.49,2.21-6.79-1.85L443.07,257l-.63-.17-9.05-2.49-4-1.11.29-.06,2.38-.31,6.31-.87.63.18c1.46.39,2.89.79,4.39,1.18a11.12,11.12,0,0,0,5,.16l28.08-5.93a5.27,5.27,0,0,0,.64-.17c1.11-.44.75-1-1.1-1.49s-3.48-1-5.22-1.43l6.86-1.45,13.57,3.69.29.05,10.43-2.2,6.82,1.85-10.23,2.16L504,250.1l10.23-2.16L521,249.8,510.53,252l.77.26a46.16,46.16,0,0,1,5.34,2A8.13,8.13,0,0,1,519.2,256c1.13,1.3-.44,2.3-3.86,3.09a46.11,46.11,0,0,1-15.26.52l-.33,0c.67.34,1.39.67,2,1,2.07,1.22,2,2.31-.63,3.26a29.54,29.54,0,0,1-5,1.17,56.73,56.73,0,0,1-8.5.78,75.59,75.59,0,0,1-12.45-.87c-3.75-.56-7.08-1.26-10.28-2l-2.69-.63-.37.06-9.95,2.1A2.45,2.45,0,0,0,451.56,264.48Zm20.34-10.15-14.14,3,.15.06c3.26.91,6.54,1.81,10.28,2.61a67.13,67.13,0,0,0,7.78,1.25c5.36.52,10.32-.2,10.28-1.5,0-.53-.8-1-1.87-1.54a49.84,49.84,0,0,0-7.32-2.46Zm6.42-1.36.3.1c2,.52,3.9,1.06,5.95,1.57a60.71,60.71,0,0,0,7.48,1.41c4.56.56,8.79.27,10.41-.71.87-.53.39-1.1-.65-1.67a25.53,25.53,0,0,0-5.05-1.85c-1.84-.52-3.75-1-5.66-1.55Z"
            />
          </g>
          <g id="coin1">
            <path
              className="fintech-s"
              d="M551.83,157.81c-.37,4.8-7.33,9.56-20.89,13.22-27.86,7.52-73.05,7.52-100.91,0-14.16-3.82-21.12-8.85-20.89-13.86h0v11.09h0c.34,4.81,7.3,9.59,20.89,13.25,27.86,7.53,73.05,7.53,100.91,0,14.32-3.86,21.27-9,20.89-14Z"
            />
            <ellipse className="fintech-c" cx="480.48" cy="157.83" rx="71.36" ry="19.27" />
            <path
              className="fintech-g"
              d="M451.56,166.25l-6.9-1.88,10.49-2.21-5.45-1.48-10.49,2.21L432.42,161l10.65-2.25-.63-.17-9.05-2.48-4-1.11.29-.06,2.38-.31,6.31-.87.63.17c1.46.4,2.89.8,4.39,1.19a11.12,11.12,0,0,0,5,.16l28.08-5.93a3.84,3.84,0,0,0,.64-.18c1.11-.43.75-.95-1.1-1.48s-3.48-1-5.22-1.43l6.86-1.45,13.57,3.69.29.05,10.43-2.2,6.82,1.85-10.23,2.16,5.45,1.48,10.23-2.16,6.83,1.86-10.49,2.22.77.25a48,48,0,0,1,5.34,2,8,8,0,0,1,2.56,1.65c1.13,1.31-.44,2.3-3.86,3.09a46.08,46.08,0,0,1-15.26.53,1.94,1.94,0,0,0-.33,0c.67.34,1.39.66,2,1,2.07,1.21,2,2.31-.63,3.26a30.54,30.54,0,0,1-5,1.17,59.5,59.5,0,0,1-8.5.78,74.5,74.5,0,0,1-12.45-.88c-3.75-.55-7.08-1.25-10.28-2l-2.69-.64-.37.07-9.95,2.1A2.45,2.45,0,0,0,451.56,166.25ZM471.9,156.1l-14.14,3,.15.06c3.26.91,6.54,1.82,10.28,2.62A67.13,67.13,0,0,0,476,163c5.36.51,10.32-.21,10.28-1.5,0-.53-.8-1-1.87-1.55a49.92,49.92,0,0,0-7.32-2.45Zm6.42-1.36.3.1c2,.52,3.9,1.06,5.95,1.57a60.81,60.81,0,0,0,7.48,1.4c4.56.57,8.79.28,10.41-.7.87-.53.39-1.1-.65-1.67a25.48,25.48,0,0,0-5.05-1.86c-1.84-.52-3.75-1-5.66-1.54Z"
            />
          </g>
          <g id="coin2">
            <path
              className="fintech-w"
              d="M492.79,102.7c-3.65,3.13-11.94,1.58-24.11-5.41-25-14.39-57-46.34-71.36-71.36-7.31-12.72-8.67-21.19-5-24.57h0L384.5,9.19h0c-3.16,3.64-1.62,11.94,5.4,24.14,14.38,25,46.33,57,71.35,71.36,12.86,7.39,21.38,8.71,24.69,4.85Z"
            />
            <ellipse
              className="fintech-c"
              cx="442.33"
              cy="52.27"
              rx="19.27"
              ry="71.36"
              transform="translate(92.59 328.09) rotate(-45)"
            />
            <path
              className="fintech-g"
              d="M415.93,37.77l-3.55-6.2,9,5.85-2.81-4.91-9-5.85-3.5-6.11,9.12,5.95-.32-.57q-2.33-4.08-4.64-8.16c-.69-1.21-1.38-2.43-2.08-3.64l.25.16,1.9,1.47,5.08,3.84.33.58c.75,1.31,1.48,2.6,2.26,3.93a11.25,11.25,0,0,0,3.44,3.67l24,15.66a4,4,0,0,0,.57.33c1.1.48,1.22-.14.28-1.83-.86-1.54-1.79-3.13-2.68-4.7l5.87,3.82,7,12.21a1.59,1.59,0,0,0,.17.24l8.93,5.82,3.51,6.13-8.76-5.7,2.81,4.9,8.76,5.7,3.52,6.15-9-5.85c.14.27.25.5.36.73a45.81,45.81,0,0,1,2.35,5.19,8,8,0,0,1,.64,3c-.12,1.73-1.94,1.32-4.91-.54A46,46,0,0,1,453.72,68.6a2.73,2.73,0,0,0-.22-.24c.23.71.51,1.45.68,2.09.6,2.32-.22,3-2.76,1.85a30.36,30.36,0,0,1-4.35-2.69,58.69,58.69,0,0,1-6.56-5.46,75.35,75.35,0,0,1-8.19-9.42c-2.25-3-4.11-5.9-5.85-8.69L425,43.68l-.31-.21-8.52-5.55ZM437.48,45l-12.1-7.88a.71.71,0,0,0,.07.15c1.65,2.95,3.33,5.91,5.41,9.12a69.85,69.85,0,0,0,4.62,6.38c3.43,4.16,7.45,7.16,8.33,6.21.36-.39.17-1.3-.23-2.42a49,49,0,0,0-3.44-6.91ZM443,48.56l.14.27c1,1.77,2,3.51,3.1,5.32a60.5,60.5,0,0,0,4.29,6.29c2.83,3.62,6,6.41,7.86,6.86,1,.24,1.06-.5.72-1.64a25.76,25.76,0,0,0-2.25-4.88c-.94-1.67-1.93-3.38-2.91-5.09Z"
            />
          </g>
        </g>
      </svg>
    </div>
  );
}

function animate() {
  const svgs = document.querySelectorAll('.fintech-illustration');
  if (!svgs) return;

  const timeline = anime.timeline({
    autoplay: false,
    loop: false,
  });

  const animations = [
    {
      targets: '#base',
      translateY: [30, 0],
      opacity: [0, 0.25],
      easing: 'easeInOutSine',
      duration: 500,
      delay: 0,
    },
    {
      targets: '#stack-shadow',
      opacity: [0, 0.56],
      easing: 'linear',
      duration: 300,
      delay: 500,
    },
    {
      targets: '#stack0',
      translateY: [-50, 0],
      opacity: [0, 1],
      duration: 700,
      delay: 500,
    },
    {
      targets: '#stack1',
      translateY: [-50, 0],
      opacity: [0, 1],
      duration: 700,
      delay: 600,
    },
    {
      targets: '#phone',
      translateX: [50, 0],
      translateY: [-15, 0],
      opacity: [0, 1],
      duration: 1000,
      delay: 850,
    },
    {
      targets: '#phone-shadow',
      translateY: [-50, 0],
      opacity: [0, 0.49],
      duration: 1000,
      delay: 1000,
    },
    {
      targets: '#euro',
      translateY: [50, 0],
      rotateY: ['720deg', '0deg'],
      opacity: [0, 0.8],
      easing: 'easeOutCirc',
      duration: 1000,
      delay: 1700,
    },
    ...[0, 1, 2].map(idx => {
      return {
        targets: `#coin${idx}`,
        translateY: [30, 0],
        opacity: [0, 1],
        easing: 'easeOutCirc',
        duration: 750,
        delay: 1300 + idx * 100,
      };
    }),
    {
      targets: '#line',
      strokeDashoffset: [anime.setDashoffset, 0],
      easing: 'easeInOutSine',
      duration: 750,
      delay: 1750,
    },
    {
      targets: '#graph-mask-rect',
      scaleX: [0, 1],
      easing: 'easeInOutSine',
      duration: 1000,
      delay: 1000,
    },
  ];

  anime({
    targets: '#euro-sign',
    rotateY: {
      value: ['180deg', '0deg'],
      easing: 'easeInOutSine',
      duration: 7500,
    },
    direction: 'alternate',
    loop: true,
  });

  animations.forEach(animation => timeline.add(animation, 0));

  svgs.forEach(svg => (svg.style.opacity = '1'));

  timeline.play();
}
