import { useEffect } from 'react';
import anime from 'animejs/lib/anime.es.js';
import 'styles/illustrations/robotics.css';

export default function Robotics() {
  useEffect(() => {
    animate();
  }, []);

  return (
    <div className="robotics-illustration">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        viewBox="0 0 1037 671"
      >
        <title>Robotics</title>
        <defs>
          <linearGradient
            id="robotics-a"
            x1="365.26"
            y1="405.63"
            x2="497.1"
            y2="405.63"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0" stopColor="#ee105a" />
            <stop offset="0.1" stopColor="#e8115b" />
            <stop offset="0.25" stopColor="#d5145d" />
            <stop offset="0.42" stopColor="#b81861" />
            <stop offset="0.61" stopColor="#8e1f67" />
            <stop offset="0.81" stopColor="#5a276d" />
            <stop offset="1" stopColor="#203075" />
          </linearGradient>
          <linearGradient
            id="robotics-b"
            x1="241.12"
            y1="244.89"
            x2="455.79"
            y2="244.89"
            xlinkHref="#robotics-a"
          />
          <linearGradient
            id="robotics-c"
            x1="213.19"
            y1="379.12"
            x2="418.42"
            y2="379.12"
            xlinkHref="#robotics-a"
          />
          <linearGradient
            id="robotics-d"
            x1="346.99"
            y1="209.47"
            x2="548.86"
            y2="209.47"
            xlinkHref="#robotics-a"
          />
          <linearGradient
            id="robotics-e"
            x1="495.33"
            y1="264.03"
            x2="548.86"
            y2="264.03"
            xlinkHref="#robotics-a"
          />
          <linearGradient
            id="robotics-f"
            x1="531.02"
            y1="281.21"
            x2="581.7"
            y2="281.21"
            xlinkHref="#robotics-a"
          />
          <linearGradient
            id="robotics-g"
            x1="531.02"
            y1="254"
            x2="581.7"
            y2="254"
            xlinkHref="#robotics-a"
          />
          <linearGradient
            id="robotics-h"
            x1="241.33"
            y1="440.15"
            x2="373.17"
            y2="440.15"
            xlinkHref="#robotics-a"
          />
          <linearGradient
            id="robotics-i"
            x1="530.69"
            y1="505.04"
            x2="547.86"
            y2="505.04"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0" stopColor="#203075" />
            <stop offset="1" stopColor="#0a1236" />
            <stop offset="1" />
          </linearGradient>
          <linearGradient
            id="robotics-j"
            x1="513.84"
            y1="488.95"
            x2="564.71"
            y2="488.95"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0" stopColor="#5ec2bb" />
            <stop offset="1" stopColor="#203075" />
          </linearGradient>
        </defs>

        <polygon
          id="base"
          className="robotics-a"
          points="403.2 609.61 38.36 511.1 628.96 351.64 993.8 450.15 403.2 609.61"
        />

        <g id="grid">
          <line className="robotics-b" x1="417.61" y1="640.89" x2="1036.24" y2="473.63" />
          <line className="robotics-b" x1="165.63" y1="634.65" x2="784.25" y2="467.4" />
          <line className="robotics-b" x1="50.31" y1="628.94" x2="668.94" y2="461.69" />
          <line className="robotics-b" x1="136.81" y1="421.15" x2="820.27" y2="604.08" />
          <line className="robotics-b" x1="0.13" y1="421.15" x2="683.59" y2="604.08" />
        </g>

        <g id="screen0">
          <polygon
            className="robotics-c"
            points="675.69 207.83 424.96 140.65 424.96 0 675.69 67.18 675.69 207.83"
          />
          <line className="robotics-d" x1="447.85" y1="39.34" x2="477.99" y2="47.42" />
          <line className="robotics-d" x1="485.52" y1="49.44" x2="499.76" y2="53.25" />
          <line className="robotics-d" x1="507.29" y1="55.27" x2="521.52" y2="59.08" />
          <line className="robotics-d" x1="447.85" y1="50.23" x2="493.9" y2="62.56" />
          <line className="robotics-d" x1="500.59" y1="64.36" x2="529.06" y2="71.99" />
          <line className="robotics-d" x1="447.85" y1="61.11" x2="460.41" y2="64.47" />
          <line className="robotics-d" x1="543.34" y1="86.7" x2="555.9" y2="90.06" />
          <line className="robotics-d" x1="543.34" y1="64.93" x2="555.9" y2="68.29" />
          <line className="robotics-d" x1="546.97" y1="76.78" x2="555.9" y2="79.18" />
          <line className="robotics-d" x1="543.34" y1="120.18" x2="555.9" y2="123.55" />
          <line className="robotics-d" x1="543.34" y1="98.42" x2="555.9" y2="101.78" />
          <line className="robotics-d" x1="546.97" y1="110.27" x2="555.9" y2="112.66" />
          <line className="robotics-d" x1="543.34" y1="131.9" x2="555.9" y2="135.27" />
          <line className="robotics-d" x1="546.97" y1="143.76" x2="555.9" y2="146.15" />
          <line className="robotics-d" x1="467.66" y1="66.42" x2="488.04" y2="71.88" />
          <line className="robotics-d" x1="495.29" y1="73.82" x2="512.87" y2="78.53" />
          <line className="robotics-d" x1="447.85" y1="71.99" x2="491.94" y2="83.81" />
          <line className="robotics-d" x1="497.8" y1="85.38" x2="518.18" y2="90.84" />
          <line className="robotics-d" x1="447.85" y1="83.71" x2="477.99" y2="91.79" />
          <line className="robotics-d" x1="485.52" y1="93.81" x2="499.76" y2="97.62" />
          <line className="robotics-d" x1="507.29" y1="99.64" x2="521.52" y2="103.45" />
          <line className="robotics-d" x1="447.85" y1="94.6" x2="493.9" y2="106.94" />
          <line className="robotics-d" x1="500.59" y1="108.73" x2="529.06" y2="116.36" />
          <line className="robotics-d" x1="447.85" y1="105.48" x2="460.41" y2="108.85" />
          <line className="robotics-d" x1="467.66" y1="110.79" x2="488.04" y2="116.25" />
          <line className="robotics-d" x1="495.29" y1="118.19" x2="512.87" y2="122.9" />
          <line className="robotics-d" x1="447.85" y1="116.37" x2="491.94" y2="128.18" />
          <line className="robotics-d" x1="497.8" y1="129.75" x2="518.18" y2="135.21" />
          <polygon
            className="robotics-e"
            points="608.32 96.01 578.17 87.94 578.17 74.26 608.32 82.34 608.32 96.01"
          />
          <polygon
            className="robotics-e"
            points="647.54 106.52 617.4 98.45 617.4 84.77 647.54 92.85 647.54 106.52"
          />
          <polygon
            className="robotics-e"
            points="608.32 122.66 578.17 114.59 578.17 100.91 608.32 108.99 608.32 122.66"
          />
          <polygon
            className="robotics-e"
            points="647.54 133.18 617.4 125.1 617.4 111.42 647.54 119.5 647.54 133.18"
          />
          <path
            className="robotics-f"
            d="M581,152l66.54,17.83V156.76s-6.11-10.21-11.76-4.54c-2.74,2.74-6.22,3.7-11.25,2.84-8.89-1.53-3.35-10.81-13.39-13.5s-4.89,8.18-18.7,4.48C584.07,143.79,581,152,581,152Z"
          />
        </g>

        <g id="screen1">
          <polygon
            className="robotics-c"
            points="956.23 284.35 705.5 217.17 705.5 76.52 956.23 143.7 956.23 284.35"
          />
          <line className="robotics-d" x1="728.39" y1="115.86" x2="758.53" y2="123.94" />
          <line className="robotics-d" x1="766.06" y1="125.96" x2="780.3" y2="129.77" />
          <line className="robotics-d" x1="787.83" y1="131.79" x2="802.06" y2="135.6" />
          <line className="robotics-d" x1="728.39" y1="126.75" x2="774.44" y2="139.09" />
          <line className="robotics-d" x1="781.13" y1="140.88" x2="809.6" y2="148.51" />
          <line className="robotics-d" x1="728.39" y1="137.63" x2="740.95" y2="141" />
          <line className="robotics-d" x1="823.88" y1="163.22" x2="836.44" y2="166.58" />
          <line className="robotics-d" x1="823.88" y1="141.45" x2="836.44" y2="144.81" />
          <line className="robotics-d" x1="827.51" y1="153.31" x2="836.44" y2="155.7" />
          <line className="robotics-d" x1="823.88" y1="196.71" x2="836.44" y2="200.07" />
          <line className="robotics-d" x1="823.88" y1="174.94" x2="836.44" y2="178.3" />
          <line className="robotics-d" x1="827.51" y1="186.79" x2="836.44" y2="189.19" />
          <line className="robotics-d" x1="823.88" y1="208.43" x2="836.44" y2="211.79" />
          <line className="robotics-d" x1="827.51" y1="220.28" x2="836.44" y2="222.68" />
          <line className="robotics-d" x1="748.2" y1="142.94" x2="768.58" y2="148.4" />
          <line className="robotics-d" x1="775.83" y1="150.34" x2="793.41" y2="155.05" />
          <line className="robotics-d" x1="728.39" y1="148.51" x2="772.48" y2="160.33" />
          <line className="robotics-d" x1="778.34" y1="161.9" x2="798.72" y2="167.36" />
          <line className="robotics-d" x1="728.39" y1="160.24" x2="758.53" y2="168.31" />
          <line className="robotics-d" x1="766.06" y1="170.33" x2="780.3" y2="174.14" />
          <line className="robotics-d" x1="787.83" y1="176.16" x2="802.06" y2="179.98" />
          <line className="robotics-d" x1="728.39" y1="171.12" x2="774.44" y2="183.46" />
          <line className="robotics-d" x1="781.13" y1="185.25" x2="809.6" y2="192.88" />
          <line className="robotics-d" x1="728.39" y1="182" x2="740.95" y2="185.37" />
          <line className="robotics-d" x1="748.2" y1="187.31" x2="768.58" y2="192.77" />
          <line className="robotics-d" x1="775.83" y1="194.72" x2="793.41" y2="199.43" />
          <line className="robotics-d" x1="728.39" y1="192.89" x2="772.48" y2="204.7" />
          <line className="robotics-d" x1="778.34" y1="206.27" x2="798.72" y2="211.73" />
          <polygon
            className="robotics-e"
            points="888.86 172.53 858.72 164.46 858.72 150.78 888.86 158.86 888.86 172.53"
          />
          <polygon
            className="robotics-e"
            points="928.08 183.05 897.94 174.97 897.94 161.29 928.08 169.37 928.08 183.05"
          />
          <polygon
            className="robotics-e"
            points="888.86 199.19 858.72 191.11 858.72 177.44 888.86 185.51 888.86 199.19"
          />
          <polygon
            className="robotics-e"
            points="928.08 209.7 897.94 201.62 897.94 187.95 928.08 196.02 928.08 209.7"
          />
          <path
            className="robotics-f"
            d="M861.54,228.56l66.54,17.83V233.28s-6.11-10.21-11.76-4.54c-2.74,2.75-6.22,3.7-11.25,2.84-8.89-1.52-3.35-10.81-13.39-13.5s-4.89,8.18-18.7,4.48C864.61,220.32,861.54,228.56,861.54,228.56Z"
          />
        </g>

        <g id="robot">
          <path
            className="robotics-g"
            d="M497,403.16c-.85-29.42-24.73-59.24-54.13-67.11a51.13,51.13,0,0,0-20.57-1.46l-.09-.07-1,.28a40.35,40.35,0,0,0-8.11,2.2l-47.85,13a50.17,50.17,0,0,1,23.43.91c29.5,7.91,53.46,37.82,54.31,67.35l.08,0v58.51l-.21-.05a.11.11,0,0,1,0,.05c1.41,3.27,54.21-15.26,54.21-15.26V403.18Z"
          />
          <path
            className="robotics-h"
            d="M443,418.22c-.85-29.53-24.81-59.44-54.31-67.35s-53.46,9.18-54.31,38.24h-.06v1.09c0,.17,0,.33,0,.5s0,.34,0,.51v56.41l108.76,29.14V418.24Z"
          />

          <path
            id="robot-underarm-back"
            className="robotics-i"
            d="M440.07,232.44c9.46-3.66,15.72-12.67,15.72-24.82,0-19.17-15.55-38.88-34.72-44-8.05-2.16-15.45-1.37-21.34,1.65l0,0-.21.13a23.67,23.67,0,0,0-3.4,2.18l-144,92.22h0c-6.77,4.52-11,12.37-11,22.38,0,19.17,15.54,38.88,34.71,44,8.28,2.22,15.88,1.34,21.85-1.9h0a24.51,24.51,0,0,0,2.91-1.88Z"
          />

          <path
            className="robotics-h"
            d="M201.57,295.57l63.19,89.81s20.94-10.78,24.41-13.87-59.67-86.25-59.67-86.25Z"
          />
          <polygon
            className="robotics-j"
            points="213.19 278.87 356.85 490.42 418.42 471.48 276.2 267.82 213.19 278.87"
          />

          <g id="robot-hand">
            <polygon
              className="robotics-h"
              points="495.33 300.51 347 250.38 347 190.93 495.33 241.06 495.33 300.51"
            />
            <polygon
              className="robotics-k"
              points="495.32 241.4 346.99 191.99 400.53 177.54 548.86 226.94 495.32 241.4"
            />
            <polygon
              className="robotics-l"
              points="548.86 286.59 495.33 300.93 495.33 241.47 548.86 227.13 548.86 286.59"
            />
            <path
              className="robotics-m"
              d="M576.14,278.88h0L571,277.47l-.18-.06-1.85-.5h0l-30-8c-4.37-1.17-8,1.45-8,5.83s3.58,8.91,8,10.08l31.82,8.53a8.23,8.23,0,0,0,1.36.23,7.33,7.33,0,0,0,1.94.27,7.61,7.61,0,0,0,2-14.94Z"
            />
            <path
              className="robotics-n"
              d="M576.14,251.67h0L571,250.26l-.18-.06-1.85-.5h0l-30-8c-4.37-1.18-8,1.44-8,5.82s3.58,8.91,8,10.08l31.82,8.53a9.47,9.47,0,0,0,1.36.24,7.76,7.76,0,0,0,1.94.26,7.61,7.61,0,0,0,2-14.94Z"
            />
            <path
              className="robotics-h"
              d="M553.68,284.64h0l-5.18-1.41-.18-.06-1.85-.49h0l-30-8c-4.38-1.18-7.95,1.44-7.95,5.82s3.57,8.91,7.95,10.08l31.81,8.53a10,10,0,0,0,1.36.24,7.78,7.78,0,0,0,2,.26,7.61,7.61,0,0,0,2-14.93Z"
            />
            <path
              className="robotics-h"
              d="M553.68,257.43h0L548.5,256l-.18-.06-1.85-.49h0l-30-8c-4.38-1.18-7.95,1.44-7.95,5.82s3.57,8.91,7.95,10.08l31.81,8.53a8.65,8.65,0,0,0,1.36.24,7.78,7.78,0,0,0,2,.26,7.61,7.61,0,0,0,2-14.93Z"
            />
          </g>

          <path
            id="robot-underarm-front"
            className="robotics-h"
            d="M394.29,232.44c9.45-3.66,15.71-12.67,15.71-24.82,0-19.17-15.54-38.88-34.71-44-8.06-2.16-15.45-1.37-21.35,1.65v0l-.21.13a23.13,23.13,0,0,0-3.4,2.18l-144,92.22h0c-6.78,4.52-11,12.37-11,22.38,0,19.17,15.55,38.88,34.72,44,8.28,2.22,15.88,1.34,21.85-1.9h0a24.51,24.51,0,0,0,2.91-1.88Z"
          />

          <path
            className="robotics-h"
            d="M319.07,452.73c-.85-29.53-24.81-59.44-54.31-67.35s-53.46,9.18-54.31,38.24h-.06v1.09c0,.17,0,.33,0,.5s0,.34,0,.51v56.41l108.76,29.14V452.75Z"
          />
          <path
            className="robotics-o"
            d="M373.09,437.67c-.86-29.42-24.73-59.24-54.13-67.11a51.13,51.13,0,0,0-20.57-1.46l-.09-.07-1,.28a40.63,40.63,0,0,0-8.12,2.2l-47.84,13a50.17,50.17,0,0,1,23.43.91c29.5,7.91,53.46,37.82,54.31,67.35l.08,0v58.51l-.21-.05a.11.11,0,0,1,0,.05c1.41,3.27,54.21-15.26,54.21-15.26V437.69Z"
          />
        </g>

        <g id="screen2">
          <polygon
            className="robotics-c"
            points="794.3 372.41 543.57 439.59 543.57 298.94 794.3 231.76 794.3 372.41"
          />
          <line className="robotics-d" x1="566.46" y1="326.02" x2="596.6" y2="317.94" />
          <line className="robotics-d" x1="604.14" y1="315.92" x2="618.37" y2="312.11" />
          <line className="robotics-d" x1="625.9" y1="310.09" x2="640.14" y2="306.28" />
          <line className="robotics-d" x1="566.46" y1="336.9" x2="612.51" y2="324.56" />
          <line className="robotics-d" x1="619.21" y1="322.77" x2="647.67" y2="315.14" />
          <line className="robotics-d" x1="566.46" y1="347.78" x2="579.02" y2="344.42" />
          <line className="robotics-d" x1="661.95" y1="322.2" x2="674.51" y2="318.83" />
          <line className="robotics-d" x1="661.95" y1="300.43" x2="674.51" y2="297.07" />
          <line className="robotics-d" x1="665.58" y1="310.34" x2="674.51" y2="307.95" />
          <line className="robotics-d" x1="661.95" y1="355.69" x2="674.51" y2="352.32" />
          <line className="robotics-d" x1="661.95" y1="333.92" x2="674.51" y2="330.55" />
          <line className="robotics-d" x1="665.58" y1="343.83" x2="674.51" y2="341.44" />
          <line className="robotics-d" x1="661.95" y1="367.41" x2="674.51" y2="364.04" />
          <line className="robotics-d" x1="665.58" y1="377.32" x2="674.51" y2="374.93" />
          <line className="robotics-d" x1="586.28" y1="342.48" x2="606.65" y2="337.02" />
          <line className="robotics-d" x1="613.9" y1="335.07" x2="631.48" y2="330.36" />
          <line className="robotics-d" x1="566.46" y1="358.67" x2="610.55" y2="346.85" />
          <line className="robotics-d" x1="616.41" y1="345.28" x2="636.79" y2="339.82" />
          <line className="robotics-d" x1="566.46" y1="370.39" x2="596.6" y2="362.31" />
          <line className="robotics-d" x1="604.14" y1="360.29" x2="618.37" y2="356.48" />
          <line className="robotics-d" x1="625.9" y1="354.46" x2="640.14" y2="350.65" />
          <line className="robotics-d" x1="566.46" y1="381.27" x2="612.51" y2="368.93" />
          <line className="robotics-d" x1="619.21" y1="367.14" x2="647.67" y2="359.51" />
          <line className="robotics-d" x1="566.46" y1="392.16" x2="579.02" y2="388.79" />
          <line className="robotics-d" x1="586.28" y1="386.85" x2="606.65" y2="381.39" />
          <line className="robotics-d" x1="613.9" y1="379.44" x2="631.48" y2="374.73" />
          <line className="robotics-d" x1="566.46" y1="403.04" x2="610.55" y2="391.23" />
          <line className="robotics-d" x1="616.41" y1="389.66" x2="636.79" y2="384.2" />
          <polygon
            className="robotics-e"
            points="726.93 296.69 696.79 304.77 696.79 291.1 726.93 283.02 726.93 296.69"
          />
          <polygon
            className="robotics-e"
            points="766.15 286.18 736.01 294.26 736.01 280.59 766.15 272.51 766.15 286.18"
          />
          <polygon
            className="robotics-e"
            points="726.93 323.35 696.79 331.42 696.79 317.75 726.93 309.67 726.93 323.35"
          />
          <polygon
            className="robotics-e"
            points="766.15 312.83 736.01 320.91 736.01 307.24 766.15 299.16 766.15 312.83"
          />
          <path
            className="robotics-f"
            d="M699.61,367.36l66.54-17.83V336.42s-6.1-6.94-11.76,1.76a21.47,21.47,0,0,1-11.25,8.87c-8.89,3.24-3.35-9-13.39-6.32s-4.89,10.8-18.7,14.5C702.68,357.47,699.61,367.36,699.61,367.36Z"
          />
        </g>

        <g id="pillar3">
          <polygon
            className="robotics-p"
            points="949.97 537.64 906.16 525.9 906.16 410.04 949.97 421.78 949.97 537.64"
          />
          <polygon
            className="robotics-f"
            points="949.97 421.87 906.15 410.04 949.97 398.2 993.8 410.04 949.97 421.87"
          />
          <polygon
            className="robotics-q"
            points="993.79 525.9 949.97 537.64 949.97 421.78 993.79 410.04 993.79 525.9"
          />
        </g>

        <g id="pillar2">
          <polygon
            className="robotics-p"
            points="882.37 558.57 838.55 546.83 838.55 342.78 882.37 354.52 882.37 558.57"
          />
          <polygon
            className="robotics-f"
            points="882.37 354.61 838.55 342.78 882.37 330.94 926.19 342.78 882.37 354.61"
          />
          <polygon
            className="robotics-q"
            points="926.19 546.83 882.37 558.57 882.37 354.52 926.19 342.78 926.19 546.83"
          />
        </g>

        <g id="pillar1">
          <polygon
            className="robotics-p"
            points="770.03 590.88 726.21 579.14 726.21 419.18 770.03 430.92 770.03 590.88"
          />
          <polygon
            className="robotics-f"
            points="770.03 431.01 726.21 419.18 770.03 407.35 813.86 419.18 770.03 431.01"
          />
          <polygon
            className="robotics-q"
            points="813.85 579.14 770.03 590.88 770.03 430.92 813.85 419.18 813.85 579.14"
          />
        </g>

        <g id="pillar0">
          <polygon
            className="robotics-p"
            points="677.22 621.35 633.4 609.61 633.4 493.75 677.22 505.49 677.22 621.35"
          />
          <polygon
            className="robotics-f"
            points="677.22 505.58 633.39 493.75 677.22 481.92 721.04 493.75 677.22 505.58"
          />
          <polygon
            className="robotics-q"
            points="721.04 609.61 677.22 621.35 677.22 505.49 721.04 493.75 721.04 609.61"
          />
        </g>

        <g id="joystick">
          <polygon
            className="robotics-r"
            points="607.06 610.56 538.93 628.81 538.93 608.21 607.06 589.95 607.06 610.56"
          />
          <polygon
            className="robotics-r"
            points="538.93 628.81 470.81 610.56 470.81 589.95 538.93 608.21 538.93 628.81"
          />
          <polygon
            className="robotics-q"
            points="538.93 571.67 607.06 589.95 538.93 608.21 470.81 589.95 538.93 571.67"
          />
          <polygon
            id="joystick-shadow"
            className="robotics-a"
            points="538.93 634.29 607.06 652.58 538.93 670.83 470.81 652.58 538.93 634.29"
          />

          <g id="handle">
            <ellipse className="robotics-s" cx="539.28" cy="505.04" rx="8.59" ry="2.32" />
            <path
              className="robotics-r"
              d="M545.35,506.68a27.38,27.38,0,0,1-12.15,0c-1.67-.45-2.5-1-2.51-1.63v77.26h0a8.61,8.61,0,0,0,17.11,0h0V505C547.86,505.63,547,506.23,545.35,506.68Z"
            />
            <circle className="robotics-t" cx="539.28" cy="488.95" r="25.43" />
          </g>
        </g>
      </svg>
    </div>
  );
}

function animate() {
  const svgs = document.querySelectorAll('.robotics-illustration');
  if (!svgs) return;

  const timeline = anime.timeline({
    autoplay: false,
    loop: false,
  });

  const animations = [
    {
      targets: '#base',
      translateY: [30, 0],
      opacity: [0, 0.25],
      easing: 'easeInOutSine',
      duration: 500,
      delay: 0,
    },
    {
      targets: '#joystick',
      translateY: [20, 0],
      opacity: [0, 1],
      easing: 'easeOutExpo',
      duration: 750,
      delay: 300,
    },
    {
      targets: '#joystick-shadow',
      translateY: [-15, 0],
      opacity: [0, 0.25],
      easing: 'easeOutExpo',
      duration: 700,
      delay: 300,
    },
    {
      targets: '#robot',
      translateX: [-40, 0],
      translateY: [-12, 0],
      opacity: {
        value: [0, 1],
        duration: 500,
        easing: 'linear',
        delay: 200,
      },
      easing: 'easeInOutSine',
      duration: 1500,
      delay: 500,
    },
    {
      targets: '#robot-hand',
      translateX: [20, 0],
      translateY: [6, 0],
      rotate: ['5deg', 0],
      easing: 'linear',
      duration: 1500,
      delay: 500,
    },
    {
      targets: '#robot-underarm-front, #robot-underarm-back',
      rotate: ['20deg', 0],
      easing: 'linear',
      duration: 1500,
      delay: 500,
    },
    {
      targets: '#handle',
      rotateZ: ['10deg', '10deg', '10deg', 0],
      rotateX: ['-10deg', '-10deg', '-10deg', 0],
      easing: 'easeInOutSine',
      duration: 1500,
      delay: 500,
    },
    {
      targets: '#grid line',
      strokeDashoffset: [anime.setDashoffset, 0],
      easing: 'easeInOutSine',
      duration: 1200,
      delay: 200,
    },
    ...[0, 1, 2, 3].map(idx => {
      return {
        targets: `#pillar${idx}`,
        translateY: [30, 0],
        opacity: [0, 1],
        easing: 'easeOutCirc',
        duration: 750,
        delay: 800 + idx * 100,
      };
    }),
    ...[0, 1, 2].map(idx => {
      return {
        targets: `#screen${idx}`,
        translateY: [30, 0],
        opacity: [0, 1],
        easing: 'easeOutCirc',
        duration: 750,
        delay: 1500 - idx * 100,
      };
    }),
    ...[0, 1, 2].map(idx => {
      return {
        targets: `#screen${idx} polygon, #screen${idx} line`,
        strokeDashoffset: [anime.setDashoffset, 0],
        easing: 'linear',
        duration: 1000,
        delay: 1800 - idx * 200,
      };
    }),
  ];

  animations.forEach(animation => timeline.add(animation, 0));

  svgs.forEach(svg => (svg.style.opacity = '1'));

  timeline.play();
}
