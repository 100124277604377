import { useEffect } from 'react';
import anime from 'animejs/lib/anime.es.js';
import 'styles/illustrations/proptech.css';

export default function PropTech() {
  useEffect(() => {
    animate();
  }, []);

  return (
    <div className="proptech-illustration">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        viewBox="0 0 956 741"
      >
        <title>PropTech</title>
        <defs>
          <linearGradient
            id="proptech-b"
            x1="748.48"
            y1="412.05"
            x2="526.7"
            y2="352.91"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0" stopColor="#203075" />
            <stop offset="1" stopColor="#ffe964" />
          </linearGradient>
          <linearGradient
            id="proptech-c"
            x1="1026.24"
            y1="319.28"
            x2="308.09"
            y2="270.7"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.18" stopColor="#203075" />
            <stop offset="1" stopColor="#ffe964" />
          </linearGradient>
          <linearGradient
            id="proptech-d"
            x1="748.48"
            y1="250.84"
            x2="526.7"
            y2="191.7"
            xlinkHref="#proptech-b"
          />
          <linearGradient
            id="proptech-e"
            x1="1026.24"
            y1="158.08"
            x2="308.09"
            y2="109.5"
            xlinkHref="#proptech-c"
          />
          <linearGradient
            id="proptech-f"
            x1="309.91"
            y1="529.97"
            x2="172.68"
            y2="493.38"
            xlinkHref="#proptech-b"
          />
          <linearGradient
            id="proptech-g"
            x1="464.48"
            y1="363.96"
            x2="54.53"
            y2="336.22"
            xlinkHref="#proptech-c"
          />
          <linearGradient
            id="proptech-h"
            x1="632.37"
            y1="642.99"
            x2="941.54"
            y2="642.99"
            xlinkHref="#proptech-b"
          />
          <linearGradient
            id="proptech-i"
            x1="-536.12"
            y1="1838.89"
            x2="-381.74"
            y2="1838.89"
            gradientTransform="translate(2397.45 -351.97) rotate(45)"
            xlinkHref="#proptech-b"
          />
          <linearGradient
            id="proptech-j"
            x1="464.48"
            y1="172.86"
            x2="54.53"
            y2="145.13"
            xlinkHref="#proptech-c"
          />
        </defs>

        <polygon
          id="base"
          className="proptech-b"
          points="364.84 658.58 0 560.08 590.6 400.62 955.44 499.12 364.84 658.58"
        />

        <polygon
          id="building-1-shadow"
          className="proptech-d"
          points="751.41 525.87 894.77 487.45 721.51 440.7 579.09 479.67 751.41 525.87"
        />
        <g id="building-1">
          <polygon
            className="proptech-e"
            points="751.51 483.66 579.09 437.46 579.09 296.07 751.51 342.26 751.51 483.66"
          />
          <polygon
            className="proptech-c"
            points="894.77 303.85 751.41 342.27 751.41 483.74 894.77 445.33 894.77 303.85"
          />
          <polygon
            className="proptech-h"
            points="751.41 342.27 894.77 303.85 721.51 257.1 579.09 296.07 751.41 342.27"
          />
        </g>

        <g id="building-1-windows">
          <polygon
            className="proptech-f"
            points="622.84 364.71 611.29 361.62 611.29 338.02 622.84 341.11 622.84 364.71"
          />
          <polygon
            className="proptech-f"
            points="640.79 369.52 629.25 366.43 629.25 342.83 640.79 345.92 640.79 369.52"
          />
          <polygon
            className="proptech-f"
            points="658.75 374.33 647.2 371.24 647.2 347.64 658.75 350.73 658.75 374.33"
          />
          <polygon
            className="proptech-f"
            points="622.84 397.91 611.29 394.82 611.29 371.22 622.84 374.31 622.84 397.91"
          />
          <polygon
            className="proptech-f"
            points="640.79 402.72 629.25 399.63 629.25 376.03 640.79 379.12 640.79 402.72"
          />
          <polygon
            className="proptech-f"
            points="658.75 407.53 647.2 404.44 647.2 380.84 658.75 383.93 658.75 407.53"
          />
          <polygon
            className="proptech-f"
            points="722.96 391.54 711.41 388.44 711.41 364.84 722.96 367.94 722.96 391.54"
          />
          <polygon
            className="proptech-f"
            points="740.91 396.35 729.36 393.25 729.36 369.65 740.91 372.75 740.91 396.35"
          />
          <polygon
            className="proptech-f"
            points="722.96 424.74 711.41 421.64 711.41 398.04 722.96 401.14 722.96 424.74"
          />
          <polygon
            className="proptech-f"
            points="740.91 429.55 729.36 426.45 729.36 402.85 740.91 405.95 740.91 429.55"
          />

          <polygon
            className="proptech-g"
            points="777.03 392.35 765.48 395.45 765.48 371.85 777.03 368.75 777.03 392.35"
          />
          <polygon
            className="proptech-g"
            points="794.98 387.54 783.43 390.64 783.43 367.04 794.98 363.94 794.98 387.54"
          />
          <polygon
            className="proptech-g"
            points="812.94 382.73 801.39 385.83 801.39 362.23 812.94 359.13 812.94 382.73"
          />
          <polygon
            className="proptech-g"
            points="830.89 377.92 819.34 381.01 819.34 357.42 830.89 354.32 830.89 377.92"
          />
          <polygon
            className="proptech-g"
            points="777.03 425.55 765.48 428.64 765.48 405.05 777.03 401.95 777.03 425.55"
          />
          <polygon
            className="proptech-g"
            points="794.98 420.74 783.43 423.83 783.43 400.24 794.98 397.14 794.98 420.74"
          />
          <polygon
            className="proptech-g"
            points="812.94 415.93 801.39 419.02 801.39 395.42 812.94 392.33 812.94 415.93"
          />
          <polygon
            className="proptech-g"
            points="830.89 411.12 819.34 414.21 819.34 390.61 830.89 387.52 830.89 411.12"
          />
        </g>

        <polygon
          id="building-2-wall"
          className="proptech-c"
          points="653.13 74.9 509.76 113.31 509.76 254.79 653.13 216.38 653.13 74.9"
        />
        <g id="building-2">
          <polygon
            className="proptech-i"
            points="751.51 322.46 579.09 276.26 579.09 134.86 751.51 181.06 751.51 322.46"
          />
          <polygon
            className="proptech-c"
            points="894.77 142.65 751.41 181.06 751.41 322.54 894.77 284.12 894.77 142.65"
          />
          <polygon
            className="proptech-j"
            points="751.41 181.06 894.77 142.65 721.51 95.89 579.09 134.86 751.41 181.06"
          />
        </g>

        <g id="building-2-windows">
          <polygon
            className="proptech-g"
            points="777.03 259.63 765.48 262.72 765.48 239.12 777.03 236.03 777.03 259.63"
          />
          <polygon
            className="proptech-g"
            points="794.98 254.81 783.43 257.91 783.43 234.31 794.98 231.22 794.98 254.81"
          />
          <polygon
            className="proptech-g"
            points="812.94 250 801.39 253.1 801.39 229.5 812.94 226.4 812.94 250"
          />
          <polygon
            className="proptech-g"
            points="830.89 245.19 819.34 248.29 819.34 224.69 830.89 221.59 830.89 245.19"
          />
          <polygon
            className="proptech-g"
            points="777.03 292.82 765.48 295.92 765.48 272.32 777.03 269.22 777.03 292.82"
          />
          <polygon
            className="proptech-g"
            points="794.98 288.01 783.43 291.11 783.43 267.51 794.98 264.41 794.98 288.01"
          />
          <polygon
            className="proptech-g"
            points="812.94 283.2 801.39 286.3 801.39 262.7 812.94 259.6 812.94 283.2"
          />
          <polygon
            className="proptech-g"
            points="830.89 278.39 819.34 281.49 819.34 257.89 830.89 254.79 830.89 278.39"
          />
        </g>

        <polygon
          id="building-3-shadow"
          className="proptech-d"
          points="307.59 636.26 389.43 614.33 290.52 587.64 209.23 609.88 307.59 636.26"
        />
        <g id="building-3">
          <polygon
            className="proptech-k"
            points="307.65 589.77 209.23 563.4 209.23 442.73 307.65 469.1 307.65 589.77"
          />
          <polygon
            className="proptech-l"
            points="389.43 447.17 307.59 469.1 307.59 589.82 389.43 567.89 389.43 447.17"
          />
        </g>

        <g id="building-3-windows">
          <polygon
            className="proptech-f"
            points="240.86 507.85 230.25 505.01 230.25 483.34 240.86 486.18 240.86 507.85"
          />
          <polygon
            className="proptech-f"
            points="257.34 512.27 246.74 509.43 246.74 487.76 257.34 490.6 257.34 512.27"
          />
          <polygon
            className="proptech-f"
            points="273.82 516.68 263.22 513.84 263.22 492.17 273.82 495.01 273.82 516.68"
          />
          <polygon
            className="proptech-f"
            points="240.86 538.33 230.25 535.49 230.25 513.82 240.86 516.66 240.86 538.33"
          />
          <polygon
            className="proptech-f"
            points="257.34 542.75 246.74 539.91 246.74 518.24 257.34 521.08 257.34 542.75"
          />
          <polygon
            className="proptech-f"
            points="273.82 547.16 263.22 544.32 263.22 522.65 273.82 525.5 273.82 547.16"
          />
        </g>

        <g id="building-4">
          <polygon
            className="proptech-l"
            points="389.43 355.15 307.59 377.07 307.59 457.84 389.43 435.91 389.43 355.15"
          />
          <polygon
            className="proptech-m"
            points="307.59 377.07 389.43 355.15 290.52 328.46 209.23 350.7 307.59 377.07"
          />
        </g>

        <path
          id="phone-shadow"
          className="proptech-d"
          d="M727.05,739.14,636.6,714.71c-5.49-1.48-5.49-3.88,0-5.36l187.75-50.69a45,45,0,0,1,19.86,0l90.46,24.42c5.48,1.48,5.48,3.88,0,5.36l-187.75,50.7A45,45,0,0,1,727.05,739.14Z"
        />
        <g id="phone">
          <path
            className="proptech-n"
            d="M727.83,683.6,636.53,659c-5.54-1.49-5.54-3.92,0-5.41L826,602.37a45.53,45.53,0,0,1,20.05,0L937.39,627c5.54,1.5,5.54,3.92,0,5.42L747.88,683.6A45.23,45.23,0,0,1,727.83,683.6Z"
          />
          <polygon
            className="proptech-o"
            points="754.11 677.72 658.32 651.86 827.47 606.18 923.27 632.05 754.11 677.72"
          />
          <path className="proptech-c" d="M941.53,629.67v0h0A.11.11,0,0,1,941.53,629.67Z" />
          <path
            className="proptech-c"
            d="M937.39,632.44,747.88,683.6a45.23,45.23,0,0,1-20,0L636.53,659c-2.6-.7-4-1.6-4.12-2.52h0v12.87h0c.16.92,1.54,1.82,4.14,2.52l91.3,24.66a45.53,45.53,0,0,0,20,0L937.39,645.3c2.66-.72,4-1.65,4.13-2.59h0v-.06a.24.24,0,0,0,0-.12V629.79C941.48,630.75,940.11,631.7,937.39,632.44Z"
          />
          <ellipse className="proptech-c" cx="697.74" cy="668.44" rx="9" ry="2.43" />
          <ellipse className="proptech-c" cx="892.9" cy="620.9" rx="4.5" ry="1.22" />
          <polygon
            className="proptech-c"
            points="880.83 617.64 860.62 612.19 862.43 611.7 882.64 617.15 880.83 617.64"
          />
        </g>

        <g id="tree-1">
          <path
            className="proptech-l"
            d="M456.41,471.29c12.83-3.18,25.45-10.1,24.38-26.28,0-4.22-.69-8.46-1.1-13-10.24,2.56-18.72,6.78-23.59,16-3.3,6.25-1.48,16.52.31,23.26"
          />
          <path
            className="proptech-l"
            d="M461.26,512.61c9.44,3.26,20.29,4.06,26.26-6.64,1.77-2.66,3.05-5.65,4.65-8.68-7.56-2.59-14.66-3.4-21.54.44-4.67,2.61-7.74,9.87-9.37,14.88"
          />
          <path
            className="proptech-l"
            d="M450.37,497.17c-11.42,1-23.72-1.13-27.39-14.71-1.23-3.45-1.81-7.14-2.75-10.95,9.13-.78,17.27.3,23.86,6.49,4.47,4.2,5.86,13.14,6.28,19.17"
          />
          <path
            className="proptech-p"
            d="M467.89,554a65.28,65.28,0,0,0-3.39-28.46c-2.91-8-6.27-12-8.36-15.94-4.69-8.91-6.46-24.55,8.62-53.8"
          />
        </g>

        <g id="tree-2">
          <path
            className="proptech-l"
            d="M845,127.86c9.3,3.22,20,4,25.88-6.54a95.45,95.45,0,0,0,4.58-8.55c-7.44-2.55-14.45-3.35-21.23.43-4.6,2.57-7.63,9.73-9.23,14.66"
          />
          <path
            className="proptech-l"
            d="M854.17,65.24c-12.65-3.13-25.09-9.95-24-25.9,0-4.17.68-8.34,1.08-12.79,10.1,2.53,18.45,6.68,23.25,15.77,3.26,6.16,1.47,16.28-.3,22.92"
          />
          <path
            className="proptech-l"
            d="M844.93,109.68c-10.71-.16-21.94-3.32-24-16.29-.81-3.33-1-6.81-1.51-10.44,8.57.15,16,1.93,21.55,8.31,3.76,4.34,4.2,12.78,4,18.42"
          />
          <path
            className="proptech-l"
            d="M858.62,80.33c10.76,3.45,23,4.07,29.53-8.18,1.93-3.06,3.3-6.48,5-9.94-8.61-2.73-16.67-3.47-24.35,1.06-5.21,3.07-8.5,11.36-10.21,17.06"
          />
          <path
            className="proptech-p"
            d="M841.41,146a64.34,64.34,0,0,1,3.35-28.07c2.86-7.88,6.18-11.78,8.24-15.7,4.61-8.79,6.36-24.2-8.5-53"
          />
        </g>

        <g id="screen">
          <polygon
            className="proptech-q"
            points="550.13 331.09 332.83 272.87 332.83 150.97 550.13 209.19 550.13 331.09"
          />
          <line className="proptech-r" x1="352.67" y1="185.07" x2="378.79" y2="192.07" />
          <line className="proptech-r" x1="385.32" y1="193.82" x2="397.65" y2="197.12" />
          <line className="proptech-r" x1="404.18" y1="198.87" x2="416.52" y2="202.18" />
          <line className="proptech-r" x1="352.67" y1="194.5" x2="392.57" y2="205.19" />
          <line className="proptech-r" x1="398.38" y1="206.75" x2="423.05" y2="213.36" />
          <line className="proptech-r" x1="352.67" y1="203.93" x2="363.55" y2="206.85" />
          <line className="proptech-r" x1="435.42" y1="226.11" x2="446.31" y2="229.02" />
          <line className="proptech-r" x1="435.42" y1="207.24" x2="446.31" y2="210.16" />
          <line className="proptech-r" x1="438.57" y1="217.52" x2="446.31" y2="219.59" />
          <line className="proptech-r" x1="435.42" y1="255.13" x2="446.31" y2="258.05" />
          <line className="proptech-r" x1="435.42" y1="236.26" x2="446.31" y2="239.18" />
          <line className="proptech-r" x1="438.57" y1="246.54" x2="446.31" y2="248.61" />
          <line className="proptech-r" x1="435.42" y1="265.29" x2="446.31" y2="268.2" />
          <line className="proptech-r" x1="438.57" y1="275.56" x2="446.31" y2="277.64" />
          <line className="proptech-r" x1="369.84" y1="208.53" x2="387.5" y2="213.26" />
          <line className="proptech-r" x1="393.78" y1="214.95" x2="409.02" y2="219.03" />
          <line className="proptech-r" x1="352.67" y1="213.36" x2="390.88" y2="223.6" />
          <line className="proptech-r" x1="395.96" y1="224.97" x2="413.62" y2="229.7" />
          <line className="proptech-r" x1="352.67" y1="223.52" x2="378.79" y2="230.52" />
          <line className="proptech-r" x1="385.32" y1="232.27" x2="397.65" y2="235.58" />
          <line className="proptech-r" x1="404.18" y1="237.33" x2="416.52" y2="240.63" />
          <line className="proptech-r" x1="352.67" y1="232.96" x2="392.57" y2="243.65" />
          <line className="proptech-r" x1="398.38" y1="245.2" x2="423.05" y2="251.81" />
          <line className="proptech-r" x1="352.67" y1="242.39" x2="363.55" y2="245.3" />
          <line className="proptech-r" x1="369.84" y1="246.99" x2="387.5" y2="251.72" />
          <line className="proptech-r" x1="393.78" y1="253.41" x2="409.02" y2="257.49" />
          <line className="proptech-r" x1="352.67" y1="251.82" x2="390.88" y2="262.06" />
          <line className="proptech-r" x1="395.96" y1="263.42" x2="413.62" y2="268.15" />
          <polygon
            className="proptech-s"
            points="491.74 234.18 465.62 227.18 465.62 215.33 491.74 222.33 491.74 234.18"
          />
          <polygon
            className="proptech-s"
            points="525.73 243.29 499.61 236.29 499.61 224.44 525.73 231.44 525.73 243.29"
          />
          <polygon
            className="proptech-s"
            points="491.74 293.68 465.62 286.68 465.62 238.43 491.74 245.43 491.74 293.68"
          />
          <polygon
            className="proptech-s"
            points="525.73 302.79 499.61 295.79 499.61 247.54 525.73 254.54 525.73 302.79"
          />
        </g>

        <g id="building-5">
          <polygon
            className="proptech-l"
            points="307.65 306.64 209.23 280.27 209.23 159.59 307.65 185.97 307.65 306.64"
          />
          <polygon
            className="proptech-t"
            points="307.59 185.98 389.43 164.05 290.52 137.36 209.23 159.6 307.59 185.98"
          />
          <polygon
            className="proptech-l"
            points="389.49 83.39 307.65 105.32 307.65 186.08 389.49 164.15 389.49 83.39"
          />
        </g>

        <g id="pipes">
          <path
            className="proptech-u"
            d="M676,135.67V65A28.38,28.38,0,0,0,647.63,36.6H327.71A28.37,28.37,0,0,0,299.33,65V349.41"
          />
          <path
            className="proptech-u"
            d="M751.51,131.87V24.9a24,24,0,0,1,24-24h5.37a24,24,0,0,1,24,24V632.34"
          />
          <path
            className="proptech-u"
            d="M367.91,487.76l407.46-.65A20.28,20.28,0,0,1,795,507.38v127"
          />
        </g>
      </svg>
    </div>
  );
}

const animate = () => {
  const svgs = document.querySelectorAll('.proptech-illustration');
  if (!svgs) return;

  const timeline = anime.timeline({
    autoplay: false,
    loop: false,
  });

  const animations = [
    {
      targets: '#base',
      translateY: [30, 0],
      opacity: [0, 0.63],
      easing: 'easeOutSine',
      duration: 750,
      delay: 0,
    },

    {
      targets: `#building-1-shadow`,
      translateY: [20, 0],
      opacity: [0, 0.5],
      easing: 'easeOutQuad',
      duration: 500,
      delay: 200,
    },
    {
      targets: `#building-1 > polygon`,
      translateY: [-30, 0],
      opacity: [0, 1],
      easing: 'easeOutQuad',
      duration: 300,
      delay: (el, idx) => 200 + idx * 100,
    },
    {
      targets: '#building-1-windows > .proptech-f',
      opacity: [0, 1],
      easing: 'easeOutSine',
      duration: 100,
      delay: (el, idx) => 600 + idx * 50,
    },
    {
      targets: '#building-1-windows > .proptech-g',
      opacity: [0, 0.5],
      easing: 'easeOutSine',
      duration: 100,
      delay: (el, idx) => 900 + idx * 50,
    },

    {
      targets: `#building-2-wall`,
      translateX: [-50, 0],
      opacity: [0, 1],
      easing: 'easeInOutQuad',
      duration: 1500,
      delay: 550,
    },
    {
      targets: `#building-2 > polygon`,
      translateY: [-30, 0],
      opacity: [0, 1],
      easing: 'easeOutQuad',
      duration: 300,
      delay: (el, idx) => 700 + idx * 100,
    },
    {
      targets: '#building-2-windows > .proptech-g',
      opacity: [0, 0.5],
      easing: 'easeOutSine',
      duration: 100,
      delay: (el, idx) => 1100 + idx * 50,
    },

    {
      targets: `#building-3-shadow`,
      translateY: [20, 0],
      opacity: [0, 0.5],
      easing: 'easeOutQuad',
      duration: 750,
      delay: 1000,
    },
    {
      targets: `#building-3 > polygon`,
      translateY: [-30, 0],
      opacity: [0, 1],
      easing: 'easeOutQuad',
      duration: 300,
      delay: (el, idx) => 1000 + idx * 100,
    },
    {
      targets: '#building-3-windows > polygon',
      opacity: [0, 1],
      easing: 'easeOutSine',
      duration: 100,
      delay: (el, idx) => 1400 + idx * 50,
    },

    {
      targets: `#building-4 > polygon`,
      translateY: [-30, 0],
      opacity: [0, 1],
      easing: 'easeOutQuad',
      duration: 300,
      delay: (el, idx) => 1250 + idx * 100,
    },
    {
      targets: `#building-5 > polygon`,
      translateX: [-30, 0],
      translateY: [-10, 0],
      opacity: [0, 1],
      easing: 'easeOutQuad',
      duration: 800,
      delay: (el, idx) => 1500 + idx * 100,
    },

    {
      targets: '#tree-1',
      scaleY: [0.01, 1],
      opacity: [0, 1],
      easing: 'easeOutSine',
      duration: 500,
      delay: 1500,
    },
    {
      targets: '#tree-1 > .proptech-l',
      opacity: [0, 1],
      easing: 'easeOutSine',
      duration: 300,
      delay: (el, i) => 1700 + i * 100,
    },

    {
      targets: '#tree-2',
      scaleY: [0.01, 1],
      opacity: [0, 1],
      easing: 'easeOutSine',
      duration: 500,
      delay: 2300,
    },
    {
      targets: '#tree-2 > .proptech-l',
      opacity: [0, 1],
      easing: 'easeOutSine',
      duration: 300,
      delay: (el, i) => 2500 + i * 100,
    },

    {
      targets: '#screen',
      translateY: [30, 0],
      opacity: [0, 1],
      easing: 'easeOutCirc',
      duration: 750,
      delay: 2250,
    },
    {
      targets: '#screen polygon, #screen line',
      strokeDashoffset: [anime.setDashoffset, 0],
      easing: 'linear',
      duration: 1000,
      delay: 2550,
    },

    {
      targets: '#pipes path',
      strokeDashoffset: [anime.setDashoffset, 0],
      easing: 'linear',
      duration: 1500,
      delay: (el, i) => 1500 + i * 400,
    },

    {
      targets: '#phone',
      translateY: [50, 0],
      opacity: [0, 1],
      duration: 1000,
      delay: 2700,
    },
    {
      targets: '#phone-shadow',
      translateY: [-50, 0],
      opacity: [0, 0.49],
      duration: 1000,
      delay: 2750,
    },
  ];

  animations.forEach(animation => timeline.add(animation, 0));

  svgs.forEach(svg => (svg.style.opacity = '1'));
  timeline.play();
};
