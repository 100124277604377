import { useEffect } from 'react';
import anime from 'animejs/lib/anime.es.js';
import 'styles/illustrations/ai.css';

export default function AI() {
  useEffect(() => {
    animate();
  }, []);

  return (
    <div className="ai-illustration">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        viewBox="0 0 811 686"
      >
        <title>AI Mission</title>
        <defs>
          <linearGradient
            id="New_Gradient_Swatch_2"
            x1="61.45"
            y1="528.7"
            x2="674.02"
            y2="528.7"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0" stopColor="#203075" />
            <stop offset="1" stopColor="#ffe864" />
          </linearGradient>
          <linearGradient
            id="New_Gradient_Swatch_2-2"
            x1="-1011.73"
            y1="2050.87"
            x2="-705.86"
            y2="2050.87"
            gradientTransform="translate(2396.83 -352.22) rotate(45)"
            xlinkHref="#New_Gradient_Swatch_2"
          />
          <linearGradient
            id="New_Gradient_Swatch_4"
            x1="179.22"
            y1="200.5"
            x2="585.48"
            y2="200.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0" stopColor="#5ec2bb" />
            <stop offset="1" stopColor="#203075" />
          </linearGradient>
        </defs>

        <g id="phone">
          <path
            id="shadow"
            className="ai-cls-1"
            d="M301.9,683.44,131,637.31c-10.36-2.8-10.36-7.34,0-10.14l354.64-95.75c10.37-2.8,27.17-2.8,37.53,0l170.86,46.13c10.36,2.8,10.36,7.34,0,10.13L339.43,683.44C329.06,686.24,312.26,686.24,301.9,683.44Z"
          />
          <path
            className="ai-cls-2"
            d="M250.58,609.17,69.68,560.33c-11-3-11-7.76,0-10.73L445.16,448.22c11-3,28.76-3,39.73,0L665.8,497.07c11,3,11,7.76,0,10.72L290.31,609.17C279.34,612.14,261.55,612.14,250.58,609.17Z"
          />
          <polygon
            className="ai-cls-3"
            points="302.65 597.52 112.85 546.27 448.01 455.78 637.81 507.02 302.65 597.52"
          />
          <path className="ai-cls-4" d="M674,502.32v-.11h0S674,502.28,674,502.32Z" />
          <path
            className="ai-cls-4"
            d="M665.8,507.79,290.31,609.17c-11,3-28.76,3-39.73,0L69.68,560.33c-5.14-1.39-7.84-3.18-8.16-5l-.07,0V580.8h0c.32,1.82,3.06,3.61,8.2,5l180.9,48.85c11,3,28.76,3,39.73,0L665.8,533.28c5.26-1.43,8-3.27,8.18-5.13h0V528a.81.81,0,0,0,0-.22V502.54C673.9,504.44,671.18,506.34,665.8,507.79Z"
          />
          <ellipse className="ai-cls-4" cx="190.95" cy="579.13" rx="17.84" ry="4.82" />
          <ellipse className="ai-cls-4" cx="577.65" cy="484.94" rx="8.92" ry="2.41" />
          <polygon
            className="ai-cls-4"
            points="553.72 478.48 513.68 467.67 517.28 466.7 557.33 477.51 553.72 478.48"
          />
        </g>

        <g id="fingerprint">
          <path
            className="ai-cls-5"
            d="M320.42,526.43c6.63-1,14.76-2.59,21.66-4.38,10.51-2.72,19.43-5,29.42-9.14,4.95-2,10.45-3.84,15.4-5.87.51-.21,1-.45,1.49-.7,1.83-1,2.64-2.16,1.86-2.85a4.09,4.09,0,0,0-1.58-.77c-1.12-.31-2.42-.51-3.57-.8a11.61,11.61,0,0,1-6.15-3.73,4.68,4.68,0,0,1-1.18-4.84,11.27,11.27,0,0,1,2-3.34"
          />
          <path
            className="ai-cls-5"
            d="M425.91,540.79a142.66,142.66,0,0,0,14-5,83.34,83.34,0,0,0,10.39-6.25,73.61,73.61,0,0,0,10.34-8.82,53,53,0,0,0,8.56-11.2c.51-.93,1-1.85,1.37-2.76a12.13,12.13,0,0,0-1-3.94"
          />
          <path
            className="ai-cls-5"
            d="M361.29,548.36a172.14,172.14,0,0,0,38.09-1.46c1.9-.35,3.82-.77,5.78-1.29a172.16,172.16,0,0,0,21.18-7.12c9.83-4,18.23-8.66,24.49-14.15.06,0,3.62-3.24,5.21-4.87a42.87,42.87,0,0,0,6.95-8.78c2.81-5.08,3.09-9.52.31-13.14-2.16-2.81-5.44-5.08-9.43-7a71.75,71.75,0,0,0-8.84-2.46"
          />
          <path
            className="ai-cls-5"
            d="M419.06,485.84l-1.09.64a23.58,23.58,0,0,0-6.45,5c-2.17,2.73-2.71,5,0,6.48a2.25,2.25,0,0,1,.76.7c.36.55-.24,1.14-1.7,1.59a27.33,27.33,0,0,1-3,.73,35,35,0,0,1-7.64.53c-5.27-.13-9.23-1.17-12.17-2.89a10.1,10.1,0,0,1-4.84-5.91,3.63,3.63,0,0,1,.38-3.44"
          />
          <path
            className="ai-cls-5"
            d="M322.1,520.74c.42,0,.84-.12,1.26-.2,6.62-1.31,13.23-2.59,19.87-4,5.28-1.11,10.61-2.66,15.91-4.21a36.48,36.48,0,0,0,8.49-3.61c2.18-1.3,3.13-2.52,2.81-3.66a4.59,4.59,0,0,0-1.75-2.08,19.22,19.22,0,0,1-2.1-1.74c-1.64-1.76-1.49-4.06-.71-6.52a4.18,4.18,0,0,1,.7-1.4"
          />
          <path
            className="ai-cls-5"
            d="M324.92,535.73c.76-.42,1.57-.83,2.43-1.22,1.43-.64,2.9-1.26,4.37-1.86,3.72-1.53,7.43-3,11.18-4.55,1.87-.75,3.8-1.41,5.69-2.14a14,14,0,0,0,4.37-2.5c.83-.78.22-1.22-1.24-.9-.46.1-1,.25-1.42.39-3.85,1.16-7.69,2.36-11.54,3.47a154.18,154.18,0,0,1-15.67,3.77l-1.78.31"
          />
          <path
            className="ai-cls-5"
            d="M328.54,512.49l4.14-.75c2.81-.5,5.59-.93,8.41-1.51a43.79,43.79,0,0,0,8.92-2.77,4.35,4.35,0,0,0,2.39-1.89,1.92,1.92,0,0,0,.16-1.13,15.26,15.26,0,0,0-.75-2,5.05,5.05,0,0,1-.25-3.77"
          />
          <path
            className="ai-cls-5"
            d="M324.3,517.36l3.75-.59c5.49-.88,11-1.83,16.65-3.22a58.37,58.37,0,0,0,9.4-3c4.38-1.88,6.61-3.94,6.47-6a3,3,0,0,0-1.42-2.24,4.57,4.57,0,0,1-1.93-4.46,3,3,0,0,1,.59-1.61"
          />
          <path
            className="ai-cls-5"
            d="M331.89,509.89c1-.26,1.91-.51,2.87-.74a42.55,42.55,0,0,0,6.82-2.25c.76-.34,1.41-.77,2.1-1.15a8.23,8.23,0,0,0,1-1.49,28.15,28.15,0,0,0,.7-2.85"
          />
          <path
            className="ai-cls-5"
            d="M394.55,487.41a4,4,0,0,0,.34,2.83c.4.71,1.09,1.28,3.06,1.47a4.09,4.09,0,0,0,3-1.23,19.68,19.68,0,0,0,1.58-1.79,11.92,11.92,0,0,1,1.93-2.24"
          />
          <path
            className="ai-cls-5"
            d="M390,488.15a7.19,7.19,0,0,0,0,2c.5,2.62,2.23,4.76,5.07,6.45a10.41,10.41,0,0,0,3.81,1.24l.43,0c2.12.09,4.5-1,5-2.25a1.9,1.9,0,0,0,.1-1,4.79,4.79,0,0,1,.56-3.21,11.94,11.94,0,0,1,4.78-4.79c.38-.23.79-.44,1.2-.66"
          />
          <path
            className="ai-cls-5"
            d="M336.06,542.56a28.37,28.37,0,0,1,5.67-2.7c2.29-.81,4.61-1.58,6.94-2.3a79.59,79.59,0,0,1,14.52-3.21c4.14-.47,8.13-.74,12-.9,4.75-.18,9.46-.32,14.24-.54a98.52,98.52,0,0,0,14.86-1.71,55.56,55.56,0,0,0,16.29-5.71,71.6,71.6,0,0,0,9.17-5.82c3.83-3,6.34-5.86,7.44-8.73,1.57-4.08.25-7.11-4.59-8.86-2.68-1-5.44-1.89-8.2-2.82a26.35,26.35,0,0,1-5.38-2.19c-2.09-1.33-2.35-3.15-.06-5.65a25.29,25.29,0,0,1,7.26-5.17"
          />
          <path
            className="ai-cls-5"
            d="M327.39,538c.62-.41,1.27-.82,1.95-1.22A78.52,78.52,0,0,1,347,529.36a29.76,29.76,0,0,0,4.84-1.81"
          />
          <path
            className="ai-cls-5"
            d="M383.49,519.08c1-.32,1.88-.67,2.76-.88,3.23-.8,6.47-1.59,9.69-2.29a22.38,22.38,0,0,1,3.64-.4c1.15-.07,1.89.19,2.16.71s-.11.78-1.23,1.08a38.3,38.3,0,0,1-3.85.86,92.38,92.38,0,0,1-12.56,1l-.39,0S383.64,519.14,383.49,519.08Z"
          />
          <path
            className="ai-cls-5"
            d="M362.25,525.52c4.53-2,9-4.17,13.62-6A130,130,0,0,1,398,512.77a26.66,26.66,0,0,1,8.9-.58c2.67.41,3.9,1.5,4,3.09.07,1-1.14,2-2.64,3a21.29,21.29,0,0,1-8.58,3.2,59.34,59.34,0,0,1-9.19.79c-3.21.07-6.32,0-9.64.26a113.79,113.79,0,0,0-18.1,2.94l-.51.12Z"
          />
          <path
            className="ai-cls-5"
            d="M332.15,539.49a117.86,117.86,0,0,1,17.38-6.92,107.93,107.93,0,0,1,30.25-5.2c3.51-.06,7-.11,10.58-.26a62.38,62.38,0,0,0,12.94-1.9,35.05,35.05,0,0,0,12-5.47"
          />
          <path
            className="ai-cls-5"
            d="M421.74,514.32c.11-.12.21-.25.31-.37,1.32-1.68,1.15-3,.13-4-1.89-2-5.35-3-8.65-3.36a37.38,37.38,0,0,0-8.54,0,67.93,67.93,0,0,0-15.07,3.78c-6.44,2.3-12.57,5-18.59,7.73A135.94,135.94,0,0,1,358,523.74"
          />
          <path
            className="ai-cls-5"
            d="M344.87,545.65a17,17,0,0,1,2.05-.95,31.85,31.85,0,0,1,6.47-1.6,136.42,136.42,0,0,1,14.16-1.32c6.32-.32,12.65-.66,19.27-1.38a162.55,162.55,0,0,0,20-3.31,95.82,95.82,0,0,0,28.25-11.36A58.63,58.63,0,0,0,447,516.16c4.43-4.65,5.89-8.76,4.31-12.3-1.68-3.75-5.43-6.53-11-8.47a24.8,24.8,0,0,1-6.25-2.89,2.63,2.63,0,0,1-1.29-1.74c-.09-.9.86-1.78,2.77-2.58L436,488"
          />
          <path
            className="ai-cls-5"
            d="M352.27,547.17a55.45,55.45,0,0,1,9.59-.8,91.38,91.38,0,0,0,9.77-.35c7.78-.67,15.78-1.72,23.82-2.84a127.44,127.44,0,0,0,15.64-3.1,113.06,113.06,0,0,0,24.86-9.9c7.18-3.89,12.94-8,16.82-12.5,4.93-5.65,6.54-10.69,4.94-15.11a16.09,16.09,0,0,0-5.18-6.61,19.58,19.58,0,0,0-6.06-3.45c-1.39-.5-2.73-1-4-1.6a2.8,2.8,0,0,1-1.63-1.57c-.09-.32-.08-.67.55-1.06A4.12,4.12,0,0,1,442,488"
          />
          <path
            className="ai-cls-5"
            d="M340.07,544.19a10.09,10.09,0,0,1,1.56-1.08,27.4,27.4,0,0,1,4.13-1.88,59.28,59.28,0,0,1,12.78-3.1c6.35-.84,12.36-1.19,18.28-1.42,4.32-.17,8.61-.29,13.05-.6a103.87,103.87,0,0,0,18.7-3A76,76,0,0,0,429,524.86a51.68,51.68,0,0,0,11.86-9.52,17,17,0,0,0,4-6.29,5.89,5.89,0,0,0-1.82-6c-2.42-2.44-6.07-4.24-10.39-5.69a27.11,27.11,0,0,1-6-2.51c-1.24-.82-2.1-1.8-2-3.13s1.29-2.44,3.76-3.57c.6-.28,1.24-.53,1.86-.79l.32-.13"
          />
        </g>

        <g id="screen-1">
          <polygon
            className="ai-cls-6"
            points="404.68 289.98 0 396.47 1.58 106.49 406.26 0 404.68 289.98"
          />
          <line className="ai-cls-7" x1="21.65" y1="265.93" x2="53.5" y2="258.35" />
          <line className="ai-cls-7" x1="61.46" y1="256.45" x2="76.5" y2="252.87" />
          <line className="ai-cls-7" x1="84.46" y1="250.97" x2="99.5" y2="247.39" />
          <line className="ai-cls-7" x1="21.72" y1="278.71" x2="70.38" y2="267.12" />
          <line className="ai-cls-7" x1="77.45" y1="265.44" x2="107.53" y2="258.28" />
          <line className="ai-cls-7" x1="21.78" y1="291.49" x2="35.06" y2="288.33" />
          <line className="ai-cls-7" x1="122.69" y1="267.46" x2="135.96" y2="264.3" />
          <line className="ai-cls-7" x1="122.55" y1="241.9" x2="135.83" y2="238.74" />
          <line className="ai-cls-7" x1="126.46" y1="253.77" x2="135.89" y2="251.52" />
          <line className="ai-cls-7" x1="122.9" y1="306.79" x2="136.17" y2="303.63" />
          <line className="ai-cls-7" x1="122.76" y1="281.23" x2="136.04" y2="278.07" />
          <line className="ai-cls-7" x1="126.67" y1="293.09" x2="136.1" y2="290.85" />
          <line className="ai-cls-7" x1="122.97" y1="320.55" x2="136.25" y2="317.39" />
          <line className="ai-cls-7" x1="126.88" y1="332.42" x2="136.31" y2="330.17" />
          <line className="ai-cls-7" x1="42.72" y1="286.51" x2="64.25" y2="281.38" />
          <line className="ai-cls-7" x1="71.92" y1="279.55" x2="90.5" y2="275.13" />
          <line className="ai-cls-7" x1="21.85" y1="304.27" x2="68.45" y2="293.18" />
          <line className="ai-cls-7" x1="74.64" y1="291.7" x2="96.17" y2="286.58" />
          <line className="ai-cls-7" x1="21.93" y1="318.04" x2="53.78" y2="310.45" />
          <line className="ai-cls-7" x1="61.74" y1="308.56" x2="76.78" y2="304.97" />
          <line className="ai-cls-7" x1="84.74" y1="303.08" x2="99.78" y2="299.5" />
          <line className="ai-cls-7" x1="22" y1="330.82" x2="70.65" y2="319.23" />
          <line className="ai-cls-7" x1="77.73" y1="317.54" x2="107.81" y2="310.38" />
          <line className="ai-cls-7" x1="22.06" y1="343.6" x2="35.33" y2="340.44" />
          <line className="ai-cls-7" x1="43" y1="338.61" x2="64.53" y2="333.49" />
          <line className="ai-cls-7" x1="72.2" y1="331.66" x2="90.78" y2="327.23" />
          <line className="ai-cls-7" x1="22.13" y1="356.38" x2="68.73" y2="345.28" />
          <line className="ai-cls-7" x1="74.92" y1="343.81" x2="96.45" y2="338.68" />

          <g id="hello" className="ai-cls-8">
            <path
              className="ai-cls-9"
              d="M37.11,218.38,24.3,221.43l.07,12.28-2.37.56L21.86,208l2.37-.56.06,11.79L37.1,216.2,37,204.41l2.37-.56.14,26.25-2.37.56Z"
            />
            <path
              className="ai-cls-9"
              d="M52.92,227.21c-5.24,1.25-9.08-1.9-9.11-8.4s3.69-11.44,9-12.7,8.74,2.2,8.77,7.42a19.8,19.8,0,0,1-.06,2.08l-15.31,3.65c.23,4.75,3.25,6.55,6.7,5.73a7.83,7.83,0,0,0,5.92-6l2.5-.6A11.18,11.18,0,0,1,52.92,227.21ZM46.2,217.12l13-3.09c.08-4.8-3-6.46-6.46-5.65S46.45,212.28,46.2,217.12Z"
            />
            <path className="ai-cls-9" d="M65.82,196l2.36-.56.15,27.79L66,223.8Z" />
            <path className="ai-cls-9" d="M73.55,194.17l2.37-.56.15,27.79L73.7,222Z" />
            <path
              className="ai-cls-9"
              d="M89.52,218.49c-5.2,1.24-9.18-1.87-9.21-8.37s4-11.52,9.16-12.75,9.25,1.82,9.29,8.36S94.72,217.25,89.52,218.49Zm0-2.29c3.48-.83,6.84-4.26,6.81-9.89s-3.35-7.48-6.87-6.64-6.78,4.25-6.75,9.88S86,217,89.51,216.2Z"
            />
          </g>
        </g>

        <g id="screen-2">
          <polygon
            className="ai-cls-10"
            points="583.9 292.25 179.22 398.74 180.8 108.76 585.48 2.27 583.9 292.25"
          />
          <path
            className="ai-cls-9"
            d="M216.14,313.54l-12.81,3,.07,12.28-2.37.56-.14-26.25,2.37-.56.06,11.79,12.81-3.05-.06-11.79,2.36-.56.14,26.25-2.36.56Z"
          />
          <path
            className="ai-cls-9"
            d="M232,322.37c-5.24,1.25-9.08-1.9-9.12-8.4s3.7-11.44,9-12.7,8.74,2.2,8.77,7.42a19.92,19.92,0,0,1-.05,2.08l-15.31,3.65c.23,4.75,3.25,6.55,6.69,5.73a7.83,7.83,0,0,0,5.93-6l2.5-.6A11.2,11.2,0,0,1,232,322.37Zm-6.72-10.09,13-3.09c.08-4.8-3-6.46-6.46-5.65S225.48,307.44,225.24,312.28Z"
          />
          <path
            className="ai-cls-9"
            d="M247.39,297.86l.14,25.35c0,3.3-1.46,5.2-4.44,5.91l-1.65.39,0-2.25,1.29-.31c1.75-.42,2.46-1.3,2.45-3.29L245,298.43Zm-2.9-5.13a2.37,2.37,0,0,1,1.71-2.33c1-.22,1.69.39,1.7,1.51a2.39,2.39,0,0,1-1.68,2.36A1.4,1.4,0,0,1,244.49,292.73Z"
          />
          <path
            className="ai-cls-9"
            d="M254.9,316.79c-1,.24-1.73-.45-1.73-1.54a2.38,2.38,0,0,1,1.71-2.33c1-.22,1.69.43,1.7,1.52A2.4,2.4,0,0,1,254.9,316.79Zm-1.52-26.1,2.56-.62-.2,19.38-2,.47Z"
          />
        </g>

        <g id="neurons-1">
          <polyline
            className="ai-cls-11"
            points="382.73 461.58 382.91 427.99 371.55 416.07 371.79 373.78 372.03 328.18 397.58 337.6 397.52 348.38"
          />
          <polyline
            className="ai-cls-11"
            points="417.07 301 416.9 332.51 431.02 343.29 430.71 399.68 398.59 441.67 398.79 405.18"
          />
          <line className="ai-cls-11" x1="349.9" y1="414.73" x2="349.74" y2="443.76" />
          <ellipse
            className="ai-cls-11"
            cx="397.81"
            cy="359.04"
            rx="10.21"
            ry="7.82"
            transform="translate(-126.52 469.66) rotate(-53.98)"
          />
          <ellipse
            className="ai-cls-11"
            cx="349.91"
            cy="403.93"
            rx="10.21"
            ry="7.82"
            transform="translate(-182.56 449.42) rotate(-53.98)"
          />
          <ellipse
            className="ai-cls-11"
            cx="349.65"
            cy="452.86"
            rx="10.21"
            ry="7.82"
            transform="translate(-222.25 469.36) rotate(-53.98)"
          />
          <ellipse
            className="ai-cls-11"
            cx="398.4"
            cy="394.9"
            rx="10.21"
            ry="7.82"
            transform="translate(-155.28 484.92) rotate(-53.98)"
          />
          <ellipse
            className="ai-cls-11"
            cx="382.62"
            cy="472.75"
            rx="10.21"
            ry="7.82"
            transform="translate(-224.75 504.22) rotate(-53.98)"
          />
          <path
            className="ai-cls-11"
            d="M370.73,256.1s-6,22.28,13.59,34.92,61,12.11,68.88,6.72,6.42-21.56,7.62-25.19,6.34-7.88,6.34-7.88l-5.86-8.81,9.89-7.16-5.41-18.44,20.9-5.51-17.09-43.52s4.76-6.64,6.37-12.86c2.12-28.3-5.55-66-36.59-78.56s-70.11,2.3-70.11,2.3l-.15,27.78,12.53,12.44,20.9-5.09,7.93-10.37"
          />
          <polyline
            className="ai-cls-11"
            points="475.66 151.06 452.79 157.08 452.71 171.59 441.14 198.23 378.04 214.84 349.09 252.68 348.92 282.95"
          />
          <polyline
            className="ai-cls-11"
            points="319.88 264.51 320.06 230.92 308.71 219.01 308.94 176.72 309.19 131.11 334.73 140.53 334.67 151.31"
          />
          <polyline className="ai-cls-11" points="288.7 133.19 288.52 165.53 308.94 176.72" />
          <polyline
            className="ai-cls-11"
            points="354.23 103.94 354.06 135.45 368.18 146.22 367.87 202.61 335.74 244.6 335.94 208.11"
          />
          <line className="ai-cls-11" x1="287.06" y1="217.67" x2="286.9" y2="246.69" />
          <polyline
            className="ai-cls-11"
            points="407.23 206.74 407.35 183.53 437.32 175.64 437.55 134.59"
          />
          <polyline
            className="ai-cls-11"
            points="393.81 210.69 393.96 184.98 382.19 175.66 382.34 148.71 401.66 143.62 408.73 148.39"
          />
          <ellipse
            className="ai-cls-11"
            cx="289.49"
            cy="123.84"
            rx="10.21"
            ry="7.82"
            transform="translate(19.09 285.16) rotate(-53.98)"
          />
          <ellipse
            className="ai-cls-11"
            cx="334.96"
            cy="161.97"
            rx="10.21"
            ry="7.82"
            transform="translate(6.98 337.65) rotate(-53.98)"
          />
          <ellipse
            className="ai-cls-11"
            cx="287.07"
            cy="206.87"
            rx="10.21"
            ry="7.82"
            transform="translate(-49.06 317.41) rotate(-53.98)"
          />
          <ellipse
            className="ai-cls-11"
            cx="286.8"
            cy="255.79"
            rx="10.21"
            ry="7.82"
            transform="translate(-88.74 337.34) rotate(-53.98)"
          />
          <ellipse
            className="ai-cls-11"
            cx="335.56"
            cy="197.83"
            rx="10.21"
            ry="7.82"
            transform="translate(-21.78 352.9) rotate(-53.98)"
          />
          <ellipse
            className="ai-cls-11"
            cx="319.77"
            cy="275.68"
            rx="10.21"
            ry="7.82"
            transform="translate(-91.25 372.21) rotate(-53.98)"
          />
          <ellipse
            className="ai-cls-11"
            cx="348.42"
            cy="294.23"
            rx="10.21"
            ry="7.82"
            transform="matrix(0.59, -0.81, 0.81, 0.59, -94.45, 403.02)"
          />
          <ellipse
            className="ai-cls-11"
            cx="415.73"
            cy="155.2"
            rx="10.21"
            ry="7.82"
            transform="translate(45.73 400.19) rotate(-53.98)"
          />
          <ellipse
            className="ai-cls-11"
            cx="418.35"
            cy="107.73"
            rx="10.21"
            ry="7.82"
            transform="translate(85.21 382.75) rotate(-53.98)"
          />
          <ellipse
            className="ai-cls-11"
            cx="437.95"
            cy="125.35"
            rx="10.21"
            ry="7.82"
            transform="matrix(0.59, -0.81, 0.81, 0.59, 79.03, 405.86)"
          />
        </g>

        <g id="neurons-2">
          <polyline
            className="ai-cls-11"
            points="672.28 376.38 672.28 336.37 657.96 314.45 657.96 264.06 657.96 209.71 690.07 238.23 690.07 251.08"
          />
          <polyline className="ai-cls-11" points="632.27 198.36 632.27 236.9 657.96 264.06" />
          <polyline
            className="ai-cls-11"
            points="714.28 207.66 714.28 245.21 732.06 267.63 732.06 334.81 692.05 363.3 692.05 319.83"
          />
          <line className="ai-cls-11" x1="630.78" y1="298.22" x2="630.78" y2="332.81" />
          <ellipse
            className="ai-cls-11"
            cx="633.2"
            cy="187.72"
            rx="9.46"
            ry="12.61"
            transform="translate(31.69 460.42) rotate(-40.9)"
          />
          <ellipse
            className="ai-cls-11"
            cx="690.5"
            cy="264.01"
            rx="9.46"
            ry="12.61"
            transform="translate(-4.27 516.57) rotate(-40.9)"
          />
          <ellipse
            className="ai-cls-11"
            cx="630.73"
            cy="285.32"
            rx="9.46"
            ry="12.61"
            transform="translate(-32.82 482.64) rotate(-40.9)"
          />
          <ellipse
            className="ai-cls-11"
            cx="630.73"
            cy="343.62"
            rx="9.46"
            ry="12.61"
            transform="translate(-70.99 496.87) rotate(-40.9)"
          />
          <ellipse
            className="ai-cls-11"
            cx="691.49"
            cy="307.28"
            rx="9.46"
            ry="12.61"
            transform="translate(-32.36 527.78) rotate(-40.9)"
          />
          <ellipse
            className="ai-cls-11"
            cx="672.22"
            cy="389.67"
            rx="9.46"
            ry="12.61"
            transform="translate(-91.01 535.28) rotate(-40.9)"
          />
        </g>

        <g id="screen-3">
          <polygon
            className="ai-cls-12"
            points="810.05 492.59 600.15 436.35 600.15 219.06 810.05 275.3 810.05 492.59"
          />
          <line className="ai-cls-13" x1="686.47" y1="356.27" x2="715.31" y2="364" />
          <line className="ai-cls-13" x1="722.52" y1="365.93" x2="736.13" y2="369.58" />
          <line className="ai-cls-13" x1="743.34" y1="371.51" x2="756.96" y2="375.16" />
          <line className="ai-cls-13" x1="686.47" y1="366.69" x2="730.53" y2="378.49" />
          <line className="ai-cls-13" x1="736.94" y1="380.21" x2="764.17" y2="387.51" />
          <line className="ai-cls-13" x1="686.47" y1="377.1" x2="698.49" y2="380.32" />
          <line className="ai-cls-13" x1="777.83" y1="401.58" x2="789.85" y2="404.8" />
          <line className="ai-cls-13" x1="777.83" y1="380.75" x2="789.85" y2="383.97" />
          <line className="ai-cls-13" x1="781.3" y1="392.1" x2="789.85" y2="394.39" />
          <line className="ai-cls-13" x1="777.83" y1="433.62" x2="789.85" y2="436.84" />
          <line className="ai-cls-13" x1="777.83" y1="412.79" x2="789.85" y2="416.01" />
          <line className="ai-cls-13" x1="781.3" y1="424.14" x2="789.85" y2="426.43" />
          <line className="ai-cls-13" x1="777.83" y1="444.83" x2="789.85" y2="448.05" />
          <line className="ai-cls-13" x1="781.3" y1="456.18" x2="789.85" y2="458.47" />
          <line className="ai-cls-13" x1="705.43" y1="382.18" x2="724.92" y2="387.4" />
          <line className="ai-cls-13" x1="731.86" y1="389.26" x2="748.68" y2="393.77" />
          <line className="ai-cls-13" x1="686.47" y1="387.51" x2="728.66" y2="398.82" />
          <line className="ai-cls-13" x1="734.27" y1="400.32" x2="753.76" y2="405.54" />
          <line className="ai-cls-13" x1="686.47" y1="398.73" x2="715.31" y2="406.45" />
          <line className="ai-cls-13" x1="722.52" y1="408.39" x2="736.13" y2="412.03" />
          <line className="ai-cls-13" x1="743.34" y1="413.97" x2="756.96" y2="417.61" />
          <line className="ai-cls-13" x1="686.47" y1="409.14" x2="730.53" y2="420.95" />
          <line className="ai-cls-13" x1="736.94" y1="422.66" x2="764.17" y2="429.96" />
          <line className="ai-cls-13" x1="686.47" y1="419.55" x2="698.49" y2="422.77" />
          <line className="ai-cls-13" x1="705.43" y1="424.63" x2="724.92" y2="429.86" />
          <line className="ai-cls-13" x1="731.86" y1="431.72" x2="748.68" y2="436.22" />
          <line className="ai-cls-13" x1="686.47" y1="429.97" x2="728.66" y2="441.27" />
          <line className="ai-cls-13" x1="734.27" y1="442.77" x2="753.76" y2="447.99" />
        </g>

        <g id="hello-2">
          <path
            className="ai-cls-4"
            d="M626.59,87.19l-104-28.41c-6.47-1.77-11.71,2.15-11.71,8.74V122.6c0,6.59,5.24,13.37,11.71,15.13l12.33,3.37v24.45l16.18-20,75.53,20.62c6.46,1.77,11.71-2.14,11.71-8.73V102.32C638.3,95.73,633.05,89,626.59,87.19Z"
          />
          <path
            className="ai-cls-14"
            d="M555.07,105l-10.1-3v9.84l-1.87-.57v-21l1.87.56v9.45l10.1,3.06V93.76l1.87.56v21l-1.87-.57Z"
          />
          <path
            className="ai-cls-14"
            d="M567.49,118.79c-4.13-1.25-7.14-5.41-7.14-10.61s3-7.57,7.14-6.3a10.1,10.1,0,0,1,6.88,9.69c0,.67,0,1.09,0,1.64l-12.08-3.65a7.86,7.86,0,0,0,5.25,7.46c2.48.75,4.16-.22,4.69-2.26l2,.6C573.49,118.26,571.14,119.9,567.49,118.79Zm-5.25-11,10.24,3.1a7.32,7.32,0,0,0-5.07-7.28C564.77,102.86,562.45,104.09,562.24,107.84Z"
          />
          <path className="ai-cls-14" d="M577.78,99.39l1.87.57v22.27l-1.87-.56Z" />
          <path className="ai-cls-14" d="M583.89,101.24l1.86.57v22.27l-1.86-.57Z" />
          <path
            className="ai-cls-14"
            d="M596.36,127.53c-4.1-1.24-7.22-5.44-7.22-10.65s3.2-7.48,7.27-6.25,7.28,5.42,7.28,10.66S600.47,128.77,596.36,127.53Zm0-1.84c2.75.83,5.41-.47,5.41-5s-2.61-7.41-5.38-8.25-5.36.48-5.36,5S593.59,124.85,596.36,125.69Z"
          />
        </g>
      </svg>
    </div>
  );
}

const animate = () => {
  const svgs = document.querySelectorAll('.ai-illustration');
  if (!svgs) return;

  const timeline = anime.timeline({
    autoplay: false,
    loop: false,
  });

  const animations = [
    {
      targets: '#shadow',
      opacity: [0, 0.49],
      easing: 'easeInOutSine',
      duration: 750,
      delay: 0,
    },
    {
      targets: '#phone',
      translateY: [20, 0],
      opacity: [0, 1],
      easing: 'easeInOutSine',
      duration: 750,
      delay: 0,
    },
    {
      targets: '#screen-3',
      translateX: [20, 0],
      translateY: [-10, 0],
      opacity: [0, 1],
      easing: 'easeOutSine',
      duration: 750,
      delay: 300,
    },
    {
      targets: '#screen-1',
      translateX: [-20, 0],
      translateY: [-10, 0],
      opacity: [0, 1],
      easing: 'easeOutSine',
      duration: 750,
      delay: 500,
    },
    {
      targets: '#screen-2',
      translateX: [-20, 0],
      translateY: [-10, 0],
      opacity: [0, 1],
      easing: 'easeOutSine',
      duration: 750,
      delay: 750,
    },
    {
      targets: '#fingerprint > path',
      strokeDashoffset: [anime.setDashoffset, 0],
      easing: 'easeOutSine',
      duration: 600,
      delay: (el, i, l) => 950 + i * 20,
    },
    {
      targets: '#neurons-2 > *',
      strokeDashoffset: [anime.setDashoffset, 0],
      easing: 'easeOutSine',
      duration: 500,
      delay: (el, i, l) => 1400 + i * 50,
    },
    {
      targets: '#neurons-1 > *',
      strokeDashoffset: [anime.setDashoffset, 0],
      easing: 'easeOutSine',
      duration: 750,
      delay: (el, i, l) => 1700 + i * 50,
    },
    {
      targets: '#hello-2',
      translateY: [10, 0],
      opacity: [0, 1],
      easing: 'easeOutSine',
      duration: 500,
      delay: 3550,
    },
    {
      targets: '#letters > *',
      opacity: [0, 1],
      easing: 'easeOutSine',
      duration: 750,
      delay: (el, i, l) => 3550 + i * 100,
    },
  ];

  animations.forEach(animation => timeline.add(animation, 0));

  svgs.forEach(svg => (svg.style.opacity = '1'));
  timeline.play();
};
