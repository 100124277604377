import { useEffect } from 'react';
import anime from 'animejs/lib/anime.es.js';
import 'styles/illustrations/egovernance.css';

export default function Egovernance() {
  useEffect(() => {
    animate();
  }, []);

  return (
    <div className="egov-illustration">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        viewBox="0 0 1288 695"
      >
        <title>GovTech</title>
        <defs>
          <linearGradient
            id="egov-a"
            x1="2604.68"
            y1="701.45"
            x2="2805.66"
            y2="701.45"
            gradientTransform="translate(-1440.55 2000.99) rotate(-45)"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0" stopColor="#5ec2bb" />
            <stop offset="1" stopColor="#203075" />
          </linearGradient>
          <linearGradient
            id="egov-b"
            x1="479.33"
            y1="232.68"
            x2="479.33"
            y2="123.12"
            gradientTransform="matrix(1.05, -0.05, 0.19, 0.95, 81.71, -82.21)"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0" stopColor="#ee105a" />
            <stop offset="1" stopColor="#0a1236" />
          </linearGradient>
          <linearGradient
            id="egov-c"
            x1="444.24"
            y1="305.98"
            x2="444.24"
            y2="251.75"
            xlinkHref="#egov-b"
          />
          <linearGradient
            id="egov-d"
            x1="529.17"
            y1="306.02"
            x2="529.17"
            y2="123.12"
            xlinkHref="#egov-b"
          />
          <linearGradient
            id="egov-e"
            x1="753.95"
            y1="319.03"
            x2="1097.66"
            y2="319.03"
            gradientTransform="matrix(1, 0, 0, 1, 0, 0)"
            xlinkHref="#egov-a"
          />
          <linearGradient
            id="egov-f"
            x1="929.55"
            y1="176.25"
            x2="929.8"
            y2="176.25"
            gradientTransform="matrix(1, 0, 0, 1, 0, 0)"
            xlinkHref="#egov-a"
          />
          <linearGradient
            id="egov-g"
            x1="929.55"
            y1="210.52"
            x2="1055.7"
            y2="210.52"
            gradientTransform="matrix(1, 0, 0, 1, 0, 0)"
            xlinkHref="#egov-a"
          />
          <linearGradient
            id="egov-h"
            x1="929.55"
            y1="175.5"
            x2="1055.7"
            y2="175.5"
            gradientTransform="matrix(1, 0, 0, 1, 0, 0)"
            xlinkHref="#egov-a"
          />
        </defs>

        <polygon
          id="base"
          className="a"
          points="1287.85 520.51 927.5 617.8 378.57 469.59 738.91 372.29 1287.85 520.51"
        />

        <g id="hq">
          <polygon
            className="n"
            points="925.81 248.28 753.95 202.24 753.95 343.18 925.8 389.22 925.8 389.78 1097.66 435.83 1097.66 294.89 925.81 248.28"
          />
          <polygon
            className="d"
            points="1097.84 294.89 1240.74 256.6 896.09 163.33 754.14 202.17 1097.84 294.89"
          />
          <polygon
            className="b"
            points="1240.46 256.6 1097.56 294.89 1097.56 435.91 1240.46 397.62 1240.46 256.6"
          />
          <path className="o" d="M929.55,175.5V177a4.63,4.63,0,0,1,.25-1.5Z" />
          <path
            className="p"
            d="M1037.22,189c-24.63,6.65-64.56,6.65-89.2,0-12.31-3.33-18.47-7.68-18.47-12v50c0,4.36,6.16,8.72,18.47,12,24.64,6.65,64.57,6.65,89.2,0,12.32-3.32,18.48-7.68,18.48-12V177C1055.7,181.36,1049.54,185.71,1037.22,189Z"
          />
          <path
            className="b"
            d="M930,175.5a4.94,4.94,0,0,0-.15.64c0,.82.11,1.63.18,2.44,1.31,3.81,7.38,7.54,18.2,10.46,24.63,6.65,64.57,6.65,89.2,0,10.82-2.92,16.89-6.65,18.2-10.46.17-1.91.27-3.83.27-5.78a63.07,63.07,0,0,0-126.14,0c0,.9,0,1.8.07,2.7Z"
          />
          <ellipse className="q" cx="992.62" cy="175.5" rx="63.07" ry="17.03" />
          <path className="d" d="M973.6,91.32v.46a1.54,1.54,0,0,1,.08-.46Z" />
          <path
            className="d"
            d="M1006.49,95.46c-7.52,2-19.72,2-27.24,0-3.77-1-5.65-2.35-5.65-3.68V118.1c0,1.34,1.88,2.67,5.65,3.68,7.52,2,19.72,2,27.24,0,3.77-1,5.65-2.34,5.65-3.68V91.78C1012.14,93.11,1010.26,94.44,1006.49,95.46Z"
          />
          <ellipse className="b" cx="992.87" cy="91.78" rx="19.27" ry="5.2" />
          <rect className="d" x="993.34" y="41.6" width="1" height="52.65" />
          <circle className="d" cx="993.84" cy="79.99" r="2.74" />
          <rect className="d" x="990.91" y="45.76" width="5.86" height="1" />
          <path
            className="d"
            d="M997.55,91.25l-.95-.32a76.15,76.15,0,0,0,2.59-9.57.79.79,0,0,0-.23-.76c-.84-.71-3.59-.27-5.13.18l-.28-1c.46-.13,4.54-1.27,6.06,0a1.74,1.74,0,0,1,.57,1.64A76.8,76.8,0,0,1,997.55,91.25Z"
          />
          <path
            className="d"
            d="M999.6,92.49l-.78-.62a79.26,79.26,0,0,0,5.72-8.11.74.74,0,0,0,0-.78c-.54-1-3.28-1.49-4.87-1.6l.06-1c.48,0,4.7.37,5.68,2.1a1.76,1.76,0,0,1,0,1.74A76.14,76.14,0,0,1,999.6,92.49Z"
          />
          <path
            className="d"
            d="M990.15,90.94a77.29,77.29,0,0,1-2.63-9.77,1.77,1.77,0,0,1,.58-1.64c1.52-1.3,5.59-.16,6,0l-.28,1c-1.54-.45-4.29-.9-5.13-.18a.76.76,0,0,0-.22.75,76.92,76.92,0,0,0,2.58,9.58Z"
          />
          <path
            className="d"
            d="M988.1,92.18a75,75,0,0,1-5.82-8.27,1.74,1.74,0,0,1,0-1.74c1-1.74,5.2-2.07,5.68-2.1l.07,1c-1.6.11-4.34.63-4.88,1.59a.79.79,0,0,0,0,.79,77.65,77.65,0,0,0,5.72,8.1Z"
          />
          <path
            className="d"
            d="M997.53,90.94l-.95-.32A74.7,74.7,0,0,0,999.17,81a.78.78,0,0,0-.23-.75c-.84-.71-3.59-.27-5.13.18l-.28-1c.46-.14,4.54-1.28,6.06,0a1.74,1.74,0,0,1,.57,1.64A75.58,75.58,0,0,1,997.53,90.94Z"
          />
          <path
            className="d"
            d="M999.58,92.18l-.78-.63a77.65,77.65,0,0,0,5.72-8.1.76.76,0,0,0,0-.79c-.54-1-3.28-1.48-4.87-1.59l.06-1c.48,0,4.7.36,5.68,2.1a1.76,1.76,0,0,1,0,1.74A76.14,76.14,0,0,1,999.58,92.18Z"
          />
          <path
            className="d"
            d="M996.5,70.58l-1-.31a52.72,52.72,0,0,0,1.74-6.47c0-.25-.06-.33-.1-.36-.49-.42-2.27-.16-3.32.15l-.28-1c.52-.15,3.18-.87,4.25,0a1.3,1.3,0,0,1,.44,1.25A51.5,51.5,0,0,1,996.5,70.58Z"
          />
          <path
            className="d"
            d="M997.86,71.47l-.78-.62a50.75,50.75,0,0,0,3.85-5.47c.12-.23.07-.33,0-.38-.32-.56-2.08-.92-3.18-1l.07-1c.54,0,3.29.28,4,1.51a1.32,1.32,0,0,1,0,1.33A51.29,51.29,0,0,1,997.86,71.47Z"
          />
          <path
            className="d"
            d="M991.19,70.58a49.5,49.5,0,0,1-1.79-6.65,1.32,1.32,0,0,1,.44-1.25c1.08-.92,3.73-.2,4.26,0l-.28,1c-1.06-.31-2.84-.57-3.33-.15,0,0-.13.11-.1.36a52.72,52.72,0,0,0,1.74,6.47Z"
          />
          <path
            className="d"
            d="M989.82,71.47a51.29,51.29,0,0,1-4-5.63,1.34,1.34,0,0,1,0-1.33c.7-1.23,3.44-1.47,4-1.51l.07,1c-1.09.08-2.86.44-3.18,1,0,0-.08.15,0,.38a50.43,50.43,0,0,0,3.86,5.47Z"
          />
          <rect className="d" x="989.21" y="70.21" width="9.25" height="2" />
          <path className="d" d="M986.49,87.58v.17a.47.47,0,0,1,0-.17Z" />
          <path
            className="d"
            d="M998.54,89.09a22.41,22.41,0,0,1-10,0c-1.38-.37-2.07-.86-2.07-1.34v4.64c0,.49.69,1,2.07,1.35a22.71,22.71,0,0,0,10,0c1.38-.37,2.07-.86,2.07-1.35V87.75C1000.61,88.23,999.92,88.72,998.54,89.09Z"
          />

          <g id="hq-windows">
            <polygon
              className="m"
              points="779.66 265.86 768.15 262.78 768.15 239.26 779.66 242.34 779.66 265.86"
            />
            <polygon
              className="m"
              points="797.56 270.66 786.05 267.57 786.05 244.05 797.56 247.14 797.56 270.66"
            />
            <polygon
              className="m"
              points="821.21 277.34 809.7 274.26 809.7 250.73 821.21 253.82 821.21 277.34"
            />
            <polygon
              className="m"
              points="839.11 282.13 827.59 279.05 827.59 255.53 839.11 258.61 839.11 282.13"
            />
            <polygon
              className="m"
              points="779.66 298.95 768.15 295.87 768.15 272.35 779.66 275.43 779.66 298.95"
            />
            <polygon
              className="m"
              points="797.56 303.75 786.05 300.67 786.05 277.14 797.56 280.23 797.56 303.75"
            />
            <polygon
              className="m"
              points="821.21 310.43 809.7 307.35 809.7 283.82 821.21 286.91 821.21 310.43"
            />
            <polygon
              className="m"
              points="839.11 315.23 827.59 312.14 827.59 288.62 839.11 291.7 839.11 315.23"
            />
            <polygon
              className="m"
              points="867.44 289.6 855.92 286.52 855.92 262.99 867.44 266.08 867.44 289.6"
            />
            <polygon
              className="m"
              points="885.33 294.4 873.82 291.31 873.82 267.79 885.33 270.87 885.33 294.4"
            />
            <polygon
              className="m"
              points="867.44 322.69 855.92 319.61 855.92 296.08 867.44 299.17 867.44 322.69"
            />
            <polygon
              className="m"
              points="885.33 327.49 873.82 324.4 873.82 300.88 885.33 303.96 885.33 327.49"
            />
            <polygon
              className="m"
              points="960.33 316.01 948.82 312.92 948.82 289.4 960.33 292.48 960.33 316.01"
            />
            <polygon
              className="m"
              points="978.22 320.8 966.71 317.72 966.71 294.19 978.22 297.28 978.22 320.8"
            />
            <polygon
              className="m"
              points="1001.87 327.48 990.36 324.4 990.36 300.88 1001.87 303.96 1001.87 327.48"
            />
            <polygon
              className="m"
              points="1019.77 332.28 1008.26 329.19 1008.26 305.67 1019.77 308.75 1019.77 332.28"
            />
            <polygon
              className="m"
              points="960.33 349.1 948.82 346.01 948.82 322.49 960.33 325.57 960.33 349.1"
            />
            <polygon
              className="m"
              points="978.22 353.89 966.71 350.81 966.71 327.28 978.22 330.37 978.22 353.89"
            />
            <polygon
              className="m"
              points="1001.87 360.57 990.36 357.49 990.36 333.96 1001.87 337.05 1001.87 360.57"
            />
            <polygon
              className="m"
              points="1019.77 365.37 1008.26 362.28 1008.26 338.76 1019.77 341.85 1019.77 365.37"
            />
            <polygon
              className="m"
              points="1048.1 339.74 1036.59 336.66 1036.59 313.14 1048.1 316.22 1048.1 339.74"
            />
            <polygon
              className="m"
              points="1066 344.54 1054.49 341.45 1054.49 317.93 1066 321.02 1066 344.54"
            />
            <polygon
              className="m"
              points="1048.1 372.83 1036.59 369.75 1036.59 346.23 1048.1 349.31 1048.1 372.83"
            />
            <polygon
              className="m"
              points="1066 377.63 1054.49 374.55 1054.49 351.02 1066 354.11 1066 377.63"
            />
            <polygon
              className="m"
              points="1137.58 340.67 1126.07 343.76 1126.07 320.24 1137.58 317.15 1137.58 340.67"
            />
            <polygon
              className="m"
              points="1155.48 335.88 1143.97 338.96 1143.97 315.44 1155.48 312.36 1155.48 335.88"
            />
            <polygon
              className="m"
              points="1194.05 325.44 1182.54 328.53 1182.54 305.01 1194.05 301.92 1194.05 325.44"
            />
            <polygon
              className="m"
              points="1211.94 320.65 1200.43 323.74 1200.43 300.21 1211.94 297.13 1211.94 320.65"
            />
            <polygon
              className="m"
              points="1137.58 373.76 1126.07 376.85 1126.07 353.33 1137.58 350.24 1137.58 373.76"
            />
            <polygon
              className="m"
              points="1155.48 368.97 1143.97 372.05 1143.97 348.53 1155.48 345.45 1155.48 368.97"
            />
            <polygon
              className="m"
              points="1194.05 358.54 1182.54 361.62 1182.54 338.1 1194.05 335.01 1194.05 358.54"
            />
            <polygon
              className="m"
              points="1211.94 353.74 1200.43 356.82 1200.43 333.3 1211.94 330.22 1211.94 353.74"
            />
            <polygon
              className="m"
              points="945.44 223.83 933.92 220.74 933.92 197.22 945.44 200.31 945.44 223.83"
            />
            <polygon
              className="m"
              points="1049.53 219.72 1038.02 222.8 1038.02 199.28 1049.53 196.19 1049.53 219.72"
            />
            <path
              className="m"
              d="M911.28,334.62c-10.25-2.74-18.56,3.34-18.56,13.59v32l37.12,9.95v-32C929.84,347.91,921.53,337.37,911.28,334.62Z"
            />
            <rect className="m" x="984.63" y="205.96" width="15.07" height="26.46" />
          </g>
        </g>

        <g id="phone">
          <path
            id="phone-shadow"
            className="e"
            d="M1073.89,692.87l118.86-32.09c7.21-1.95,7.21-5.1,0-7L946,587.12c-7.21-2-18.9-2-26.11,0L801.06,619.21c-7.21,1.95-7.21,5.1,0,7l246.72,66.61C1055,694.82,1066.68,694.82,1073.89,692.87Z"
          />
          <path
            className="b"
            d="M1192.75,582.66l-118.86,32.1c-7.21,1.94-18.9,1.94-26.11,0L801.06,548.14c-7.21-1.94-7.21-5.1,0-7L919.92,509c7.21-1.94,18.9-1.94,26.11,0l246.72,66.62C1200,577.56,1200,580.72,1192.75,582.66Z"
          />
          <polygon
            className="c"
            points="1164.38 573.43 1039.67 607.1 819.45 547.64 944.16 513.97 1164.38 573.43"
          />
          <ellipse className="d" cx="1119.64" cy="593.24" rx="11.72" ry="3.16" />
          <ellipse className="d" cx="890.42" cy="524.64" rx="5.86" ry="1.58" />
          <polygon
            className="d"
            points="874.7 528.88 848.39 535.99 846.02 535.35 872.33 528.25 874.7 528.88"
          />
          <path
            className="d"
            d="M801.06,548.12l246.72,66.61c7.21,1.95,18.9,1.95,26.11,0l118.86-32.09c3.38-.91,5.15-2.09,5.36-3.28h.05v16.74h0c-.21,1.19-2,2.37-5.38,3.28l-118.86,32.1c-7.21,1.94-18.9,1.94-26.11,0L801.06,564.86c-3.46-.93-5.24-2.14-5.38-3.37h0V544.67C795.73,545.92,797.52,547.17,801.06,548.12Z"
          />
        </g>

        <g id="fingerprint">
          <path
            className="g"
            d="M1059.18,571.65c-6-1-13.44-2-20-2.56a270.53,270.53,0,0,0-28.76-1.48c-5.09.06-10.51-.16-15.59-.1-.53,0-1.07,0-1.57.07-2,.16-3.25.7-3,1.33a2.42,2.42,0,0,0,.92.86c.77.47,1.75.91,2.54,1.38A6.2,6.2,0,0,1,997,575c.31,1.15,0,2.26-1.43,3.32a14.52,14.52,0,0,1-3.3,1.59"
          />
          <path
            className="g"
            d="M979.38,536.86c-4.79-.27-9.5-.37-14-.29q-6,.43-11.65,1.37a105.23,105.23,0,0,0-12.89,3,72.16,72.16,0,0,0-12.59,4.9c-.88.46-1.73.92-2.5,1.39a7.05,7.05,0,0,0-1.14,2.7"
          />
          <path
            className="g"
            d="M1036.37,547.94a178.87,178.87,0,0,0-32.1-8.41c-1.74-.24-3.54-.45-5.4-.6q-10.35-.87-21-.73c-10.1.11-19.31.94-27.18,2.84,0,0-4.59,1.14-6.71,1.77a61,61,0,0,0-10.07,3.79c-4.82,2.49-7.25,5.2-6.75,8.14.38,2.28,2,4.51,4.3,6.69a51.7,51.7,0,0,0,6.06,3.7"
          />
          <path
            className="g"
            d="M957.83,572.89l1.21-.13a34.29,34.29,0,0,0,7.78-1.53c3.14-1.18,4.72-2.49,3.22-4.05a1,1,0,0,1-.28-.62c0-.43.76-.66,2.19-.58.95,0,1.91.15,2.8.27a38,38,0,0,1,6.57,1.54c4.27,1.37,7,3,8.59,4.78a4.64,4.64,0,0,1,1.06,4.88,3.21,3.21,0,0,1-2,2.06"
          />
          <path
            className="g"
            d="M1055,574.79c-.37-.06-.74-.13-1.14-.18-6.1-.8-12.17-1.62-18.33-2.37-4.91-.59-10.06-.93-15.2-1.26a50.32,50.32,0,0,0-8.79.18c-2.44.28-3.82.81-4.12,1.61a2.07,2.07,0,0,0,.41,1.72,9.44,9.44,0,0,1,.88,1.6c.47,1.51-.79,2.91-2.65,4.26a5.74,5.74,0,0,1-1.27.7"
          />
          <path
            className="g"
            d="M1060.08,564.73c-.83.08-1.7.14-2.61.17-1.49.06-3,.08-4.51.1-3.82,0-7.64.09-11.47.11-1.91,0-3.83-.05-5.74-.06a19.57,19.57,0,0,0-4.84.5c-1.08.28-.79.7.57.86.44.05.91.07,1.37.1,3.74.22,7.5.41,11.22.66,5.06.34,10,.78,14.77,1.47l1.63.25"
          />
          <path
            className="g"
            d="M1045.59,578.38l-3.78-.55c-2.56-.37-5.06-.78-7.68-1.11a56.25,56.25,0,0,0-8.71-.45,6.18,6.18,0,0,0-2.91.6,1.83,1.83,0,0,0-.69.66,8.51,8.51,0,0,0-.38,1.44,3.64,3.64,0,0,1-1.66,2.42"
          />
          <path
            className="g"
            d="M1051.5,576.37l-3.38-.55c-5-.79-10-1.54-15.31-2.06a77.79,77.79,0,0,0-9.23-.43c-4.54.11-7.4.86-8.31,2.18a1.44,1.44,0,0,0,.06,1.75c.76,1.11.39,2.2-.62,3.26a3.25,3.25,0,0,1-1.28.86"
          />
          <path
            className="g"
            d="M1041.55,579.19l-2.73-.24a53.65,53.65,0,0,0-6.74-.27c-.78,0-1.54.14-2.3.21a10.77,10.77,0,0,0-1.58.68c-.74.52-1.34,1.07-2,1.6"
          />
          <path
            className="g"
            d="M978.8,577.91a2.8,2.8,0,0,0,1.12-1.86c0-.54-.27-1.06-1.79-1.66a5.27,5.27,0,0,0-3.06,0c-.8.23-1.49.48-2.19.74a16.13,16.13,0,0,1-2.69.93"
          />
          <path
            className="g"
            d="M982.93,578.56a5.54,5.54,0,0,0,1-1.24c.88-1.76.52-3.52-1-5.27a7.1,7.1,0,0,0-2.53-1.7l-.33-.13c-1.71-.59-4.19-.5-5.21.18a1.75,1.75,0,0,0-.6.63,4.57,4.57,0,0,1-2,1.87,16.85,16.85,0,0,1-6.31,1.82c-.43,0-.87.09-1.31.12"
          />
          <path
            className="g"
            d="M1054.28,557.74a38.83,38.83,0,0,1-6,.3c-2.29,0-4.58-.14-6.85-.26a97.09,97.09,0,0,1-13.55-1.54c-3.65-.72-7.06-1.53-10.35-2.39-4-1.05-8-2.11-12-3.15a110.28,110.28,0,0,0-13.1-2.56,72.7,72.7,0,0,0-16.25-.42,102.55,102.55,0,0,0-10.43,1.4c-4.62.91-8.12,2.11-10.45,3.63-3.31,2.17-3.73,4.38-.61,6.67,1.73,1.26,3.55,2.51,5.36,3.77a16.86,16.86,0,0,1,3.35,2.68c1.07,1.34.38,2.54-2.74,3.54a36,36,0,0,1-8.54,1.46"
          />
          <path
            className="g"
            d="M1059.18,562.71c-.72.1-1.46.2-2.21.28a105.37,105.37,0,0,1-18.25.32,41.85,41.85,0,0,0-4.88-.06"
          />
          <path
            className="g"
            d="M1003.59,560.81c-1,0-1.88,0-2.71-.12-3.06-.3-6.12-.59-9.12-.94a25.14,25.14,0,0,1-3.2-.64c-1-.24-1.46-.59-1.43-1s.48-.47,1.56-.38,2.47.22,3.58.4a98.76,98.76,0,0,1,10.86,2.44l.31.11S1003.49,560.74,1003.59,560.81Z"
          />
          <path
            className="g"
            d="M1024.27,562c-4.73.16-9.45.42-14.21.44a167.19,167.19,0,0,1-21.58-1.22,28.69,28.69,0,0,1-7.62-1.82c-2-.91-2.48-1.89-1.79-2.91.42-.62,1.93-1,3.67-1.25a28.57,28.57,0,0,1,8.66.09,66.78,66.78,0,0,1,8,1.76c2.68.74,5.23,1.53,8.08,2.2a131.83,131.83,0,0,0,16.36,2.59l.48,0Z"
          />
          <path
            className="g"
            d="M1056,560.62a159,159,0,0,1-17.74.07,126.22,126.22,0,0,1-27.5-4.15c-2.91-.82-5.82-1.65-8.84-2.43a70.56,70.56,0,0,0-11.6-2,47.87,47.87,0,0,0-12.61.47"
          />
          <path
            className="g"
            d="M969.72,554.43l-.44.15c-1.92.73-2.43,1.58-2.11,2.5.59,1.68,2.94,3.16,5.46,4.21a36.85,36.85,0,0,0,7,2.08A83.92,83.92,0,0,0,994,564.7c6.44.13,12.81,0,19.14-.29,4.58-.19,9.14-.42,13.83-.26"
          />
          <path
            className="g"
            d="M1048.55,553.65a18.62,18.62,0,0,1-2.16.09,38.89,38.89,0,0,1-6.12-.58c-4.45-.73-8.43-1.67-12.32-2.64-5.37-1.34-10.75-2.68-16.56-3.85s-11.64-2.16-18.12-2.82a128,128,0,0,0-28.9.18,84.48,84.48,0,0,0-14.57,3.06c-5.95,1.83-9.19,4-9.64,6.63-.47,2.76,1.24,5.42,4.86,8a14.72,14.72,0,0,1,3.72,3.34,1.24,1.24,0,0,1,.21,1.4c-.38.59-1.6.9-3.57.93H945"
          />
          <path
            className="g"
            d="M1043.21,550.89a60,60,0,0,1-8.3-1.84c-2.52-.8-5.27-1.53-8.22-2.18-6.74-1.48-13.85-2.79-21-4-4.56-.81-9.33-1.48-14.42-1.89a152.44,152.44,0,0,0-25.38.1,87.78,87.78,0,0,0-20.05,3.7c-6.86,2.33-10.67,5.09-11.54,8.24a7.25,7.25,0,0,0,1,5.4,10.72,10.72,0,0,0,3.29,3.64,31,31,0,0,1,2.49,2,1.29,1.29,0,0,1,.56,1.38c-.07.22-.25.44-1,.53-.21,0-.44,0-.68.05"
          />
          <path
            className="g"
            d="M1051.78,555.74a13.64,13.64,0,0,1-1.82.29,36,36,0,0,1-4.32.17,73.45,73.45,0,0,1-12.07-1.19c-5.65-1-10.77-2.28-15.76-3.58-3.65-1-7.24-1.93-11-2.82a119.24,119.24,0,0,0-16.88-2.72,101,101,0,0,0-21,.17,73.62,73.62,0,0,0-14.48,3.05,22.53,22.53,0,0,0-6.41,2.95c-1.74,1.35-2,2.76-1.47,4.2.78,2.12,2.9,4.14,5.74,6.11a16.74,16.74,0,0,1,3.72,3,1.76,1.76,0,0,1,.12,2.46c-.67.76-2.27,1.21-4.86,1.31-.64,0-1.29,0-1.93,0H949"
          />
        </g>

        <g id="house0">
          <polygon
            className="l"
            points="635.58 434.37 591.76 422.63 591.76 306.76 635.58 318.5 635.58 434.37"
          />
          <polygon
            className="d"
            points="635.58 318.6 591.76 306.76 635.58 294.93 679.4 306.76 635.58 318.6"
          />
          <polygon
            className="b"
            points="679.4 422.63 635.58 434.37 635.58 318.5 679.4 306.76 679.4 422.63"
          />
          <polygon
            className="m"
            points="659.96 351.15 648.45 354.24 648.45 330.72 659.96 327.63 659.96 351.15"
          />
          <polygon
            className="m"
            points="675.87 382.86 664.36 385.94 664.36 362.42 675.87 359.34 675.87 382.86"
          />
        </g>

        <g id="house1">
          <polygon
            className="l"
            points="508.93 571.34 465.12 559.6 465.12 443.74 508.93 455.48 508.93 571.34"
          />
          <polygon
            className="d"
            points="508.93 455.57 465.11 443.74 508.93 431.91 552.76 443.74 508.93 455.57"
          />
          <polygon
            className="b"
            points="552.75 559.6 508.93 571.34 508.93 455.48 552.75 443.74 552.75 559.6"
          />
        </g>

        <g id="house2">
          <polygon
            className="l"
            points="441.33 592.27 397.51 580.53 397.51 376.48 441.33 388.22 441.33 592.27"
          />
          <polygon
            className="d"
            points="441.33 388.31 397.5 376.48 441.33 364.65 485.15 376.48 441.33 388.31"
          />
          <polygon
            className="b"
            points="485.15 580.53 441.33 592.27 441.33 388.22 485.15 376.48 485.15 580.53"
          />
          <polygon
            className="m"
            points="468.99 570.09 457.48 573.18 457.48 549.65 468.99 546.57 468.99 570.09"
          />
        </g>

        <g id="house3">
          <polygon
            className="l"
            points="703.9 623.7 660.08 611.95 660.08 496.09 703.9 507.83 703.9 623.7"
          />
          <polygon
            className="d"
            points="703.9 507.92 660.07 496.09 703.9 484.26 747.72 496.09 703.9 507.92"
          />
          <polygon
            className="b"
            points="747.72 611.95 703.9 623.7 703.9 507.83 747.72 496.09 747.72 611.95"
          />
          <polygon
            className="m"
            points="724.46 539.98 712.95 543.06 712.95 519.54 724.46 516.45 724.46 539.98"
          />
          <polygon
            className="m"
            points="686.53 602.5 675.01 599.42 675.01 575.89 686.53 578.98 686.53 602.5"
          />
        </g>

        <path
          id="line"
          className="g"
          d="M979.92,605.11c-43.09,45.31-75.66,57.42-146.64,49.54C710.7,641,698.33,460.42,553.72,366.36,347.78,232.41-.69,355.73.5,401.92c1.18,45.75,348.73,143.82,491.65-10,69.46-74.77,68-184.68,61.93-243.46"
        />

        <g id="plane">
          <g id="plane-inner">
            <polygon className="h" points="802.48 0 424.87 85.05 536.87 117.76 802.48 0" />
            <polygon className="i" points="703.68 163.31 802.48 0 590.3 133.35 703.68 163.31" />
            <polygon
              className="j"
              points="626.28 143.94 576.64 185.84 590.3 133.35 626.28 143.94"
            />
            <path className="k" d="M536.87,117.76s39.23,70.18,39.77,68.08l13.66-52.49L802.48,0Z" />
          </g>
        </g>
      </svg>
    </div>
  );
}

export const animate = () => {
  const svgs = document.querySelectorAll('.egov-illustration');
  if (!svgs) return;

  const timeline = anime.timeline({
    autoplay: false,
    loop: false,
  });

  const animations = [
    {
      targets: '#base',
      translateY: [30, 0],
      opacity: [0, 0.5],
      easing: 'easeInOutSine',
      duration: 500,
      delay: 0,
    },
    {
      targets: '#hq',
      opacity: [0, 1],
      easing: 'easeOutSine',
      duration: 500,
      delay: 300,
    },
    {
      targets: '#hq-windows > *',
      opacity: [0, 1],
      easing: 'easeOutSine',
      duration: 100,
      delay: () => 250 + Math.random() * 750,
    },
    ...[0, 1, 2, 3].map(idx => {
      return {
        targets: `#house${idx}`,
        translateY: [30, 0],
        opacity: [0, 1],
        easing: 'easeOutCirc',
        duration: 750,
        delay: 300 + idx * 100,
      };
    }),
    {
      targets: '#phone',
      translateY: [50, 0],
      opacity: [0, 1],
      duration: 1000,
      delay: 950,
    },
    {
      targets: '#phone-shadow',
      translateY: [-50, 0],
      opacity: [0, 0.49],
      duration: 1000,
      delay: 1000,
    },
    {
      targets: '#fingerprint > path',
      strokeDashoffset: [anime.setDashoffset, 0],
      easing: 'easeInOutSine',
      duration: 500,
      delay: 1200,
    },
    {
      targets: '#line',
      strokeDashoffset: [anime.setDashoffset, 0],
      easing: 'easeInOutSine',
      duration: 750,
      delay: 1250,
    },
    {
      targets: '#plane',
      translateX: [-50, 0],
      translateY: [35, 0],
      opacity: [0, 1],
      easing: 'easeOutSine',
      duration: 350,
      delay: 1800,
    },
  ];

  anime({
    targets: '#plane-inner',
    translateY: [2, -2],
    easing: 'easeInOutSine',
    duration: 750,
    direction: 'alternate',
    loop: true,
  });

  animations.forEach(animation => timeline.add(animation, 0));

  svgs.forEach(svg => (svg.style.opacity = '1'));
  timeline.play();
};
