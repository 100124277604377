import React from 'react';
import FinTech from 'components/Illustrations/FinTech';
import HealthTech from 'components/Illustrations/HealthTech';
import Robotics from 'components/Illustrations/Robotics';
import CreaTech from 'components/Illustrations/CreaTech';
import PropTech from 'components/Illustrations/PropTech';
import AgroTech from 'components/Illustrations/AgroTech';
import Egovernance from 'components/Illustrations/Egovernance';
import AI from 'components/Illustrations/AI';
import StartupScene from 'components/Illustrations/StartupScene';
import DelegationOffline from 'components/Illustrations/DelegationOffline';
import EdTech from 'components/Illustrations/EdTech';

const techs = [
  'fintech',
  'healthtech',
  'robotics',
  'createch',
  'proptech',
  'agrotech',
  'egovernance',
  'edtech',
  'ai',
  'startups',
];

export default function ({ tech }) {
  if (!tech) tech = techs[Math.floor(Math.random() * techs.length)];
  switch (tech) {
    case 'fintech':
      return <FinTech />;
    case 'healthtech':
      return <HealthTech />;
    case 'robotics':
      return <Robotics />;
    case 'createch':
      return <CreaTech />;
    case 'proptech':
      return <PropTech />;
    case 'agrotech':
      return <AgroTech />;
    case 'egovernance':
      return <Egovernance />;
    case 'edtech':
      return <EdTech />;
    case 'ai':
      return <AI />;
    case 'startups':
    case 'all-startups':
      return <StartupScene />;
    case 'delegation-offline':
      return <DelegationOffline />;
    default:
      return null;
  }
}
