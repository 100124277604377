import { useEffect } from 'react';
import anime from 'animejs/lib/anime.es.js';
import 'styles/illustrations/agrotech.css';

export default function AgroTech() {
  useEffect(() => {
    animate();
  }, []);

  return (
    <div className="agrotech-illustration">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        viewBox="0 0 1190 723"
      >
        <title>AgroTech</title>
        <defs>
          <linearGradient
            id="agrotech-a"
            x1="981"
            y1="201.43"
            x2="1052.89"
            y2="201.43"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0" stopColor="#203075" />
            <stop offset="1" stopColor="#ffe964" />
          </linearGradient>
          <linearGradient
            id="agrotech-b"
            x1="740.44"
            y1="111.56"
            x2="1057.54"
            y2="111.56"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0" stopColor="#5ec2bb" />
            <stop offset="1" stopColor="#203075" />
          </linearGradient>
          <linearGradient
            id="agrotech-c"
            x1="756.46"
            y1="166.45"
            x2="800.22"
            y2="166.45"
            xlinkHref="#agrotech-a"
          />
          <linearGradient
            id="agrotech-d"
            x1="610.05"
            y1="111.75"
            x2="803.05"
            y2="111.75"
            xlinkHref="#agrotech-b"
          />
          <linearGradient
            id="agrotech-e"
            x1="441.1"
            y1="407.09"
            x2="561.62"
            y2="407.09"
            xlinkHref="#agrotech-b"
          />
          <linearGradient
            id="agrotech-f"
            x1="441.1"
            y1="331.43"
            x2="680.56"
            y2="331.43"
            xlinkHref="#agrotech-b"
          />
          <linearGradient
            id="agrotech-g"
            x1="798.04"
            y1="508.35"
            x2="918.56"
            y2="508.35"
            xlinkHref="#agrotech-a"
          />
          <linearGradient
            id="agrotech-h"
            x1="798.04"
            y1="432.69"
            x2="1037.5"
            y2="432.69"
            xlinkHref="#agrotech-a"
          />
          <linearGradient
            id="agrotech-i"
            x1="359.7"
            y1="612.55"
            x2="725.84"
            y2="612.55"
            xlinkHref="#agrotech-b"
          />
          <linearGradient
            id="agrotech-j"
            x1="218.8"
            y1="456.14"
            x2="291.38"
            y2="456.14"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0" stopColor="#5ec2bb" />
            <stop offset="0.13" stopColor="#5cbcb8" />
            <stop offset="0.31" stopColor="#54acb0" />
            <stop offset="0.51" stopColor="#4990a3" />
            <stop offset="0.73" stopColor="#396a91" />
            <stop offset="0.96" stopColor="#243a7a" />
            <stop offset="1" stopColor="#203075" />
          </linearGradient>
          <linearGradient
            id="agrotech-k"
            x1="544.72"
            y1="338.68"
            x2="643.83"
            y2="338.68"
            xlinkHref="#agrotech-b"
          />
        </defs>

        <polygon
          id="base"
          className="agrotech-a"
          points="1195.23 264 873.28 350.93 872.31 350.67 438.18 467.88 438.25 467.9 9.26 583.72 294.24 660.67 728.37 543.45 728.3 543.44 1155.03 428.22 1156.01 428.48 1195.23 417.89 1195.23 264"
        />
        <polygon
          id="road"
          className="agrotech-b"
          points="1189.98 530.1 1146.74 518.42 1145.77 518.68 719.04 403.47 719.1 403.45 284.98 286.24 0 363.18 428.98 479 428.92 479.02 863.04 596.24 864.02 595.97 1189.98 683.98 1189.98 530.1"
        />

        <g id="corn1">
          <rect className="agrotech-c" x="849.07" y="206.98" width="2" height="105.21" />
          <path
            className="agrotech-c"
            d="M858.42,292.19c-4.5.47-6.11,3.78-7.67,7.41v12.12c.11-1,.23-1.93.37-2.89a33,33,0,0,1,2.61-9.49c1.72-3.64,3.94-4.95,8.75-4.69a25.74,25.74,0,0,1,5,1.2C865.9,293.92,861.47,291.88,858.42,292.19Z"
          />
          <path
            className="agrotech-c"
            d="M850.27,209.73c5.14-5.08,9.13-11.87,4.1-19.17-1.16-2-2.7-3.79-4.15-5.79-4.1,4.07-6.9,8.42-6.61,14.12.2,3.86,3.93,8.18,6.66,10.84"
          />
          <path
            className="agrotech-c"
            d="M850.77,225.09c6.35-.1,13-2,14.26-9.66.48-2,.59-4,.89-6.19-5.07.09-9.5,1.14-12.77,4.93-2.23,2.57-2.49,7.57-2.38,10.92"
          />
          <path
            className="agrotech-c"
            d="M849.51,225.09c-6.35-.1-13-2-14.26-9.66a61,61,0,0,1-.89-6.19c5.07.09,9.49,1.14,12.77,4.93,2.23,2.57,2.49,7.57,2.38,10.92"
          />
          <path
            className="agrotech-c"
            d="M850.77,246c6.35-.1,13-2,14.26-9.66.48-2,.59-4,.89-6.19-5.07.09-9.5,1.14-12.77,4.93-2.23,2.57-2.49,7.57-2.38,10.92"
          />
          <path
            className="agrotech-c"
            d="M849.51,246c-6.35-.1-13-2-14.26-9.66a61,61,0,0,1-.89-6.19c5.07.09,9.49,1.14,12.77,4.93,2.23,2.57,2.49,7.57,2.38,10.92"
          />
          <path
            className="agrotech-c"
            d="M850.77,267c6.35-.1,13-2,14.26-9.66.48-2,.59-4,.89-6.19-5.07.09-9.5,1.14-12.77,4.93-2.23,2.57-2.49,7.57-2.38,10.92"
          />
          <path
            className="agrotech-c"
            d="M849.51,267c-6.35-.1-13-2-14.26-9.66a61,61,0,0,1-.89-6.19c5.07.09,9.49,1.14,12.77,4.93,2.23,2.57,2.49,7.57,2.38,10.92"
          />
          <path
            className="agrotech-c"
            d="M850.77,287.88c6.35-.1,13-2,14.26-9.66.48-2,.59-4,.89-6.19-5.07.09-9.5,1.14-12.77,4.93-2.23,2.57-2.49,7.57-2.38,10.92"
          />
          <path
            className="agrotech-c"
            d="M849.51,287.88c-6.35-.1-13-2-14.26-9.66a61,61,0,0,1-.89-6.19c5.07.09,9.49,1.14,12.77,4.93,2.23,2.57,2.49,7.57,2.38,10.92"
          />
        </g>

        <g id="corn2">
          <rect className="agrotech-c" x="801.9" y="223.14" width="2" height="105.21" />
          <path
            className="agrotech-c"
            d="M811.12,307.26c-4.5.46-6.12,3.78-7.67,7.41v12.12c.11-1,.23-1.93.37-2.89a33,33,0,0,1,2.6-9.49c1.72-3.64,4-5,8.76-4.69a25.74,25.74,0,0,1,5,1.2C818.6,309,814.17,307,811.12,307.26Z"
          />
          <path
            className="agrotech-c"
            d="M803.11,225.89c5.14-5.08,9.13-11.87,4.1-19.17-1.17-2-2.7-3.78-4.15-5.79-4.1,4.08-6.9,8.43-6.61,14.12.2,3.87,3.93,8.18,6.66,10.84"
          />
          <path
            className="agrotech-c"
            d="M803.61,241.26c6.35-.1,13-2,14.26-9.66.48-2,.59-4,.89-6.2-5.08.09-9.5,1.15-12.78,4.94-2.22,2.56-2.48,7.57-2.37,10.92"
          />
          <path
            className="agrotech-c"
            d="M802.34,241.26c-6.35-.1-13-2-14.25-9.66-.48-2-.6-4-.9-6.2,5.08.09,9.5,1.15,12.78,4.94,2.22,2.56,2.48,7.57,2.37,10.92"
          />
          <path
            className="agrotech-c"
            d="M803.61,262.19c6.35-.1,13-2,14.26-9.66.48-2,.59-4,.89-6.2-5.08.09-9.5,1.15-12.78,4.94-2.22,2.56-2.48,7.57-2.37,10.92"
          />
          <path
            className="agrotech-c"
            d="M802.34,262.19c-6.35-.1-13-2-14.25-9.66-.48-2-.6-4-.9-6.2,5.08.09,9.5,1.15,12.78,4.94,2.22,2.56,2.48,7.57,2.37,10.92"
          />
          <path
            className="agrotech-c"
            d="M803.61,283.12c6.35-.1,13-2,14.26-9.66.48-2,.59-4,.89-6.19-5.08.08-9.5,1.14-12.78,4.93-2.22,2.56-2.48,7.57-2.37,10.92"
          />
          <path
            className="agrotech-c"
            d="M802.34,283.12c-6.35-.1-13-2-14.25-9.66-.48-2-.6-4-.9-6.19,5.08.08,9.5,1.14,12.78,4.93,2.22,2.56,2.48,7.57,2.37,10.92"
          />
          <path
            className="agrotech-c"
            d="M803.61,304.05c6.35-.1,13-2,14.26-9.66.48-2,.59-4,.89-6.19-5.08.08-9.5,1.14-12.78,4.93-2.22,2.57-2.48,7.57-2.37,10.92"
          />
          <path
            className="agrotech-c"
            d="M802.34,304.05c-6.35-.1-13-2-14.25-9.66-.48-2-.6-4-.9-6.19,5.08.08,9.5,1.14,12.78,4.93,2.22,2.57,2.48,7.57,2.37,10.92"
          />
        </g>

        <g id="cloud1">
          <path
            className="agrotech-d"
            d="M817.86,33.75a46.49,46.49,0,0,0-21,11,69,69,0,0,1,10,1.94c1.22.32,2.42.68,3.62,1.07A54.16,54.16,0,0,1,817.86,33.75Z"
          />
          <path
            className="agrotech-d"
            d="M1056.42,127.76a44.56,44.56,0,0,0-7.13-1.3c-2.09-21.81-20-43.22-41.82-49.06a41.62,41.62,0,0,0-12.1-1.47c-9.22-33.64-38-64.11-72.21-73.26C911.22-.53,899.94-.76,889.91,1.5h0l-.24.07a56.49,56.49,0,0,0-6.6,1.93L847.48,13.91h0c10.4-2.64,22.23-2.55,34.77.82,34.16,9.15,63,39.62,72.21,73.26a41.62,41.62,0,0,1,12.1,1.47c21.8,5.84,39.73,27.25,41.82,49.06a44.56,44.56,0,0,1,7.13,1.3c23.21,6.22,42,30.08,42,53.29a34.67,34.67,0,0,1-4.65,18L1093.8,199a34.67,34.67,0,0,0,4.65-18C1098.45,157.84,1079.63,134,1056.42,127.76Z"
          />
          <path
            className="agrotech-f"
            d="M1052.89,211.07a34.67,34.67,0,0,0,4.65-18c0-23.21-18.82-47.07-42-53.29a44.56,44.56,0,0,0-7.13-1.3c-2.09-21.81-20-43.22-41.82-49.06A41.62,41.62,0,0,0,954.46,88c-9.22-33.64-38.05-64.11-72.21-73.26-33.54-9-62,5.33-71.69,33-1.2-.39-2.4-.75-3.62-1.07-36.73-9.84-66.5,12-66.5,48.68a79.65,79.65,0,0,0,8.18,34.17Z"
          />
        </g>

        <g id="cloud2">
          <path
            className="agrotech-d"
            d="M657.17,64.4a28.33,28.33,0,0,0-12.75,6.69,45,45,0,0,1,6.11,1.18c.74.2,1.47.42,2.2.65A33.13,33.13,0,0,1,657.17,64.4Z"
          />
          <path
            className="agrotech-d"
            d="M802.36,121.61a27.7,27.7,0,0,0-4.33-.79c-1.28-13.27-12.19-26.3-25.46-29.86a25.66,25.66,0,0,0-7.36-.89c-5.61-20.47-23.16-39-44-44.59A42.12,42.12,0,0,0,701,44.77h0l-.15,0a35,35,0,0,0-4,1.18L675.2,52.32h0a41.74,41.74,0,0,1,21.16.5c20.79,5.57,38.34,24.12,43.95,44.59a25.66,25.66,0,0,1,7.36.89c13.27,3.56,24.18,16.59,25.46,29.86a27.82,27.82,0,0,1,4.34.79c14.12,3.79,25.58,18.31,25.58,32.44a21.16,21.16,0,0,1-2.83,10.93l24.9-7.34a21.16,21.16,0,0,0,2.82-10.93C827.94,139.92,816.49,125.4,802.36,121.61Z"
          />
          <polygon
            className="agrotech-g"
            points="756.46 160.59 800.22 172.32 800.22 172.32 756.46 160.59"
          />
          <path
            className="agrotech-h"
            d="M800.22,172.32a21.16,21.16,0,0,0,2.83-10.93c0-14.13-11.46-28.65-25.59-32.44a27.49,27.49,0,0,0-4.33-.79c-1.28-13.27-12.19-26.3-25.46-29.86a25.66,25.66,0,0,0-7.36-.89c-5.61-20.47-23.16-39-43.95-44.59-20.41-5.47-37.7,3.25-43.63,20.1-.73-.23-1.46-.45-2.2-.65-22.36-6-40.48,7.28-40.48,29.63a48.5,48.5,0,0,0,5,20.8Z"
          />
        </g>

        <path
          id="line1"
          className="agrotech-i"
          d="M743.57,277.17q-601.53,42.09-601.12,77,.33,27.3,368.37,50.24"
        />

        <g id="package">
          <polygon
            className="agrotech-j"
            points="561.62 483.5 441.1 451.2 441.1 330.68 561.62 362.97 561.62 483.5"
          />
          <polygon
            className="agrotech-d"
            points="682.15 451.2 561.62 483.5 561.62 362.97 682.15 330.68 682.15 451.2"
          />
          <polygon
            className="agrotech-k"
            points="680.56 330.68 561.62 363.88 441.1 330.68 561.62 298.99 680.56 330.68"
          />

          <polygon
            className="agrotech-s"
            points="643.83 340.98 583.98 325.44 544.72 336.19 604.42 351.92 643.83 340.98"
          />
          <polygon
            className="agrotech-t"
            points="643.83 340.98 583.98 325.44 544.72 336.19 604.42 351.92 643.83 340.98"
          />
          <polygon
            className="agrotech-r"
            points="643.83 358.33 643.83 340.98 604.45 351.94 604.23 432.41 604.25 432.4 606.78 426.62 609.26 430.98 611.74 425.29 614.22 429.65 616.7 423.96 619.19 428.32 621.67 422.63 624.15 426.99 626.63 421.3 629.11 425.66 631.6 419.97 634.08 424.33 636.56 418.64 639.04 423 641.52 417.31 643.83 421.35 643.83 358.33 643.83 358.33"
          />
        </g>

        <path id="line2" className="agrotech-i" d="M602.92,446.29l216,61.95" />

        <g id="box">
          <polygon
            className="agrotech-m"
            points="918.56 584.76 798.04 552.46 798.04 431.94 918.56 464.23 918.56 584.76"
          />
          <polygon
            className="agrotech-c"
            points="1039.09 552.46 918.56 584.76 918.56 464.23 1039.09 431.94 1039.09 552.46"
          />
          <polygon
            className="agrotech-c"
            points="1066.55 469.51 946.02 501.81 918.56 464.23 1039.09 431.94 1066.55 469.51"
          />
          <polygon
            className="agrotech-c"
            points="771.53 469.51 892.06 501.81 919.52 464.23 798.99 431.94 771.53 469.51"
          />
          <polygon
            className="agrotech-c"
            points="771.53 394.88 892.06 362.58 919.52 400.16 798.99 432.46 771.53 394.88"
          />
          <polygon
            className="agrotech-c"
            points="1066.55 394.88 946.02 362.58 918.56 400.16 1039.09 432.46 1066.55 394.88"
          />
          <polygon
            className="agrotech-n"
            points="1037.5 431.94 918.56 465.14 798.04 431.94 918.56 400.25 1037.5 431.94"
          />
        </g>

        <g id="plate">
          <ellipse
            id="plate-shadow"
            className="agrotech-o"
            cx="542.77"
            cy="671.76"
            rx="187.92"
            ry="50.74"
          />
          <path
            className="agrotech-d"
            d="M725.84,612.54V611l-.08.11A13.52,13.52,0,0,1,725.84,612.54Z"
          />
          <path
            className="agrotech-d"
            d="M359.78,611.14l-.08,0c0,.09,0,.51,0,1.13A11.45,11.45,0,0,1,359.78,611.14Z"
          />
          <path
            className="agrotech-d"
            d="M672.22,647.5c-71.49,19.3-187.41,19.3-258.9,0-35.49-9.58-53.36-22.12-53.61-34.68,0,2.09,0,5.64,0,7.76v1.73l.13.15c1.61,12,19.43,24,53.49,33.16,71.49,19.31,187.41,19.31,258.9,0,33.7-9.1,51.51-20.86,53.44-32.77l.18,0V612.55C725.84,625.2,708,637.85,672.22,647.5Z"
          />
          <ellipse className="agrotech-p" cx="542.77" cy="612.55" rx="183.07" ry="49.43" />
          <ellipse className="agrotech-a" cx="542.77" cy="615.95" rx="127.74" ry="34.49" />
        </g>

        <g id="coins">
          <ellipse className="agrotech-c" cx="505.81" cy="626.59" rx="34.83" ry="9.4" />
          <ellipse className="agrotech-c" cx="578.29" cy="607.4" rx="37.65" ry="10.17" />
          <ellipse
            className="agrotech-c"
            cx="504.51"
            cy="564.19"
            rx="10.64"
            ry="39.4"
            transform="translate(-251.18 521.99) rotate(-45)"
          />
          <ellipse
            className="agrotech-c"
            cx="540.64"
            cy="514.27"
            rx="32.74"
            ry="10.64"
            transform="translate(-205.29 532.92) rotate(-45)"
          />
        </g>

        <path
          id="line3"
          className="agrotech-i"
          d="M992.55,526.66c137.81,70.58,146.69,122.72,122.74,154-29,38-185.75,69.89-547.54-46.88"
        />

        <g id="house">
          <polygon
            className="agrotech-q"
            points="291.38 561.82 218.8 542.37 218.8 350.45 291.38 369.9 291.38 561.82"
          />
          <polygon
            className="agrotech-u"
            points="291.38 370.05 218.79 350.45 291.38 330.85 363.97 350.45 291.38 370.05"
          />
          <polygon
            className="agrotech-d"
            points="363.96 542.37 291.38 561.82 291.38 369.9 363.96 350.45 363.96 542.37"
          />
          <polygon
            id="house-window0"
            className="agrotech-l"
            points="331.77 423.98 312.7 429.09 312.7 390.13 331.77 385.02 331.77 423.98"
          />
          <polygon
            id="house-window1"
            className="agrotech-l"
            points="358.12 476.5 339.05 481.61 339.05 442.64 358.12 437.54 358.12 476.5"
          />
        </g>
      </svg>
    </div>
  );
}

export const animate = () => {
  const svgs = document.querySelectorAll('.agrotech-illustration');
  if (!svgs) return;

  const timeline = anime.timeline({
    autoplay: false,
    loop: false,
  });

  const animations = [
    {
      targets: '#base',
      translateX: [1400, 0],
      translateY: [-376, 0],
      opacity: [0, 0.5],
      easing: 'easeInSine',
      duration: 800,
      delay: 0,
    },
    {
      targets: '#cloud1',
      translateX: [200, 0],
      translateY: [38, 0],
      opacity: [0, 1],
      easing: 'easeOutSine',
      duration: 900,
      delay: 500,
    },
    {
      targets: '#cloud2',
      translateX: [200, 0],
      translateY: [38, 0],
      opacity: [0, 1],
      easing: 'easeOutSine',
      duration: 800,
      delay: 600,
    },
    {
      targets: '#corn1',
      scale: [0, 1],
      easing: 'spring(.4, 80, 8, 10)',
      duration: 300,
      delay: 1200,
    },
    {
      targets: '#corn1 > rect',
      translateY: [310, 0],
      scaleY: [0.01, 1],
      opacity: [0, 1],
      easing: 'easeOutSine',
      duration: 300,
      delay: 1200,
    },
    {
      targets: '#corn1 > path',
      opacity: [0, 1],
      easing: 'spring(.4, 80, 8, 10)',
      duration: 300,
      delay: (el, i, l) => 1400 - i * 10,
    },
    {
      targets: '#corn2',
      scale: [0, 1],
      opacity: [0, 1],
      easing: 'spring(.4, 80, 8, 10)',
      duration: 300,
      delay: 1300,
    },
    {
      targets: '#corn2 > rect',
      translateY: [310, 0],
      scaleY: [0.01, 1],
      opacity: [0, 1],
      easing: 'easeOutSine',
      duration: 300,
      delay: 1300,
    },
    {
      targets: '#corn2 > path',
      opacity: [0, 1],
      easing: 'spring(.4, 80, 8, 10)',
      duration: 300,
      delay: (el, i, l) => 1500 - i * 10,
    },
    {
      targets: '#road',
      translateX: [1400, 0],
      translateY: [376, 0],
      opacity: [0.5, 0.5],
      easing: 'easeOutSine',
      duration: 700,
      delay: 1300,
    },
    {
      targets: '#line1',
      strokeDashoffset: [anime.setDashoffset, 0],
      easing: 'easeInOutSine',
      duration: 350,
      delay: 1500,
    },
    {
      targets: '#package',
      translateX: [-20, 0],
      translateY: [-5, 0],
      opacity: [0, 1],
      easing: 'easeOutCirc',
      duration: 400,
      delay: 1700,
    },
    {
      targets: '#line2',
      strokeDashoffset: [anime.setDashoffset, 0],
      easing: 'easeInOutSine',
      duration: 250,
      delay: 1800,
    },
    {
      targets: '#box',
      translateX: [-20, 0],
      translateY: [-5, 0],
      opacity: [0, 1],
      easing: 'easeOutCirc',
      duration: 400,
      delay: 2000,
    },
    {
      targets: '#line3',
      strokeDashoffset: [anime.setDashoffset, 0],
      easing: 'easeInOutSine',
      duration: 500,
      delay: 2000,
    },
    {
      targets: '#plate',
      translateY: [20, 0],
      opacity: [0, 1],
      easing: 'easeOutCirc',
      duration: 400,
      delay: 2300,
    },
    {
      targets: '#coins > ellipse',
      translateY: [20, 0],
      opacity: [0, 1],
      easing: 'easeOutCirc',
      duration: 400,
      delay: (el, i, l) => 2450 + i * 50,
    },
    {
      targets: '#house',
      translateY: [20, 0],
      opacity: [0, 1],
      easing: 'spring(.6, 80, 8, 10)',
      duration: 400,
      delay: 2900,
    },
    ...[0, 1].map((idx) => {
      return {
        targets: `#house-window${idx}`,
        opacity: [0, 1],
        easing: 'easeOutBounce',
        duration: 300,
        delay: 3500 - idx * 200,
      };
    }),
  ];

  animations.forEach((animation) => timeline.add(animation, 0));

  svgs.forEach((svg) => (svg.style.opacity = '1'));
  timeline.play();
};
