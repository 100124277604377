import { useEffect } from 'react';
import anime from 'animejs/lib/anime.es.js';
import 'styles/illustrations/createch.css';

export default function CreaTech() {
  useEffect(() => {
    animate();
  }, []);

  return (
    <div className="createch-illustration">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        viewBox="0 0 955 741"
      >
        <title>CreativeTech</title>
        <defs>
          <linearGradient id="a" y1="526.63" x2="520.47" y2="526.63" gradientUnits="userSpaceOnUse">
            <stop offset="0" stopColor="#203075" />
            <stop offset="1" stopColor="#ffe964" />
          </linearGradient>
          <linearGradient
            id="b"
            x1="-347.8"
            y1="3503.78"
            x2="-87.92"
            y2="3503.78"
            gradientTransform="translate(2867.75 -1829.15) rotate(45)"
            xlinkHref="#a"
          />
          <linearGradient
            id="c"
            x1="8020.87"
            y1="247.13"
            x2="8118.7"
            y2="247.13"
            gradientTransform="matrix(-1, 0, 0, 1, 8313.72, 0)"
            xlinkHref="#a"
          />
          <linearGradient
            id="d"
            x1="764.57"
            y1="498.65"
            x2="789.67"
            y2="498.65"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0" stopColor="#203075" />
            <stop offset="1" stopColor="#0a1236" />
            <stop offset="1" />
          </linearGradient>
          <linearGradient id="e" x1="739.94" y1="475.13" x2="814.29" y2="475.13" xlinkHref="#a" />
        </defs>

        <polygon
          id="base"
          className="a"
          points="954.89 513.86 614.38 605.8 95.65 465.74 436.17 373.8 954.89 513.86"
        />
        <polygon
          id="screen"
          className="b"
          points="928.68 412.02 503.15 298 503.15 0 928.68 114.02 928.68 412.02"
        />
        <path
          id="avatar"
          className="c"
          d="M747,118.89l58.36,15.64v31.65l-26.58-7.12V185.5l-25.31-6.79v20.61l23.28,6.23v25.57L828,244.84v26.59l30.44,8.16v31.65l-58-15.55v-25.2l-23.57-6.32v23.21l-24.45-6.55v20.59l25.2,6.75v31.68L746,331.38V305.82l-26.57-7.12V272.27l-43.9-11.77v26.33l-26.64-7.14v25.6l-31.65-8.48V265.43l26.18,7V251.89l-25.4-6.8V221.56l-23.51-6.31v25.19l-58.29-15.62V193.36l31.4,8.41V175.28l50.27,13.47V163.07l23.44,6.28V148.93l-25.34-6.79V115.45l-26.39-7.08V76.72l58.1,15.57V118.7l25.57,6.86v25.52L721.34,164V138.55L747,145.42Zm-98.05,84.45v18.91l20.19,5.41V208.75Zm96.78,45v-19l-19.9-5.34v19Z"
        />

        <g id="phone">
          <path
            id="phone-shadow"
            className="d"
            d="M159.39,688.49,7.11,647.38c-9.23-2.49-9.23-6.54,0-9L323.18,553c9.23-2.49,24.21-2.49,33.44,0L508.9,594.13c9.23,2.49,9.23,6.53,0,9L192.83,688.49C183.6,691,168.62,691,159.39,688.49Z"
          />
          <path
            className="e"
            d="M160.69,595,7,553.51c-9.32-2.52-9.32-6.6,0-9.12l319-86.13c9.32-2.52,24.44-2.52,33.76,0l153.7,41.49c9.33,2.52,9.33,6.6,0,9.12L194.45,595C185.13,597.52,170,597.52,160.69,595Z"
          />
          <polygon
            id="phone-screen"
            className="f"
            points="204.94 585.1 43.67 541.56 328.44 464.68 489.71 508.22 204.94 585.1"
          />
          <path className="b" d="M520.46,504.22v-.1h0S520.46,504.19,520.46,504.22Z" />
          <path
            className="b"
            d="M513.48,508.87,194.45,595c-9.32,2.51-24.43,2.51-33.76,0L7,553.51c-4.36-1.18-6.66-2.7-6.93-4.25H0V570.9H0c.27,1.55,2.59,3.07,7,4.25l153.7,41.5c9.33,2.52,24.44,2.52,33.76,0l319-86.14c4.48-1.21,6.78-2.78,7-4.36h0V504.41C520.37,506,518.05,507.64,513.48,508.87Z"
          />
          <path
            id="phone-o"
            className="g"
            d="M317.05,500.09c-17.09,1.8-30.95,8.72-30.95,15.45s13.86,10.72,30.95,8.92S348,515.74,348,509,334.14,498.29,317.05,500.09Zm0,18.1c-8.3.87-15-1.07-15-4.33s6.72-6.62,15-7.5,15,1.06,15,4.33S325.34,517.31,317.05,518.19Z"
          />
          <path
            id="phone-x"
            className="g"
            d="M246.54,528.19l-15.83,1.68v-6.24c0-1.68-3.5-2.69-7.78-2.24s-7.79,2.2-7.79,3.88v6.24l-15.83,1.67c-4.28.45-7.78,2.2-7.78,3.88s3.5,2.7,7.78,2.24l15.83-1.67v6.24c0,1.68,3.51,2.69,7.79,2.24s7.78-2.2,7.78-3.88V536l15.83-1.67c4.28-.45,7.78-2.2,7.78-3.88S250.82,527.74,246.54,528.19Z"
          />
          <ellipse className="b" cx="110.03" cy="569.48" rx="15.16" ry="4.09" />
          <ellipse className="b" cx="438.59" cy="489.45" rx="7.58" ry="2.05" />
          <polygon
            className="b"
            points="418.26 483.97 384.24 474.78 387.3 473.95 421.32 483.14 418.26 483.97"
          />
        </g>
        <g id="goggles">
          <path className="b" d="M529.49,189.21h0l-.07,0Z" />
          <path
            className="b"
            d="M304.45,231.38l188.81-50.59a11.46,11.46,0,0,1,6.07,0l0-.07,26.91,7.59a12.24,12.24,0,0,0-4.47.38L333,239.26c-7,1.88-12.73,9.11-12.73,16.14v87.81c0,4.75,2.6,8.19,6.46,9.34l-28.35-7.82,0-.06c-3.94-1.1-6.65-4.52-6.65-9.33V247.52C291.72,240.49,297.42,233.27,304.45,231.38Z"
          />
          <path className="b" d="M327.32,352.71v0l-.24-.07Z" />
          <path
            className="h"
            d="M195.36,196.58a60.9,60.9,0,0,1,4.38-17.07l.16,0a28.66,28.66,0,0,0-2.66,12.18c0,26,31.84,56.57,79.11,80.48V270.6l16.5,4.42v39.74l-16.5-4.42v-3.29C224.42,278.53,191.2,236.19,195.36,196.58Z"
          />
          <path
            className="g"
            d="M333,352.53l188.8-50.59c7-1.88,12.73-9.11,12.73-16.14V198c0-7-5.7-11.2-12.73-9.31L333,239.26c-7,1.88-12.73,9.11-12.73,16.14v87.81C320.22,350.24,325.92,354.41,333,352.53Z"
          />
        </g>

        <polygon
          id="opague"
          className="i"
          points="409.55 511.84 928.68 372.74 928.68 9.2 409.55 148.3 409.55 511.84"
        />
        <g id="joystick">
          <polygon
            className="b"
            points="876.19 652.88 776.61 679.56 776.61 649.44 876.19 622.76 876.19 652.88"
          />
          <polygon
            className="b"
            points="776.61 679.56 677.04 652.88 677.04 622.76 776.61 649.44 776.61 679.56"
          />
          <polygon
            className="g"
            points="776.61 596.03 876.19 622.76 776.61 649.44 677.04 622.76 776.61 596.03"
          />
          <polygon
            className="j"
            points="776.61 687.57 876.19 714.3 776.61 740.98 677.04 714.3 776.61 687.57"
          />

          <g id="handle">
            <ellipse className="k" cx="777.12" cy="498.65" rx="12.55" ry="3.39" />
            <path
              className="b"
              d="M786,501a40,40,0,0,1-17.75,0c-2.44-.65-3.66-1.52-3.67-2.38V611.59h0a12.58,12.58,0,0,0,25,0h0V498.65C789.67,499.52,788.44,500.38,786,501Z"
            />
            <circle className="l" cx="777.12" cy="475.13" r="37.17" />
          </g>
        </g>
      </svg>
    </div>
  );
}

const animate = () => {
  const svgs = document.querySelectorAll('.createch-illustration');
  if (!svgs) return;

  const timeline = anime.timeline({
    autoplay: false,
    loop: false,
  });

  const animations = [
    {
      targets: '#base',
      translateY: [30, 0],
      opacity: [0, 0.25],
      easing: 'easeOutSine',
      duration: 750,
      delay: 0,
    },
    {
      targets: '#joystick',
      translateX: [-1000, 0],
      translateY: [-300, 0],
      opacity: [0, 1],
      easing: 'easeOutExpo',
      duration: 750,
      delay: 200,
    },
    {
      targets: '#handle',
      rotate: ['20deg', 0],
      easing: 'easeOutBounce',
      duration: 750,
      delay: 650,
    },
    {
      targets: '#goggles',
      opacity: [0, 1],
      translateY: [-250, 0],
      duration: 900,
      delay: 500,
    },
    {
      targets: '#opague',
      opacity: {
        value: [0, 0.35],
        duration: 300,
        easing: 'easeInOutElastic(10, 2)',
        delay: 700,
      },
      translateY: {
        value: [20, 0],
        duration: 900,
        easing: 'easeInOutSine',
        delay: 700,
      },
    },
    {
      targets: '#phone',
      translateY: [50, 0],
      opacity: [0, 1],
      duration: 1000,
      delay: 1250,
    },
    {
      targets: '#phone-shadow',
      translateY: [-50, 0],
      opacity: [0, 0.49],
      duration: 1000,
      delay: 1300,
    },
    {
      targets: '#screen, #avatar',
      translateX: [10, 0],
      translateY: [50, 0],
      rotateX: ['45deg', 0],
      opacity: [0, 1],
      easing: 'easeOutCirc',
      duration: 1500,
      delay: 1500,
    },
    {
      targets: '#phone-o',
      opacity: [0, 1, 0, 1, 0, 1, 0, 1],
      easing: 'easeOutBounce',
      duration: 750,
      delay: 1200,
    },
    {
      targets: '#phone-x',
      opacity: [0, 1, 0, 1, 0, 1, 0, 1],
      easing: 'easeOutBounce',
      duration: 750,
      delay: 1270,
    },
  ];

  animations.forEach(animation => timeline.add(animation, 0));

  svgs.forEach(svg => (svg.style.opacity = '1'));
  timeline.play();
};
