import { useEffect } from 'react';
import anime from 'animejs/lib/anime.es.js';
import 'styles/illustrations/edtech.css';

export default function EdTech() {
  useEffect(() => {
    animate();
  }, []);

  return (
    <div className="edtech-illustration">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        viewBox="0 0 1066 724"
      >
        <title>Education Tech</title>
        <defs>
          <linearGradient
            id="New_Gradient_Swatch_4"
            x1="888.07"
            y1="121.41"
            x2="1003.26"
            y2="121.41"
            gradientTransform="translate(313.91 -592.67) rotate(41.18)"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0" stopColor="#5ec2bb" />
            <stop offset="1" stopColor="#203075" />
          </linearGradient>
          <linearGradient
            id="New_Gradient_Swatch_1"
            x1="153.09"
            y1="556.03"
            x2="617.33"
            y2="556.03"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0" stopColor="#ee105a" />
            <stop offset="0.1" stopColor="#e8115b" />
            <stop offset="0.25" stopColor="#d5145d" />
            <stop offset="0.42" stopColor="#b81861" />
            <stop offset="0.61" stopColor="#8e1f67" />
            <stop offset="0.81" stopColor="#5a276d" />
            <stop offset="1" stopColor="#203075" />
          </linearGradient>
          <linearGradient
            id="New_Gradient_Swatch_1-2"
            x1="153.09"
            y1="598.64"
            x2="418.43"
            y2="598.64"
            xlinkHref="#New_Gradient_Swatch_1"
          />
          <linearGradient
            id="New_Gradient_Swatch_4-2"
            x1="423.23"
            y1="522.62"
            x2="887.47"
            y2="522.62"
            gradientTransform="matrix(1, 0, 0, 1, 0, 0)"
            xlinkHref="#New_Gradient_Swatch_4"
          />
          <linearGradient
            id="New_Gradient_Swatch_4-3"
            x1="423.23"
            y1="565.23"
            x2="688.57"
            y2="565.23"
            gradientTransform="matrix(1, 0, 0, 1, 0, 0)"
            xlinkHref="#New_Gradient_Swatch_4"
          />
          <linearGradient
            id="New_Gradient_Swatch_4-4"
            x1="5111.89"
            y1="401.65"
            x2="5562.41"
            y2="401.65"
            gradientTransform="matrix(-1, 0, 0, 1, 5985.29, 0)"
            xlinkHref="#New_Gradient_Swatch_4"
          />
          <linearGradient
            id="linear-gradient"
            x1="5161.75"
            y1="288.02"
            x2="5444.96"
            y2="224.43"
            gradientTransform="matrix(-1, 0, 0, 1, 5985.29, 0)"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0" stopColor="#5ec2bb" stopOpacity="0.3" />
            <stop offset="1" stopColor="#203075" />
          </linearGradient>
          <linearGradient
            id="laptop-screen-gradient"
            x1="5110.39"
            y1="268.9"
            x2="5383.4"
            y2="268.9"
            gradientTransform="matrix(-1, 0, 0, 1, 5985.29, 0)"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0" stopColor="#5ec2bb" stopOpacity="0.8" />
            <stop offset="0.28" stopColor="#488da2" stopOpacity="0.87" />
            <stop offset="0.59" stopColor="#325b89" stopOpacity="0.94" />
            <stop offset="0.84" stopColor="#253b7a" stopOpacity="0.98" />
            <stop offset="1" stopColor="#203075" />
          </linearGradient>
          <linearGradient
            id="New_Gradient_Swatch_4-5"
            x1="241.61"
            y1="225.72"
            x2="495.65"
            y2="225.72"
            gradientTransform="matrix(1, 0, 0, 1, 0, 0)"
            xlinkHref="#New_Gradient_Swatch_4"
          />
        </defs>

        <polygon
          id="base"
          className="edtech-cls-1"
          points="1065.42 609.55 643.2 723.55 0 549.89 422.22 435.89 1065.42 609.55"
        />

        <g id="clock">
          <ellipse
            className="edtech-cls-2"
            cx="958.1"
            cy="122.05"
            rx="58.04"
            ry="75.81"
            transform="translate(156.67 661.09) rotate(-41.18)"
          />
          <ellipse
            className="edtech-cls-3"
            cx="948.35"
            cy="122.05"
            rx="58.04"
            ry="75.81"
            transform="translate(154.26 654.67) rotate(-41.18)"
          />
          <ellipse
            className="edtech-cls-4"
            cx="945.67"
            cy="121.41"
            rx="50.39"
            ry="65.83"
            transform="translate(154.02 652.75) rotate(-41.18)"
          />
          <ellipse
            className="edtech-cls-2"
            cx="942.34"
            cy="121.58"
            rx="5.86"
            ry="7.66"
            transform="translate(153.08 650.6) rotate(-41.18)"
          />
          <ellipse
            className="edtech-cls-2"
            cx="942"
            cy="70.89"
            rx="2.69"
            ry="3.52"
            transform="matrix(0.75, -0.66, 0.66, 0.75, 186.38, 637.83)"
          />
          <ellipse
            className="edtech-cls-2"
            cx="942"
            cy="167.88"
            rx="2.69"
            ry="3.52"
            transform="translate(122.51 661.83) rotate(-41.18)"
          />
          <ellipse
            className="edtech-cls-2"
            cx="994.03"
            cy="141.68"
            rx="2.69"
            ry="3.52"
            transform="translate(152.63 689.61) rotate(-41.18)"
          />
          <ellipse
            className="edtech-cls-2"
            cx="895.45"
            cy="96.83"
            rx="2.69"
            ry="3.52"
            transform="translate(157.78 613.6) rotate(-41.18)"
          />
          <line className="edtech-cls-5" x1="942.34" y1="81.78" x2="942.34" y2="121.58" />
          <line className="edtech-cls-5" x1="942.74" y1="124.02" x2="964.27" y2="168.29" />
        </g>

        <g id="book-1">
          <path
            className="edtech-cls-2"
            d="M418.43,647.22l193.22-51.77a7.67,7.67,0,0,0,0-14.82l-233.36-63a22.53,22.53,0,0,0-11.81,0l-213.4,58.4Z"
          />
          <path
            className="edtech-cls-6"
            d="M418.43,621.2l193.22-51.77a7.67,7.67,0,0,0,0-14.82l-233.36-63a22.53,22.53,0,0,0-11.81,0l-213.4,58.39Z"
          />
          <polygon
            className="edtech-cls-7"
            points="153.09 550.05 153.09 576.08 418.43 647.22 418.43 621.2 153.09 550.05"
          />
          <polygon
            className="edtech-cls-8"
            points="418.43 621.2 418.43 644.73 612.18 591.97 612.18 569.43 418.43 621.2"
          />
          <line className="edtech-cls-9" x1="234.91" y1="554.57" x2="366.49" y2="517.68" />
          <line className="edtech-cls-9" x1="279.56" y1="560.15" x2="411.14" y2="523.26" />
          <line className="edtech-cls-9" x1="320.86" y1="566.85" x2="452.44" y2="529.96" />
        </g>

        <g id="book-2">
          <path
            className="edtech-cls-2"
            d="M688.57,613.81,881.79,562a7.67,7.67,0,0,0,0-14.81l-233.36-63a22.53,22.53,0,0,0-11.81,0l-213.4,58.4Z"
          />
          <path
            className="edtech-cls-10"
            d="M688.57,587.79,881.79,536a7.67,7.67,0,0,0,0-14.82l-233.36-63a22.53,22.53,0,0,0-11.81,0l-213.4,58.39Z"
          />
          <polygon
            className="edtech-cls-11"
            points="423.23 516.64 423.23 542.66 688.57 613.81 688.57 587.79 423.23 516.64"
          />
          <polygon
            className="edtech-cls-8"
            points="688.57 587.79 688.57 611.32 882.32 558.56 882.32 536.02 688.57 587.79"
          />
          <path
            className="edtech-cls-8"
            d="M578.65,516.36l-.16,6.91-7.62,1.56,1-9.38-27.54-3.65,8.26-1.7,20.24,2.69.13-6.87,7.62-1.56-1,9.34,27.59,3.68-8.26,1.7Z"
          />
          <path
            className="edtech-cls-8"
            d="M722.49,515.45,730,513.9l12.51,24.61L735,540.06,730.9,532,687,522.74l8.1-1.66,35.27,7.73Z"
          />
        </g>

        <g id="laptop">
          <path
            className="edtech-cls-12"
            d="M427.93,444.18l260.14,70.24c6.72,1.81,17.63,1.81,24.36,0l155.92-42.1c6.72-1.82,6.72-4.76,0-6.58L608.21,395.5c-6.73-1.81-17.64-1.81-24.36,0L427.93,437.6C421.2,439.42,421.2,442.36,427.93,444.18Z"
          />
          <path
            className="edtech-cls-2"
            d="M883.52,397.48V218.09c0-6-4.87-12.19-10.88-13.8L604.15,132.35a10.3,10.3,0,0,0-4-.3l6.86-2.21a9.62,9.62,0,0,1,5.59-.05l268.49,71.94c6,1.61,10.89,7.79,10.89,13.81V394.92a8,8,0,0,1-4.52,7.61h0l-.07,0a7.85,7.85,0,0,1-1.7.54L877,405.84l0-.13C880.83,405.07,883.52,402,883.52,397.48Z"
          />
          <path
            className="edtech-cls-13"
            d="M422.94,387.19l-.06-8.14,10.51,3.77L583.85,342.2c6.72-1.82,17.63-1.82,24.36,0L863.07,411l10.32-3.42v8h0c.11,1.23-1.55,2.46-5,3.4l-155.92,42.1c-6.73,1.82-17.64,1.82-24.36,0L427.93,390.87c-3.74-1-5.39-2.36-5-3.68Z"
          />
          <path
            className="edtech-cls-2"
            d="M427.93,382.47l260.14,70.24c6.72,1.82,17.63,1.82,24.36,0l155.92-42.1c6.72-1.81,6.72-4.76,0-6.58L608.21,333.8c-6.73-1.82-17.64-1.82-24.36,0L427.93,375.89C421.2,377.71,421.2,380.66,427.93,382.47Z"
          />
          <polygon
            className="edtech-cls-8"
            points="731.3 416.69 717.94 413.08 731.3 409.47 744.67 413.08 731.3 416.69"
          />
          <polygon
            className="edtech-cls-8"
            points="753.26 410.76 739.9 407.15 753.26 403.54 766.63 407.15 753.26 410.76"
          />
          <polygon
            className="edtech-cls-8"
            points="775.22 404.83 761.85 401.22 775.22 397.61 788.58 401.22 775.22 404.83"
          />
          <polygon
            className="edtech-cls-8"
            points="708.71 410.59 695.35 406.98 708.71 403.37 722.08 406.98 708.71 410.59"
          />
          <polygon
            className="edtech-cls-8"
            points="730.67 404.66 717.3 401.05 730.67 397.44 744.03 401.05 730.67 404.66"
          />
          <polygon
            className="edtech-cls-8"
            points="752.63 398.73 739.26 395.12 752.63 391.51 765.99 395.12 752.63 398.73"
          />
          <polygon
            className="edtech-cls-8"
            points="686.12 404.49 672.75 400.88 686.12 397.27 699.48 400.88 686.12 404.49"
          />
          <polygon
            className="edtech-cls-8"
            points="708.08 398.56 694.71 394.95 708.08 391.34 721.44 394.95 708.08 398.56"
          />
          <polygon
            className="edtech-cls-8"
            points="730.03 392.63 716.67 389.02 730.03 385.41 743.4 389.02 730.03 392.63"
          />
          <polygon
            className="edtech-cls-8"
            points="663.52 391.17 676.89 394.78 663.53 398.39 650.16 394.78 663.52 391.17"
          />
          <polygon
            className="edtech-cls-8"
            points="685.48 385.24 698.85 388.85 685.48 392.46 672.12 388.85 685.48 385.24"
          />
          <polygon
            className="edtech-cls-8"
            points="707.44 379.31 720.8 382.92 707.44 386.53 694.07 382.92 707.44 379.31"
          />
          <polygon
            className="edtech-cls-8"
            points="640.93 385.07 654.3 388.68 640.93 392.29 627.57 388.68 640.93 385.07"
          />
          <polygon
            className="edtech-cls-8"
            points="662.89 379.14 676.25 382.75 662.89 386.36 649.52 382.75 662.89 379.14"
          />
          <polygon
            className="edtech-cls-8"
            points="684.85 373.21 698.21 376.82 684.85 380.43 671.48 376.82 684.85 373.21"
          />
          <polygon
            className="edtech-cls-8"
            points="618.34 378.97 631.7 382.58 618.34 386.19 604.97 382.58 618.34 378.97"
          />
          <polygon
            className="edtech-cls-8"
            points="640.3 373.04 653.66 376.65 640.3 380.26 626.93 376.65 640.3 373.04"
          />
          <polygon
            className="edtech-cls-8"
            points="662.25 367.11 675.62 370.72 662.25 374.33 648.89 370.72 662.25 367.11"
          />
          <polygon
            className="edtech-cls-8"
            points="595.75 380.09 582.38 376.48 595.75 372.87 609.11 376.48 595.75 380.09"
          />
          <polygon
            className="edtech-cls-8"
            points="617.7 374.16 604.34 370.55 617.7 366.94 631.07 370.55 617.7 374.16"
          />
          <polygon
            className="edtech-cls-8"
            points="639.66 368.23 626.29 364.62 639.66 361.01 653.02 364.62 639.66 368.23"
          />
          <polygon
            className="edtech-cls-8"
            points="573.15 373.99 559.79 370.38 573.15 366.77 586.52 370.38 573.15 373.99"
          />
          <polygon
            className="edtech-cls-8"
            points="595.11 368.06 581.75 364.45 595.11 360.84 608.48 364.45 595.11 368.06"
          />
          <polygon
            className="edtech-cls-8"
            points="617.07 362.13 603.7 358.52 617.07 354.91 630.43 358.52 617.07 362.13"
          />
          <polygon
            className="edtech-cls-8"
            points="550.56 367.88 537.2 364.28 550.56 360.67 563.92 364.28 550.56 367.88"
          />
          <polygon
            className="edtech-cls-8"
            points="572.52 361.96 559.15 358.35 572.52 354.74 585.88 358.35 572.52 361.96"
          />
          <polygon
            className="edtech-cls-8"
            points="607.84 352.42 594.47 356.03 581.11 352.42 594.47 348.81 607.84 352.42"
          />
          <path
            className="edtech-cls-8"
            d="M593.58,385.24l60.1,16.22c1.33.36,1.33.94,0,1.3l-29.55,8a10.89,10.89,0,0,1-4.82,0l-60.1-16.23c-1.33-.35-1.33-.94,0-1.3l29.55-8A10.89,10.89,0,0,1,593.58,385.24Z"
          />
          <path
            className="edtech-cls-14"
            d="M604.15,333.51l268.49,71.94c6,1.61,10.88-2,10.88-8V218.09c0-6-4.87-12.19-10.88-13.8L604.15,132.35c-6-1.61-10.89,2-10.89,8V319.7C593.26,325.72,598.13,331.9,604.15,333.51Z"
          />
          <polygon
            className="edtech-cls-15"
            points="601.89 324.73 874.9 397.88 874.9 213.07 601.89 139.92 601.89 324.73"
          />

          <g id="atom">
            <ellipse
              className="edtech-cls-16"
              cx="779.83"
              cy="279.5"
              rx="21.52"
              ry="69.6"
              transform="matrix(1, -0.03, 0.03, 1, -8.79, 25.85)"
            />
            <ellipse
              className="edtech-cls-16"
              cx="779.83"
              cy="279.5"
              rx="20.55"
              ry="72.86"
              transform="matrix(0.71, -0.7, 0.7, 0.71, 28.15, 627.67)"
            />
            <ellipse
              className="edtech-cls-16"
              cx="779.83"
              cy="279.5"
              rx="55.45"
              ry="27.01"
              transform="translate(-47.39 235.17) rotate(-16.65)"
            />
            <ellipse
              className="edtech-cls-8"
              cx="779.65"
              cy="278.63"
              rx="3.38"
              ry="4.65"
              transform="translate(-39.88 396.36) rotate(-27.84)"
            />
          </g>
        </g>

        <g id="neurons">
          <path
            className="edtech-cls-17"
            d="M265,176.3l207.36-55.57c12.89-3.45,23.34,4.2,23.34,17.09V245.55c0,12.89-10.45,26.14-23.34,29.59l-24.58,6.59v47.81l-32.26-39.17L265,330.71c-12.89,3.45-23.34-4.2-23.34-17.09V205.89C241.61,193,252.06,179.75,265,176.3Z"
          />
          <polygon
            className="edtech-cls-18"
            points="326.01 216.82 305.62 222.29 295.43 242.68 305.62 257.6 326.01 252.14 336.2 231.75 326.01 216.82"
          />
          <polygon
            className="edtech-cls-18"
            points="391 236.14 370.61 241.6 360.41 261.99 370.61 276.92 391 271.46 401.19 251.07 391 236.14"
          />
          <polygon
            className="edtech-cls-18"
            points="380.62 188.35 360.23 193.81 350.03 214.2 360.23 229.13 380.62 223.66 390.81 203.27 380.62 188.35"
          />
          <polygon
            className="edtech-cls-18"
            points="446 158.29 425.61 163.75 415.42 184.14 425.61 199.07 446 193.6 456.2 173.22 446 158.29"
          />
          <line className="edtech-cls-18" x1="415.42" y1="184.14" x2="390.81" y2="203.27" />
          <line className="edtech-cls-18" x1="336.2" y1="231.75" x2="370.61" y2="241.61" />
        </g>

        <g id="math">
          <path
            className="edtech-cls-2"
            d="M604,28.83,498,.42c-6.59-1.76-11.93,2.15-11.93,8.74V64.25c0,6.59,5.34,13.36,11.93,15.13l12.57,3.37v24.44l16.49-20,77,20.63c6.59,1.76,11.93-2.15,11.93-8.74V44C615.94,37.37,610.6,30.6,604,28.83Z"
          />
          <path
            className="edtech-cls-8"
            d="M521,34.72a6.41,6.41,0,0,1,5,6.07c0,2.07-1.18,3.36-2.92,3.29v.13a6.08,6.08,0,0,1,3.22,5.56c0,2.8-1.78,4.49-5.22,3.51a7.59,7.59,0,0,1-5.7-7l2,.58A5.08,5.08,0,0,0,521,51.35c2.21.63,3.26-.5,3.26-2.34,0-2.55-1.62-4-4.59-4.83l-.52-.15v-1.9l.55.15c2.75.76,4.24.26,4.24-1.84a3.88,3.88,0,0,0-3-3.79c-1.93-.55-3.1.32-3.3,2l-2-.58C515.79,35,517.94,33.85,521,34.72Z"
          />
          <path
            className="edtech-cls-8"
            d="M537,53.67l-8.44-2.41V49.54l8-9.87,2.49.71v11.9l2.28.65v2L539,54.25v4l-2-.59Zm.09-2V42.2L530.91,50Z"
          />
          <path className="edtech-cls-8" d="M543.23,49.36l9.1,2.59v1.92l-9.1-2.59Z" />
          <path
            className="edtech-cls-8"
            d="M557.48,47.54l-2.19-.62V44.85l4.29,1.22V64.14l-2.1-.6Z"
          />
          <path
            className="edtech-cls-8"
            d="M562.84,63.24c4.33-2.59,8.44-5,8.44-8.72a4.09,4.09,0,0,0-3-4.36c-2.14-.61-3.23.7-3.32,3l-2-.57c.14-3.81,2.35-5.18,5.31-4.34A6.85,6.85,0,0,1,573.33,55c0,4.5-4.42,7.06-7.37,8.85l7.85,2.23V68l-11-3.12Z"
          />
          <path
            className="edtech-cls-8"
            d="M577.05,56.32,588.9,59.7v1.92l-11.85-3.38Zm0,5.37,11.85,3.38V67l-11.85-3.37Z"
          />
        </g>
      </svg>
    </div>
  );
}

const animate = () => {
  const svgs = document.querySelectorAll('.edtech-illustration');
  if (!svgs) return;

  const timeline = anime.timeline({
    autoplay: false,
    loop: false,
  });

  const animations = [
    {
      targets: '#base',
      translateY: [30, 0],
      opacity: [0, 0.56],
      easing: 'easeInOutSine',
      duration: 500,
      delay: 0,
    },
    {
      targets: '#book-1',
      translateX: [40, 0],
      translateY: [10, 0],
      opacity: [0, 1],
      easing: 'easeOutSine',
      duration: 700,
      delay: 400,
    },
    {
      targets: '#book-2',
      translateX: [40, 0],
      translateY: [10, 0],
      opacity: [0, 1],
      easing: 'easeOutSine',
      duration: 700,
      delay: 600,
    },
    {
      targets: '#laptop',
      translateY: [20, 0],
      opacity: [0, 1],
      easing: 'easeOutSine',
      duration: 500,
      delay: 750,
    },
    {
      targets: '#laptop-shadow',
      translateY: [-20, 0],
      easing: 'easeOutSine',
      duration: 500,
      delay: 750,
    },
    {
      targets: '#keys > polygon',
      opacity: [0, 1],
      easing: 'easeOutSine',
      duration: 100,
      delay: (el, i, l) => 950 + Math.random() * 650,
    },
    {
      targets: '#atom > ellipse',
      strokeDashoffset: [anime.setDashoffset, 0],
      easing: 'easeOutSine',
      duration: 900,
      delay: (el, i, l) => 1250 + i * 250,
    },
    {
      targets: '#neurons',
      translateY: [20, 0],
      opacity: [0, 1],
      easing: 'easeInOutSine',
      duration: 500,
      delay: 1250,
    },
    {
      targets: '#neurons > *',
      strokeDashoffset: [anime.setDashoffset, 0],
      easing: 'easeOutSine',
      duration: 900,
      delay: (el, i, l) => 1950 + i * 250,
    },
    {
      targets: '#math > *',
      opacity: [0, 1],
      duration: 200,
      delay: (el, i, l) => 1750 + i * 50,
    },
    {
      targets: '#atom',
      opacity: [0, 1],
      easing: 'easeOutBounce',
      duration: 500,
      delay: 1250,
    },
    {
      targets: '#atom-core',
      opacity: [0, 1, 0, 1, 0, 1, 0, 1],
      easing: 'easeOutBounce',
      duration: 500,
      delay: 3550,
    },
    {
      targets: '#clock > ellipse',
      opacity: [0, 1],
      easing: 'easeOutSine',
      duration: 300,
      delay: (el, i, l) => 50 + i * 150,
    },
    {
      targets: '#clock > line',
      strokeDashoffset: [anime.setDashoffset, 0],
      easing: 'easeOutSine',
      duration: 900,
      delay: (el, i, l) => 150 + i * 250,
    },
  ];

  animations.forEach(animation => timeline.add(animation, 0));

  svgs.forEach(svg => (svg.style.opacity = '1'));
  timeline.play();
};
