import React from 'react';
import tw, { css } from 'twin.macro';
import {
  Section,
  Inner,
  Half,
  PageTitle,
  contentStyles,
  // sectionBlueGradientStyles,
} from 'components';
import TiltButton from 'components/TiltButton';
import Illustrations from 'components/Illustrations/Illustrations';
import { preventWidow } from 'utils';

const innerStyles = css`
  ${tw`relative lg:flex flex-row items-center`};
`;

const illustrationContainerStyles = css`
  > div {
    ${tw`w-full h-screen max-h-[480px]`}
  }
  svg {
    max-height: 600px;
    ${tw`w-full h-full object-contain`}
  }
  ${tw`w-full lg:relative lg:bottom-0 lg:right-0 lg:h-full lg:w-1/2 lg:flex lg:items-center mt-8 lg:mt-0`}
`;
function IllustrationContainer({ children }) {
  return <div css={illustrationContainerStyles}>{children}</div>;
}

function HeroWithIllustration({ heading, bodyTextNode, bodyText, callToActions, illustration }) {
  return (
    <Section show>
      <Inner css={[innerStyles, tw``]}>
        <div css={tw`lg:w-1/2 lg:pb-12`}>
          <Half tw="relative z-10 w-full!">
            <PageTitle>{preventWidow(heading)}</PageTitle>
            <div
              tw="mt-6"
              css={contentStyles}
              dangerouslySetInnerHTML={{ __html: bodyTextNode.childMarkdownRemark.html }}
            />
          </Half>
          <ul tw="mt-6">
            {callToActions?.map((cta) => (
              <TiltButton
                to={cta.href}
                label={cta.label}
                tw="w-full mt-4 lg:(w-auto mr-10 mt-0)"
                key={cta.href}
              />
            ))}
          </ul>
        </div>
        <IllustrationContainer>
          <Illustrations tech={illustration.ecoid} />
        </IllustrationContainer>
      </Inner>
    </Section>
  );
}

export default HeroWithIllustration;
