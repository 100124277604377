import { useEffect } from 'react';
import anime from 'animejs/lib/anime.es.js';
import 'styles/illustrations/healthtech.css';

export default function HealthTech() {
  useEffect(() => {
    animate();
  }, []);

  return (
    <div className="healthtech-illustration">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        viewBox="0 0 990 689"
      >
        <title>HealthTech</title>
        <defs>
          <linearGradient
            id="healthtech-a"
            x1="153.49"
            y1="335.95"
            x2="429.42"
            y2="335.95"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0" stopColor="#5ec2bb" />
            <stop offset="1" stopColor="#203075" />
          </linearGradient>
          <linearGradient
            id="healthtech-b"
            x1="161.69"
            y1="335.95"
            x2="421.22"
            y2="335.95"
            xlinkHref="#healthtech-a"
          />
          <linearGradient
            id="healthtech-c"
            x1="585.98"
            y1="227.75"
            x2="585.98"
            y2="227.75"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0" stopColor="#ee105a" />
            <stop offset="1" stopColor="#203075" />
          </linearGradient>
          <linearGradient
            id="healthtech-d"
            x1="528.74"
            y1="145.58"
            x2="703.54"
            y2="145.58"
            xlinkHref="#healthtech-c"
          />
          <linearGradient
            id="healthtech-e"
            x1="793.59"
            y1="432.95"
            x2="935.89"
            y2="432.95"
            xlinkHref="#healthtech-a"
          />
        </defs>

        <polygon
          id="side"
          className="healthtech-a"
          points="989.55 97.81 624.53 0 624.53 380.76 989.55 478.56 989.55 97.81"
        />
        <polygon
          id="base"
          className="healthtech-a"
          points="396.08 688.4 31.25 589.9 621.84 430.44 986.68 528.94 396.08 688.4"
        />

        <g id="laptop">
          <path
            id="laptop-shadow"
            className="healthtech-b"
            d="M586.89,509.61,339.6,576.38a52.32,52.32,0,0,1-23.16,0l-148.22-40c-6.4-1.72-6.4-4.52,0-6.25l247.3-66.77a52.58,52.58,0,0,1,23.15,0l148.22,40C593.29,505.09,593.29,507.89,586.89,509.61Z"
          />
          <path
            className="healthtech-c"
            d="M591.63,449.08l.06-7.75-10,3.59-143-38.62a52.58,52.58,0,0,0-23.15,0L173.24,471.72l-9.82-3.26v7.63h0c-.1,1.17,1.48,2.34,4.78,3.23l148.22,40a52.32,52.32,0,0,0,23.16,0l247.29-66.77c3.55-.95,5.13-2.24,4.73-3.5Z"
          />
          <path
            className="healthtech-d"
            d="M586.89,444.59,339.6,511.36a52.62,52.62,0,0,1-23.16,0l-148.22-40c-6.4-1.73-6.4-4.53,0-6.25l247.3-66.77a52.28,52.28,0,0,1,23.15,0l148.22,40C593.29,440.06,593.29,442.86,586.89,444.59Z"
          />
          <polygon
            className="healthtech-e"
            points="298.49 477.11 311.2 473.68 298.5 470.25 285.79 473.68 298.49 477.11"
          />
          <polygon
            className="healthtech-e"
            points="277.62 471.48 290.33 468.05 277.62 464.62 264.92 468.05 277.62 471.48"
          />
          <polygon
            className="healthtech-e"
            points="256.75 465.84 269.45 462.41 256.75 458.98 244.04 462.41 256.75 465.84"
          />
          <polygon
            className="healthtech-e"
            points="319.97 471.31 332.68 467.88 319.97 464.45 307.27 467.88 319.97 471.31"
          />
          <polygon
            className="healthtech-e"
            points="299.1 465.68 311.8 462.25 299.1 458.82 286.39 462.25 299.1 465.68"
          />
          <polygon
            className="healthtech-e"
            points="278.23 460.04 290.93 456.61 278.23 453.18 265.52 456.61 278.23 460.04"
          />
          <polygon
            className="healthtech-e"
            points="341.45 465.52 354.15 462.08 341.45 458.65 328.75 462.08 341.45 465.52"
          />
          <polygon
            className="healthtech-e"
            points="320.58 459.88 333.28 456.45 320.58 453.02 307.87 456.45 320.58 459.88"
          />
          <polygon
            className="healthtech-e"
            points="299.7 454.24 312.41 450.81 299.7 447.38 287 450.81 299.7 454.24"
          />
          <polygon
            className="healthtech-e"
            points="362.93 452.86 350.22 456.29 362.93 459.72 375.63 456.29 362.93 452.86"
          />
          <polygon
            className="healthtech-e"
            points="342.06 447.22 329.35 450.65 342.05 454.08 354.76 450.65 342.06 447.22"
          />
          <polygon
            className="healthtech-e"
            points="321.18 441.58 308.48 445.01 321.18 448.45 333.89 445.01 321.18 441.58"
          />
          <polygon
            className="healthtech-e"
            points="384.4 447.06 371.7 450.49 384.4 453.92 397.11 450.49 384.4 447.06"
          />
          <polygon
            className="healthtech-e"
            points="363.53 441.42 350.83 444.85 363.53 448.28 376.24 444.85 363.53 441.42"
          />
          <polygon
            className="healthtech-e"
            points="342.66 435.79 329.95 439.22 342.66 442.65 355.37 439.22 342.66 435.79"
          />
          <polygon
            className="healthtech-e"
            points="405.88 441.26 393.18 444.69 405.88 448.12 418.59 444.69 405.88 441.26"
          />
          <polygon
            className="healthtech-e"
            points="385.01 435.62 372.31 439.05 385.01 442.48 397.71 439.05 385.01 435.62"
          />
          <polygon
            className="healthtech-e"
            points="364.14 429.99 351.43 433.42 364.14 436.85 376.84 433.42 364.14 429.99"
          />
          <polygon
            className="healthtech-e"
            points="427.36 442.32 440.06 438.89 427.36 435.46 414.65 438.89 427.36 442.32"
          />
          <polygon
            className="healthtech-e"
            points="406.49 436.68 419.19 433.25 406.49 429.82 393.78 433.25 406.49 436.68"
          />
          <polygon
            className="healthtech-e"
            points="385.62 431.05 398.32 427.62 385.62 424.19 372.91 427.62 385.62 431.05"
          />
          <polygon
            className="healthtech-e"
            points="448.84 436.52 461.54 433.09 448.84 429.66 436.13 433.09 448.84 436.52"
          />
          <polygon
            className="healthtech-e"
            points="427.96 430.88 440.67 427.45 427.96 424.02 415.26 427.45 427.96 430.88"
          />
          <polygon
            className="healthtech-e"
            points="407.09 425.25 419.8 421.82 407.09 418.39 394.39 421.82 407.09 425.25"
          />
          <polygon
            className="healthtech-e"
            points="470.31 430.72 483.02 427.29 470.32 423.86 457.61 427.29 470.31 430.72"
          />
          <polygon
            className="healthtech-e"
            points="449.44 425.09 462.15 421.66 449.44 418.23 436.74 421.66 449.44 425.09"
          />
          <polygon
            className="healthtech-e"
            points="415.87 416.02 428.57 419.45 441.27 416.02 428.57 412.59 415.87 416.02"
          />
          <path
            className="healthtech-e"
            d="M429.42,447.22l-57.13,15.42c-1.27.34-1.27.9,0,1.24l28.09,7.58a10.41,10.41,0,0,0,4.58,0L462.09,456c1.26-.34,1.26-.9,0-1.24L434,447.22A10.12,10.12,0,0,0,429.42,447.22Z"
          />

          <g id="laptop-screen">
            <path
              className="healthtech-c"
              d="M153.49,458.19V287.66c0-5.72,4.63-11.59,10.35-13.12l255.23-68.39a9.9,9.9,0,0,1,3.81-.29l-6.53-2.1a9.21,9.21,0,0,0-5.32,0L155.8,272.1c-5.72,1.54-10.35,7.41-10.35,13.13V455.76a7.57,7.57,0,0,0,4.3,7.23h0l.07,0a7.82,7.82,0,0,0,1.62.5l8.31,2.61,0-.12C156.06,465.4,153.49,462.44,153.49,458.19Z"
            />
            <path
              className="healthtech-f"
              d="M419.07,397.37,163.84,465.76c-5.72,1.53-10.35-1.86-10.35-7.57V287.66c0-5.72,4.63-11.59,10.35-13.12l255.23-68.39c5.72-1.54,10.35,1.86,10.35,7.57V384.25C429.42,390,424.79,395.84,419.07,397.37Z"
            />
            <polygon
              className="healthtech-g"
              points="421.22 389.02 161.69 458.57 161.69 282.88 421.22 213.34 421.22 389.02"
            />
          </g>
        </g>

        <polyline
          id="graph"
          className="healthtech-h"
          points="0.13 434.32 106.45 405.84 139.16 380.71 171.87 388.31 195.5 381.97 203.68 408.86 210.04 378.08 229.12 372.96 250.02 322.02 269.1 362.25 280.92 359.09 291.82 374.34 310 115.94 339.98 402.08 356.34 338.88 395.41 328.41 433.58 272.83 466.29 309.42 591.69 275.81"
        />

        <g id="heart">
          <path
            className="healthtech-j"
            d="M703.05,141.87c-2.25-16.63-10.4-30.91-25.15-42-8.6-6.46-17.76-9.8-27-9.87a35.78,35.78,0,0,0-10.75,1.48l-22.22,6c-1-1.66-2.1-3.29-3.26-4.86-11.08-14.94-27.14-24-43.24-23a35.7,35.7,0,0,0-5.89.87h0l-.08,0c-.43.1-.85.22-1.27.34l-35.43,9.4h0a34.4,34.4,0,0,1,7-1.15c16.1-1,32.16,8.09,43.24,23a72.51,72.51,0,0,1,4.31,6.6c1.29,2.2,2.36,4.49,3.59,6.85.25-.37.51-.72.74-1.09,6-9.77,15.07-15.07,27.59-15,9.27.07,18.42,3.41,27,9.87,14.75,11.07,22.9,25.35,25.16,42A52.85,52.85,0,0,1,664,178.24a60.1,60.1,0,0,1-17.45,23.9c-10.32,8.86-22.47,15-35.66,19.5l21.52-5.29h0c4.31-1,8.54-2.29,12.69-3.68,13.76-4.59,26.44-10.81,37.15-20a60.24,60.24,0,0,0,17.45-23.9A52.8,52.8,0,0,0,703.05,141.87Z"
          />
          <path
            className="healthtech-k"
            d="M586.86,115.56c.25-.37.51-.72.74-1.09,6-9.77,15.07-15.07,27.59-15,9.27.07,18.42,3.41,27,9.87,14.75,11.07,22.9,25.35,25.16,42A52.85,52.85,0,0,1,664,178.24a60.1,60.1,0,0,1-17.45,23.9c-10.71,9.19-23.39,15.42-37.15,20a145.49,145.49,0,0,1-22.2,5.64,3,3,0,0,1-2.48-.67,270.57,270.57,0,0,1-46-41.62c-9.92-11.4-18.49-23.6-24.65-37.07-6-13-9-25.92-7.94-38.5,1.43-17.49,12.67-29.8,29.57-30.85,16.1-1,32.16,8.09,43.24,23a72.51,72.51,0,0,1,4.31,6.6C584.56,110.91,585.63,113.2,586.86,115.56Z"
          />
        </g>

        <polygon
          id="strap-top"
          className="healthtech-l"
          points="918.04 234 827.29 209.69 827.29 329.45 918.04 353.77 918.04 234"
        />
        <polygon
          id="strap-bottom"
          className="healthtech-l"
          points="915.36 532.64 824.61 508.33 824.61 628.09 915.36 652.41 915.36 532.64"
        />

        <g id="watch">
          <path
            className="healthtech-d"
            d="M923,354.91l-90.29-24.19a23.31,23.31,0,0,0-12.92,0l-12.93,3.44.11,0a23.62,23.62,0,0,1,12.62.1l90.29,24.2c14.36,3.84,26,18.61,26,33V512.61c0,9.44-5,16.37-12.58,18.9h0c.15.58,12.29-3.28,12.29-3.28v0c.27-.08.55-.14.81-.23C944,525.45,949,518.52,949,509.06V387.89C949,373.52,937.37,358.76,923,354.91Z"
          />
          <path
            className="healthtech-m"
            d="M909.89,531.64,819.6,507.45c-14.36-3.85-26-18.61-26-33V353.3c0-8.73,4.31-15.3,10.9-18.25a22.53,22.53,0,0,1,15.11-.79l90.29,24.2c14.36,3.84,26,18.61,26,33V512.61C935.89,527,924.25,535.49,909.89,531.64Z"
          />
        </g>

        <g id="time">
          <path className="healthtech-e" d="M830.29,424.19l-3-.82v-4.06l7.6,2v23l-4.55-1.21Z" />
          <path
            className="healthtech-e"
            d="M847.71,424.35c6.49,1.74,8.51,7.26,8.51,13.92s-2,11.19-8.51,9.45-8.52-7.3-8.52-14S841.21,422.61,847.71,424.35Zm0,4.1c-3.73-1-4.17,2.48-4.17,6.42,0,4.1.44,7.75,4.17,8.75s4.16-2.42,4.16-6.52C851.87,433.16,851.43,429.45,847.71,428.45Z"
          />
          <path
            className="healthtech-e"
            d="M862,438.78a3.63,3.63,0,0,1-2.75-3.32c0-1.45,1.14-2.28,2.75-1.85a3.62,3.62,0,0,1,2.68,3.31A1.92,1.92,0,0,1,862,438.78Zm0,13.06a3.63,3.63,0,0,1-2.75-3.32c0-1.45,1.14-2.28,2.75-1.85a3.6,3.6,0,0,1,2.68,3.3A1.93,1.93,0,0,1,862,451.84Z"
          />
          <path
            className="healthtech-e"
            d="M875.59,431.73c4.73,1.26,7.25,4.62,7.25,8,0,2.87-1.79,4.15-3.47,4.27v.13a7.52,7.52,0,0,1,3.88,6.78c0,3.75-2.65,5.82-7.47,4.52-4.54-1.21-8-4.41-8.17-9.28l4.29,1.15a4.79,4.79,0,0,0,3.78,4.42c2.21.59,3.35-.49,3.35-2.32,0-2.59-1.64-4-4.92-4.85l-.92-.25V440.7l.92.25c2.58.69,4.48.47,4.48-1.8a3.77,3.77,0,0,0-3.13-3.7c-2.14-.58-3.06.56-3.21,2l-4.32-1.16C868.15,432.07,871.17,430.54,875.59,431.73Z"
          />
          <path
            className="healthtech-e"
            d="M901.39,439.24v3.28L893,459.92l-4.48-1.2L897,441.84,886.19,439v-3.78Z"
          />
        </g>

        <path
          id="watch-coating"
          className="healthtech-n"
          d="M830.79,553.13l-90.28-24.2c-14.37-3.85-26-18.61-26-33V374.78c0-8.73,4.3-15.3,10.9-18.25a22.53,22.53,0,0,1,15.11-.78l90.28,24.19c14.37,3.85,26,18.61,26,33V534.09C856.8,548.45,845.16,557,830.79,553.13Z"
        />

        <path
          id="wifi-base"
          className="healthtech-o"
          d="M794.26,562.89,704,538.7c-14.36-3.85-26-18.61-26-33V384.55c0-8.73,4.3-15.3,10.9-18.25a22.47,22.47,0,0,1,15.11-.79l90.29,24.19c14.36,3.85,26,18.61,26,33V543.85C820.26,558.21,808.62,566.74,794.26,562.89Z"
        />
        <g id="wifi" className="healthtech-p">
          <path
            className="healthtech-e"
            d="M740.05,485.29c-4.5-1.22-8.18,1.44-8.19,5.93s3.66,9.15,8.16,10.35,8.13-1.47,8.13-6A11.37,11.37,0,0,0,740.05,485.29Z"
          />
          <path
            className="healthtech-e"
            d="M743.55,478.38a23.08,23.08,0,0,1,6.64,4.91l0,0,7.51-5.54c-.11-.14-.22-.29-.36-.44A36.29,36.29,0,0,0,740,466.08c-6.5-1.74-14-.25-17.67,1.89l-.08,0,7.57,9.61C734,475.72,738.54,476,743.55,478.38Z"
          />
          <path
            className="healthtech-e"
            d="M752.13,463.23a47.16,47.16,0,0,1,11.61,10.06l0,0,7.52-5.54,0-.06c-8.86-10.36-19.26-17.21-30.41-20.34a50.15,50.15,0,0,0-10.22-1.76c-8.2-.44-15.43,1.27-21.62,5.29a.5.5,0,0,0-.12.08l7.57,9.63c.64-.38,1.26-.78,1.94-1.1Q733.87,452.27,752.13,463.23Z"
          />
          <path
            className="healthtech-e"
            d="M748,441.49c11.15,4.51,20.86,11.9,29.14,21.76l.13.15,7.5-5.53,0-.07a98.29,98.29,0,0,0-12.19-12.37,85.64,85.64,0,0,0-33.36-17.53,62.86,62.86,0,0,0-11-1.88c-12.55-1-23.46,1.61-32.77,7.82l7.52,9.55c1-.58,1.88-1.2,2.87-1.72C718.19,435.12,732.35,435.15,748,441.49Z"
          />
        </g>
      </svg>
    </div>
  );
}

export const animate = () => {
  const svgs = document.querySelectorAll('.healthtech-illustration');
  if (!svgs) return;

  const timeline = anime.timeline({
    autoplay: false,
    loop: false,
  });

  const animations = [
    {
      targets: '#base',
      translateY: [30, 0],
      opacity: [0, 0.25],
      easing: 'easeOutSine',
      duration: 750,
      delay: 0,
    },
    {
      targets: '#laptop',
      translateY: [10, 0],
      opacity: [0, 1],
      easing: 'easeOutSine',
      duration: 1000,
      delay: 100,
    },
    {
      targets: '#laptop-shadow',
      translateY: [-10, 0],
      easing: 'easeOutSine',
      duration: 750,
      delay: 300,
    },
    {
      targets: '#laptop > polygon',
      opacity: [0, 1],
      easing: 'easeOutSine',
      duration: 200,
      delay: (el, i, l) => 500 + Math.random() * 750,
    },
    {
      targets: '#laptop-screen',
      rotateZ: ['-1deg', 0],
      rotateY: ['13deg', 0],
      rotateX: ['-19deg', 0],
      easing: 'easeInOutSine',
      duration: 1000,
      delay: 200,
    },
    {
      targets: '#side',
      translateX: [30, 0],
      translateY: [-10, 0],
      opacity: [0, 0.25],
      easing: 'easeOutSine',
      duration: 750,
      delay: 900,
    },
    {
      targets: '#watch',
      translateX: [30, 0],
      translateY: [-10, 0],
      opacity: [0, 1],
      easing: 'easeOutSine',
      duration: 350,
      delay: 1300,
    },
    {
      targets: '#time',
      translateX: [30, 0],
      translateY: [-10, 0],
      opacity: [0, 1],
      easing: 'easeOutSine',
      duration: 350,
      delay: 1350,
    },
    {
      targets: '#watch-coating',
      translateX: [30, 0],
      translateY: [-10, 0],
      opacity: [0, 0.5],
      easing: 'easeOutSine',
      duration: 350,
      delay: 1450,
    },
    {
      targets: '#wifi-base',
      translateX: [30, 0],
      translateY: [-10, 0],
      opacity: [0, 0.47],
      easing: 'easeOutSine',
      duration: 350,
      delay: 1600,
    },
    {
      targets: '#strap-top',
      translateY: [-40, 0],
      opacity: [0, 0.54],
      easing: 'easeOutSine',
      duration: 1500,
      delay: 1300,
    },
    {
      targets: '#strap-bottom',
      translateY: [40, 0],
      opacity: [0, 0.54],
      easing: 'easeOutSine',
      duration: 1500,
      delay: 1300,
    },
    {
      targets: '#wifi > path',
      opacity: [0, 1],
      easing: 'easeOutSine',
      duration: 50,
      delay: (el, i, l) => 1800 + i * 50,
    },
    {
      targets: '#graph',
      strokeDashoffset: [anime.setDashoffset, 0],
      easing: 'linear',
      duration: 1500,
      delay: 2000,
    },
    {
      targets: '#heart',
      translateY: [50, 0],
      opacity: [0, 1],
      easing: 'spring(.5, 80, 5, 0)',
      duration: 750,
      delay: 2500,
    },
  ];

  animations.forEach(animation => timeline.add(animation, 0));

  svgs.forEach(svg => (svg.style.opacity = '1'));
  timeline.play();
};
